import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";
import { createLoadingSelector } from "store/selectors";
import { completeUserKyc, getUserState } from "actions/userActions";
import { INDIVIDUAL_WAITING_APPROVAL } from "statics/onboardingSteps";
import { Auth0Context } from "utils/react-auth0-wrapper";
import { userConstants } from "actions/types";
import { scrollToTop } from "utils/dom";
import { getAllAccounts } from "actions/accountActions";
import { activeAccountState, isRolloverSelector } from "store/selectors/user";
import { userIs401kRollIn } from "../../../store/selectors/user";

import Button from "components/Button";
import ProgressBar from "components/ProgressBar";
import Alert from "components/Alert";
import PromisePoller from "components/PromisePoller";

import "./IndividualThanks.scss";

class IndividualThanks extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    getUserState: PropTypes.func,
    getAllAccounts: PropTypes.func,
    completeUserKyc: PropTypes.func,
    push: PropTypes.func,
    userState: PropTypes.string,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    isRolloverAccount: PropTypes.bool,
    accountState: PropTypes.string,
    userId: PropTypes.string,
    isPaychex: PropTypes.bool,
  };

  static contextType = Auth0Context;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      approved: false,
      rolloverApproved: false,
    };
  }

  componentDidMount() {
    window.analytics.page("Individual Thanks");
    scrollToTop();

    if (this.props.userState) {
      if (this.props.userState === "IndividualApplicationApproved") {
        this.setState({
          approved: true,
        });
      }
    } else if (this.props.isRolloverAccount) {
      if (this.props.accountState === "AccountApplicationApproved") {
        this.setState({
          rolloverApproved: true,
        });
      }
    }
  }

  componentDidUpdate() {
    if (this.props.userState) {
      if (
        this.props.userState === "IndividualApplicationApproved" &&
        !this.state.approved
      ) {
        this.setState({
          approved: true,
        });
      }
      window.analytics.track("Submitted Individual Application", {
        userState: this.props.userState,
      });
    } else if (this.props.isRolloverAccount) {
      if (
        this.props.accountState === "AccountApplicationApproved" &&
        !this.state.rolloverApproved
      ) {
        this.setState({
          rolloverApproved: true,
        });
      }
      window.analytics.track("Submitted Individual Rollover Application", {
        accountState: this.props.accountState,
      });
    }
  }
  verify = async () => {
    try {
      const { getUser } = this.context;
      const user = await getUser();

      if (this.props.isRolloverAccount) {
        return this.props.getAllAccounts(this.props.client, this.props.userId);
      }

      return this.props.getUserState(this.props.client, user);
    } catch (e) {
      console.log("Polling Error: " + e);
    }
  };

  render() {
    if (this.state.approved) {
      window.analytics.track("Individual Application Approved");
      return this.renderApproved();
    } else if (this.state.rolloverApproved) {
      window.analytics.track("Individual Rollover Application Approved");
      return this.renderApproved();
    }
    return this.renderChecking();
  }

  renderChecking = () => {
    const title = this.props.isPaychex
      ? "Just a Moment While We Prepare Your Account."
      : "Thank you!";
    const subtext = this.props.isPaychex
      ? "We're currently completing your account setup, which typically takes less than 5 minutes. You'll receive an email notification as soon as everything is ready."
      : "Your information has been submitted and we're in the process of setting up your account. This could take up to two business days. We'll email you when it's ready to go.";
    return (
      <div className="individual-thanks">
        <ProgressBar
          isEmployer={false}
          activeStepId={INDIVIDUAL_WAITING_APPROVAL.id}
          progressPercent={"100"}
        />
        <PromisePoller
          minutesToPollFor={10}
          promiseToPoll={this.verify}
          interval={4000}
        />
        <div className="mega-container">
          <div className="step-container is-active" data-circle-percent="100">
            <section className="page-title-wrap">
              <article className="text-cell">
                <p className="page-title">{title}</p>
                <p className="page-subtext">
                  {subtext}
                  <br />
                  <br />
                  We&apos;re glad you&apos;re here and look forward to working
                  with you.
                  <br />
                  <br />
                  Thanks,
                  <br />
                  The Icon Account Team
                </p>
              </article>
            </section>
          </div>
        </div>
      </div>
    );
  };

  _goToDashboard = () => {
    if (this.props.isRolloverAccount) {
      this.props.push("/dashboard");
    } else {
      this.props.completeUserKyc(this.props.client);
    }
  };

  renderApproved = () => {
    const subtext = this.props.accountState
      ? "Your Icon rollover account is now set up and ready for you to finalize."
      : "Your Icon account is now set up and ready for you to finalize.";
    return (
      <div>
        <div className="individual-thanks">
          <div className="mega-container">
            <div className="step-container is-active" data-circle-percent="100">
              <section className="page-title-wrap">
                <article className="text-cell">
                  <h1 className="page-title">Success!</h1>
                  <p className="page-subtext">{subtext}</p>
                  <div>
                    {this.props.error && (
                      <Alert type="error" msg={this.props.error} />
                    )}
                    <Button
                      name="submit"
                      withArrow={true}
                      btnLabel="Continue"
                      loading={this.props.isFetching}
                      onClick={this._goToDashboard}
                    />
                  </div>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const loadingSelector = createLoadingSelector(userConstants.USER_KYC_COMPLETED);

const mapStateToProps = (state, ownProps) => {
  const userState = state.user.userState.state;
  const error = state.user.error;
  return {
    userState,
    ownProps,
    error,
    userId: state.user.id,
    isFetching: loadingSelector(state),
    isRolloverAccount: isRolloverSelector(state),
    accountState: activeAccountState(state),
    isPaychex: userIs401kRollIn(state),
  };
};
const mapDispatchToProps = {
  getUserState,
  getAllAccounts,
  push,
  completeUserKyc,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualThanks));
