import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { scrollToTop } from "utils/dom";

import Button from "components/Button";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import Form from "react-bootstrap/Form";

const distributionTypes = [
  {
    label: "Premature",
    key: "PREMATURE",
    description:
      "Based on your age (less than 59.5 years old as of today) your withdrawal will be considered an Early withdrawal unless you choose one of the other options below. Taxes and a penalty from the IRS may apply and will be reported on Form 1099-R using code 1.",
  },
  {
    label: "Qualified Distribution",
    key: "QUALIFIED",
    description:
      "A withdrawal that meets IRS rules, such as reaching age 59.5, account maturity, or specific exceptions, making it penalty-free. Qualified distributions are reported on Form 1099-R using the appropriate IRS distribution code.",
  },
  {
    label: "Normal Withdrawal",
    key: "NORMAL",
    description:
      "Based on your age (greater than or equal to 59.5 years old as of today) your withdrawal will be considered a Normal withdrawal. Normal withdrawals (including required minimum distributions) are reported on Form 1099-R using code 7.",
  },
  {
    label: "Transfer to Identical IRA",
    key: "TRANSFER_TO_IDENTICAL_IRA",
    description:
      "Choose this option if you're looking to transfer your funds to another IRA with the same type and benefits. ",
  },
  {
    label: "Direct Rollover to Employer Plan",
    key: "DIRECT_ROLLOVER_TO_EMPLOYER_PLAN",
    description:
      "Select this option if you want to roll over your IRA funds directly into your current employer's retirement plan. ",
  },
  {
    label: "Disability",
    key: "DISABILITY",
    description:
      "If you're unable to work due to a long-term or terminal disability, you may avoid the 10% early withdrawal penalty on your IRA.",
  },
  {
    label:
      "Other or I am not sure (Revocation, Excess Contribution, or similar)",
    key: "OTHER",
    description:
      "If you need to make a different type of IRA withdrawal, or aren't sure about the withdrawal type. Pick this option we will email you with next steps.",
  },
  {
    label:
      "Other or I am not sure (Distribution with Exception, Excess Contribution, Revocation, or similar)",
    key: "OTHER_ROTH",
    description:
      "If you need to make a different type of IRA withdrawal, or aren't sure about the withdrawal type. Pick this option we will email you with next steps.",
  },
];

class DistributionType extends React.Component {
  static propTypes = {
    goToNamedStep: PropTypes.func,
    onDistributionTypeSelect: PropTypes.func,
    dob: PropTypes.string,
    distributionType: PropTypes.string,
    selectedAccountType: PropTypes.string,
    accounts: PropTypes.array,
    isRothDistribution: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const type = this.getDistributionType();

    this.state = {
      distributionType: type,
    };
  }

  componentDidMount() {
    scrollToTop();
    if (this.props.onDistributionTypeSelect) {
      this.props.onDistributionTypeSelect(this.state.distributionType);
    }
  }

  handleChange = (event) => {
    const { value } = event.target;
    this.setState({ distributionType: value }, () => {
      if (this.props.onDistributionTypeSelect) {
        this.props.onDistributionTypeSelect(value);
      }
    });
  };

  getDistributionType = () => {
    const is59AndHalfOrAbove = this.isAge59AndHalfOrAbove();
    const isQualifiedRoth = is59AndHalfOrAbove && this.props.isRothDistribution;
    const isPrematureRoth =
      !is59AndHalfOrAbove && this.props.isRothDistribution;
    const isPrematureTraditional =
      !is59AndHalfOrAbove && !this.props.isRothDistribution;

    if (isQualifiedRoth) {
      return "QUALIFIED";
    } else if (isPrematureTraditional || isPrematureRoth) {
      return "PREMATURE";
    } else if (is59AndHalfOrAbove && !this.props.isRothDistribution) {
      return "NORMAL";
    }
  };

  isAge59AndHalfOrAbove = () => {
    const { dob } = this.props;
    if (!dob) return false;

    const birthDate = moment(dob);
    const currentDate = moment();
    const ageInYears = currentDate.diff(birthDate, "years", true);
    return ageInYears >= 59.5;
  };

  goToNamedStep = () => {
    const showSupportPage = [
      "TRANSFER_TO_IDENTICAL_IRA",
      "DIRECT_ROLLOVER_TO_EMPLOYER_PLAN",
      "OTHER_ROTH",
      "OTHER",
    ].includes(this.state.distributionType);

    if (showSupportPage) {
      return this.props.goToNamedStep("distributionSupport");
    } else if (this.props.isRothDistribution) {
      return this.props.goToNamedStep("distributionFederalTax");
    } else {
      return this.props.goToNamedStep("distributionReason");
    }
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    const is59AndHalfOrAbove = this.isAge59AndHalfOrAbove();

    const isQualifiedRoth = is59AndHalfOrAbove && this.props.isRothDistribution;

    const isPrematureRoth =
      !is59AndHalfOrAbove && this.props.isRothDistribution;

    const isPrematureTraditional =
      !is59AndHalfOrAbove && !this.props.isRothDistribution;

    const isNormalTraditional =
      is59AndHalfOrAbove && !this.props.isRothDistribution;

    const rothTypes = [
      "PREMATURE",
      "QUALIFIED",
      "TRANSFER_TO_IDENTICAL_IRA",
      "DISABILITY",
      "OTHER_ROTH",
    ];

    const traditionalTypes = [
      "PREMATURE",
      "NORMAL",
      "TRANSFER_TO_IDENTICAL_IRA",
      "DIRECT_ROLLOVER_TO_EMPLOYER_PLAN",
      "OTHER",
    ];

    const includedTypes = this.props.isRothDistribution
      ? rothTypes
      : traditionalTypes;

    const filteredDistributionTypes = distributionTypes.filter((type) => {
      if (isQualifiedRoth && type.key === "PREMATURE") return false;

      if (isPrematureRoth && type.key === "QUALIFIED") return false;

      if (isNormalTraditional && type.key === "PREMATURE") return false;

      if (isPrematureTraditional && type.key === "NORMAL") return false;

      return includedTypes.includes(type.key);
    });

    return (
      <div id="account-selection">
        <div className="mega-container">
          <section className="page-title-wrap">
            <article className="text-cell">
              <IconHeader
                variant="pageHeader"
                headerText="What type of IRA distribution is this?"
              />
              <IconSubheader
                variant="subheading"
                subheader="Select the distribution type that applies to your IRA withdrawal to ensure proper documentation and tax considerations."
              />
            </article>
          </section>
          <section className="form-sec-2col" style={{ paddingTop: 40 }}>
            <article className="col-form" style={articleStyle}>
              <Form>
                {filteredDistributionTypes.map((type) => (
                  <div key={type.key} style={{ marginBottom: 30 }}>
                    <Form.Check
                      type="radio"
                      name="distributionType"
                      className="custom-radio"
                      id={type.key}
                      label={<span className="radio-label">{type.label}</span>}
                      value={type.key}
                      onChange={this.handleChange}
                      checked={this.state.distributionType === type.key}
                    />
                    <Form.Text className="radio-description">
                      {type.description}
                    </Form.Text>
                  </div>
                ))}
              </Form>
            </article>
          </section>
          <section className="form-sec-2col">
            <article className="col-form" style={articleStyle}>
              <div className="submit-row btn-row">
                <Button
                  onClick={() => this.props.goToNamedStep("distributionAmount")}
                  name="cancel"
                  btnLabel="Go Back"
                  color="cancel"
                />
                <Button
                  style={{ marginLeft: 10 }}
                  name="action"
                  onClick={() => this.goToNamedStep()}
                />
              </div>
            </article>
          </section>
        </div>
      </div>
    );
  }
}

export default DistributionType;
