import { withdrawalConstants } from "./types";
import { makeWithdrawal } from "services/withdrawalService";
import ActionCreator from "utils/actionHandler";

import { withdrawalService } from "services/withdrawalService";

export const makeIraWithdrawal = (
  client,
  { iraAccountId, amount, reason, isFullDisbursement }
) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      withdrawalConstants.WITHDRAWAL_REQUEST
    );
    dispatch(actionCreator.request());

    return makeWithdrawal({
      client,
      iraAccountId,
      amount,
      reason,
      isFullDisbursement,
    }).then(
      (data) => {
        if (data) {
          dispatch(actionCreator.success());
        }
      },
      (error) => {
        console.log("makeIraWithdrawal Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const createDisbursementRequest = (client, disbursementInput) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      withdrawalConstants.CREATE_DISBURSEMENT
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      withdrawalService.createDisbursementRequest(client, disbursementInput)
    );
  };
};
