import gql from "graphql-tag";

export function getPortfolios(client, accountId) {
  return new Promise((resolve, reject) => {
    const PORTFOLIOS = gql`
      query getUserPortfolios($accountId: ID) {
        getUserPortfolios(accountId: $accountId) {
          recommended {
            assets {
              id
              symbol
              allocation
            }
            id
            name
            description
            createdAt
            updatedAt
            label
          }
          other {
            assets {
              id
              symbol
              allocation
            }
            id
            name
            description
            createdAt
            updatedAt
            label
          }
        }
      }
    `;

    const variables = accountId ? { accountId } : {};

    const result = client.query({
      query: PORTFOLIOS,
      variables,
    });
    result.then(
      (data) => {
        resolve(data.data.getUserPortfolios);
      },
      (errors) => {
        reject(errors);
      }
    );
  });
}

export function getActivePortfolio(client, accountId) {
  return new Promise((resolve, reject) => {
    const GET_USER_PORTFOLIO = gql`
      query getUserPortfolio($accountId: ID) {
        getUserPortfolio(accountId: $accountId) {
          assets {
            id
            symbol
            allocation
          }
          id
          name
          description
          createdAt
          updatedAt
        }
      }
    `;

    const variables = accountId ? { accountId } : {};

    const result = client.query({
      query: GET_USER_PORTFOLIO,
      variables,
    });
    result.then(
      (data) => {
        resolve(data.data.getUserPortfolio);
      },
      (errors) => {
        reject(errors);
      }
    );
  });
}

export const updateUserPortfolio = (client, { portfolioId, accountId }) => {
  return new Promise((resolve, reject) => {
    const UPDATE_USER_PORTFOLIO = gql`
      mutation updateUserPortfolio($portfolioId: String!, $accountId: ID) {
        updateUserPortfolio(portfolioId: $portfolioId, accountId: $accountId) {
          assets {
            id
            symbol
            allocation
          }
          id
          name
          description
          createdAt
          updatedAt
        }
      }
    `;

    const variables = {
      portfolioId,
      ...(accountId && { accountId }),
    };

    const result = client.mutate({
      mutation: UPDATE_USER_PORTFOLIO,
      variables,
    });
    result.then(
      (data) => {
        resolve(data.data.updateUserPortfolio);
      },
      (errors) => {
        reject(errors);
      }
    );
  });
};

export function getPositions(client, accountId) {
  return new Promise((resolve, reject) => {
    const GET_POSITIONS_DATA = gql`
      query getPositions($accountId: ID!) {
        getPositions(accountId: $accountId) {
          dateTime
          positions {
            assetId {
              id
              name
              description
              type
              symbol
            }
            quantity
            amount
            currentMarketValue
            dateTime
          }
        }
      }
    `;
    const result = client.query({
      query: GET_POSITIONS_DATA,
      variables: { accountId },
    });
    result.then(
      (data) => {
        resolve(data.data.getPositions);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getPerformance(client, accountId, startTime, endTime) {
  return new Promise((resolve, reject) => {
    const GET_PERFORMANCE_DATA = gql`
      query getPerformance(
        $accountId: ID!
        $startTime: DateTime!
        $endTime: DateTime!
      ) {
        getPerformance(
          accountId: $accountId
          startTime: $startTime
          endTime: $endTime
        ) {
          updatedAt
          categoryPerformances {
            category
            returns {
              returnAmount
              returnPercent
              startTime
              endTime
            }
            assetPerformances {
              returns {
                returnAmount
                returnPercent
                startTime
                endTime
              }
              assetId {
                id
                name
                description
                type
                symbol
              }
              marketValue
              totalAllocation
              categoryAllocation
            }
            marketValue
            allocation
          }
        }
      }
    `;
    const result = client.query({
      query: GET_PERFORMANCE_DATA,
      variables: { accountId, startTime, endTime },
    });
    result.then(
      (data) => {
        resolve(data.data.getPerformance);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function addRolloverAccountPortfolio(client, portfolioId) {
  return new Promise((resolve, reject) => {
    const SELECT_USER_ROLLOVER_PORTFOLIO = gql`
      mutation addRolloverAccountPortfolio($portfolioId: String!) {
        addRolloverAccountPortfolio(portfolioId: $portfolioId) {
          id
          routingNumber
          accountNumber
          name
          accountType
          isRollover
          state
          createdAt
          active
          balances {
            withdrawalBalance
            totalAccountBalance
            cashBalance
            principal
            totalPendingContributions
            totalPendingDistributions
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: SELECT_USER_ROLLOVER_PORTFOLIO,
      variables: {
        portfolioId,
      },
    });
    result.then(
      (response) => {
        resolve(response.data);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export default {
  getPortfolios,
  updateUserPortfolio,
  // getPositions,
  getPerformance,
  addRolloverAccountPortfolio,
};
