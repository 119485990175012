import React from "react";
import PropTypes from "prop-types";

import { Formik } from "formik";
import { get } from "lodash";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import { userConstants } from "actions/types";
import { ScrollToFieldError } from "utils/form";
import { Card, Col, Form } from "react-bootstrap";
import { withApollo } from "@apollo/client/react/hoc";
import { createSupportRequest } from "actions/userActions";
import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { accountTypesToEnglishMapping } from "statics/accountTypes";
import { distributionTypesToEnglishMapping } from "statics/accountTypes";

import Alert from "components/Alert";
import Button from "components/Button";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

let yup = require("yup");

const schema = yup.object({
  subject: yup
    .string()
    .label("Subject")
    .required()
    .min(2, "Must be at least two characters."),
  comment: yup
    .string()
    .label("comment")
    .required()
    .min(2, "Must be at least two characters."),
});

class DistributionSupport extends React.Component {
  static propTypes = {
    goToNamedStep: PropTypes.func,
    push: PropTypes.func,
    createSupportRequest: PropTypes.func,
    name: PropTypes.string,
    email: PropTypes.string,
    error: PropTypes.string,
    pathname: PropTypes.string,
    userState: PropTypes.string,
    selectedAccountType: PropTypes.string,
    distributionAmount: PropTypes.string,

    isSubmitting: PropTypes.bool,
    client: PropTypes.object,
    accounts: PropTypes.array,
    userHasTwoAccounts: PropTypes.bool,
    rolloverSupportFlag: PropTypes.bool,
    isProcessingRollover: PropTypes.bool,
    prefilledSubject: PropTypes.string,
    distributionType: PropTypes.string,
  };

  constructor(props) {
    super(props);

    const accountType = get(
      this.props.accounts.find(
        (account) => account.id === this.props.selectedAccountType
      ),
      "accountType",
      ""
    );

    const accountNumber = get(
      this.props.accounts.find(
        (account) => account.id === this.props.selectedAccountType
      ),
      "accountNumber",
      ""
    );
    const amount = this.props.distributionAmount.amount || "";

    const distributionType =
      distributionTypesToEnglishMapping[this.props.distributionType];

    this.state = {
      initialSubject: `Distribution Request: ${distributionType}`,
      initialComment: `${accountTypesToEnglishMapping[accountType]} (${accountNumber}) - $${amount}`,
    };
  }

  _submitRequest = (vals, { resetForm }) => {
    const requestParams = {
      comment: vals.comment,
      subject: vals.subject,
      name: this.props.name,
      email: this.props.email,
    };

    this.props
      .createSupportRequest(this.props.client, requestParams)
      .then(() => {
        if (!this.props.error) {
          toast.success("Support request submitted. You'll get an email.");
          resetForm();
        }
      });
  };

  _supportTicket = () => {
    var articleStyle = {
      paddingBottom: 0,
    };

    return (
      <div className="transactions-mega-container">
        <section className="form-sec-2col">
          <article className="col-form" style={articleStyle}>
            <div>
              <Card>
                <Card.Title style={{ padding: 20, margin: 0 }}>
                  <IconHeader
                    variant="cardHeader"
                    headerText="How can we assist with your distribution?"
                  />
                  <IconSubheader subheader="Please describe what you need help with so we can better assist you with your distribution, and we'll respond via email as soon as possible." />
                </Card.Title>

                <Card.Body>
                  <Formik
                    validateOnChange={false}
                    validationSchema={schema}
                    onSubmit={this._submitRequest}
                    enableReinitialize={true}
                    initialValues={{
                      subject: this.state.initialSubject,
                      comment: this.state.initialComment,
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <ScrollToFieldError />

                        <Form.Row>
                          <Form.Group as={Col} controlId="formGroupSubject">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                              name="subject"
                              value={values.subject}
                              onChange={handleChange}
                              isInvalid={!!errors.subject}
                              isValid={touched.subject && !errors.subject}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.subject}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGroupComment">
                            <Form.Label>Message</Form.Label>

                            <Form.Control
                              name="comment"
                              as="textarea"
                              placeholder="Please include any information you think will be helpful."
                              value={values.comment}
                              onChange={handleChange}
                              isInvalid={!!errors.comment}
                              style={{ minHeight: "150px" }}
                              isValid={touched.comment && !errors.comment}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.comment}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        {this.props.error && (
                          <Alert type="error" msg={this.props.error} />
                        )}
                        <div className="submit-row btn-row">
                          <Button
                            btnLabel={"Cancel"}
                            color={"cancel"}
                            onClick={() =>
                              this.props.goToNamedStep("distributionType")
                            }
                          />
                          <Button
                            name="submit"
                            btnLabel="Submit"
                            withArrow={true}
                            loading={this.props.isSubmitting}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </div>
          </article>
        </section>
      </div>
    );
  };

  render() {
    return <>{this._supportTicket()}</>;
  }
}

const actions = [userConstants.CREATE_REQUEST];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector([actions]);

const mapStateToProps = (state) => {
  const { firstName, lastName, email } = get(state, "user.profile", {});

  const accounts = state.accounts.iraAccounts;
  const userState = state.user.userState.state;
  const pathname = get(state.router, "location.pathname");

  return {
    email,
    name: `${firstName} ${lastName}`,
    userState,
    pathname,
    error: errorSelector(state),
    isSubmitting: loadingSelector(state),
    accounts,
  };
};

const mapDispatchToProps = {
  push,
  createSupportRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(DistributionSupport));
