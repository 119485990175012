import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import { withApollo } from "@apollo/client/react/hoc";
import { toast } from "react-toastify";
import { ScrollToFieldError } from "utils/form";
import { submitUserRolloverInfo } from "actions/userActions";
import { userConstants } from "actions/types";
import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { accountTypesToEnglishMapping } from "statics/accountTypes";
import { setAccountSession } from "actions/accountActions";
import { push } from "connected-react-router";
import { TRADITIONAL_IRA } from "statics/accountTypes";
import { FiAlertCircle } from "react-icons/fi";
import { filter, find, get } from "lodash";
import {
  Card,
  Col,
  Form,
  ListGroup,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  isRolloverSelector,
  activeRolloverAccountSameAsCurrent,
  activeRolloverIsMixed,
} from "store/selectors/user";

import Alert from "components/Alert";
import Button from "components/Button";
import IconHeader from "components/IconHeader";
import SimpleModal from "components/SimpleModal";
import IconSubheader from "components/IconSubheader";

/* eslint-disable react/no-children-prop */

let yup = require("yup");

const schema = yup.object().shape({
  amount: yup
    .number()
    .required()
    .label("Contribution Amount")
    .test("amount", `Amount must be greater than 0`, (value) => value > 0),
  financialInstitution: yup.string(),
  financialInstitutionRoth: yup.string(),
  rolloverAgreement: yup.boolean().label("Rollover Agreement"),
  rothAmount: yup.number().when("isRolloverIsMixed", {
    is: true,
    then: yup
      .number()
      .required("Roth Contribution Amount is required")
      .test(
        "rothAmount",
        "Roth Amount must be greater than 0",
        (value) => value > 0
      ),
    otherwise: yup.number(),
  }),
});

class IndividualRolloverForm extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    setAccountSession: PropTypes.func,
    submitUserRolloverInfo: PropTypes.func,
    error: PropTypes.string,
    userState: PropTypes.string,
    isFetching: PropTypes.bool,
    client: PropTypes.object,
    accountType: PropTypes.string,
    accounts: PropTypes.arrayOf(PropTypes.object),
    isRolloverAccount: PropTypes.bool,
    rolloverAccountType: PropTypes.object,
    isRolloverSameAsCurrent: PropTypes.bool,
    isRolloverIsMixed: PropTypes.bool,
    activeRollovers: PropTypes.array,
    traditionalAccountNumber: PropTypes.string,
    rothAccountNumber: PropTypes.string,
    rolloverAccountNumber: PropTypes.string,
    rothAccountId: PropTypes.string,
    traditionalAccountId: PropTypes.string,
    rolloverAccountId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      financialInstitution: "",
      financialInstitutionRoth: "",
      amount: undefined,
      rothAmount: undefined,
      loading: false,
      showCheckExampleModal: false,
    };
  }

  _submitRequest = (values) => {
    let params;

    const {
      amount,
      rolloverAgreement,
      financialInstitution,
      rothAmount,
      financialInstitutionRoth,
    } = values;
    const {
      isRolloverIsMixed,
      rolloverAccountType,
      traditionalAccountId,
      rothAccountId,
      rolloverAccountId,
    } = this.props;

    if (isRolloverIsMixed) {
      params = [
        {
          amount,
          accountId: traditionalAccountId,
          financialInstitution,
          rolloverAgreement,
          accountType: "TRADITIONAL_IRA",
        },
        {
          amount: rothAmount,
          accountId: rothAccountId,
          financialInstitution: financialInstitutionRoth,
          rolloverAgreement,
          accountType: "ROTH_IRA",
        },
      ];
    } else {
      params = [
        {
          amount,
          accountId: rolloverAccountId,
          financialInstitution,
          rolloverAgreement,
          accountType: rolloverAccountType,
        },
      ];
    }

    const isMultiAccount = this.props.accounts.length > 1;
    const setAccountID = isMultiAccount ? "" : rolloverAccountId;

    this.props
      .submitUserRolloverInfo(this.props.client, params)
      .then(async () => {
        if (!this.props.error) {
          toast.success("Your rollover information has been submitted.");
          await this.props.setAccountSession(this.props.client, setAccountID);
          window.location.search = `?redirect_to=/dashboard`;
        } else {
          toast.error("There was a problem submitting information.");
        }
      })
      .catch(() => {
        toast.error("There was a problem submitting information.");
      });
  };

  _onAmountChange = (event, setFieldValue) => {
    const amount = +event.target.value;
    setFieldValue("amount", amount);
  };

  _onRothAmountChange = (event, setFieldValue) => {
    const rothAmount = +event.target.value;
    setFieldValue("rothAmount", rothAmount);
  };

  _renderTooltip = (props) => {
    return (
      <Tooltip className="tooltip" {...props}>
        Your IRA account number will also be provided on the instructions
        available on your dashboard once your account has been successfully
        approved.
      </Tooltip>
    );
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    const tooltip = (
      <OverlayTrigger
        className="OverlayTrigger"
        placement="auto"
        overlay={this._renderTooltip}
        containerPadding={12}
        transition={true}
      >
        <FiAlertCircle
          className="FiAlertCircle"
          size={16}
          fill={"#FFFFFF"}
          color={"#FFFFFF"}
          stroke={"#009BAA"}
          strokeWidth={"1.5"}
        />
      </OverlayTrigger>
    );

    const account = this.props.isRolloverIsMixed
      ? TRADITIONAL_IRA
      : this.props.rolloverAccountType;

    const userHasMixedRollover = this.props.isRolloverIsMixed
      ? "Usually two checks are issued when you roll over a mixed 401(k) into an IRA. One of these checks is for the pre-tax dollars (Traditional) from your mixed 401(k) and the other is for your post-tax (Roth) dollars. "
      : "Let us help you move a previous retirement account to Icon.";

    return (
      <>
        <div className="transactions-mega-container">
          <section className="form-sec-2col">
            <article className="col-form" style={articleStyle}>
              {this.state.showCheckExampleModal && (
                <SimpleModal
                  show={true}
                  size={"lg"}
                  onClose={() =>
                    this.setState({
                      showCheckExampleModal: false,
                    })
                  }
                  title="Check Example"
                  buttonText="close"
                >
                  <img className="unicorn" src="/assets/check-example.svg" />
                </SimpleModal>
              )}
              <div>
                <Card>
                  <Card.Title style={{ padding: 20, margin: 0 }}>
                    <IconHeader
                      variant="cardHeader"
                      headerText="Icon Rollover Form"
                    />
                    <IconSubheader subheader={userHasMixedRollover} />
                  </Card.Title>

                  <Card.Body>
                    <Formik
                      validateOnChange={false}
                      validationSchema={schema}
                      onSubmit={(values) => {
                        this._submitRequest(values);
                      }}
                      enableReinitialize={true}
                      initialValues={{
                        rolloverAgreement: false,
                        isRolloverIsMixed: this.props.isRolloverIsMixed,
                        financialInstitution: "",
                        financialInstitutionRoth: "",
                      }}
                    >
                      {({
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        handleChange,
                        values,
                        touched,
                        errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <ScrollToFieldError />

                          <div
                            style={{
                              paddingBottom: 20,
                              display: "flex",
                              flexDirection: "column",
                              gap: 10,
                            }}
                          >
                            <IconHeader
                              variant="labelHeader"
                              headerText={
                                this.props.isRolloverIsMixed
                                  ? "Icon Account Numbers"
                                  : "Icon Account Number"
                              }
                            />

                            {!this.props.isRolloverIsMixed && (
                              <Form.Label>
                                {accountTypesToEnglishMapping[account]}:{" "}
                                {this.props.rolloverAccountNumber}
                              </Form.Label>
                            )}

                            {this.props.isRolloverIsMixed && (
                              <>
                                <Form.Label>
                                  {"Traditional IRA"}:{" "}
                                  {this.props.traditionalAccountNumber}
                                </Form.Label>
                                <Form.Label>
                                  {"Roth IRA"}: {this.props.rothAccountNumber}
                                </Form.Label>
                              </>
                            )}
                          </div>

                          <IconHeader
                            variant="labelHeader"
                            headerText="Account Information"
                          />

                          <Form.Row style={{ paddingTop: 20 }}>
                            <Form.Group
                              as={Col}
                              controlId="formContributionAmount"
                            >
                              <Form.Label>
                                {accountTypesToEnglishMapping[account]}{" "}
                                Contribution Amount
                              </Form.Label>
                              <Form.Control
                                sm={4}
                                type="number"
                                name="amount"
                                placeholder="0.00"
                                value={values.amount}
                                onChange={(e) =>
                                  this._onAmountChange(e, setFieldValue)
                                }
                                onBlur={handleBlur}
                                isInvalid={touched.amount && !!errors.amount}
                                isValid={touched.amount && !errors.amount}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.amount}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm={6}>
                              <Form.Label>Financial Institution</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={1}
                                name="financialInstitution"
                                value={values.financialInstitution}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.financialInstitution &&
                                  !!errors.financialInstitution
                                }
                                isValid={
                                  touched.financialInstitution &&
                                  !errors.financialInstitution
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Financial Institution is a required field.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                          {this.props.isRolloverIsMixed && (
                            <>
                              <Form.Row>
                                <Form.Group
                                  as={Col}
                                  sm={6}
                                  controlId="formAccountType"
                                ></Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group
                                  as={Col}
                                  controlId="formContributionAmount"
                                >
                                  <Form.Label>
                                    Roth IRA Contribution Amount
                                  </Form.Label>
                                  <Form.Control
                                    sm={4}
                                    type="number"
                                    name="rothAmount"
                                    placeholder="0.00"
                                    value={values.rothAmount}
                                    onChange={(e) =>
                                      this._onRothAmountChange(e, setFieldValue)
                                    }
                                    onBlur={handleBlur}
                                    isInvalid={
                                      touched.rothAmount && !!errors.rothAmount
                                    }
                                    isValid={
                                      touched.rothAmount && !errors.rothAmount
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.rothAmount}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} sm={6}>
                                  <Form.Label>Financial Institution</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={1}
                                    name="financialInstitutionRoth"
                                    value={values.financialInstitutionRoth}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      touched.financialInstitutionRoth &&
                                      !!errors.financialInstitutionRoth
                                    }
                                    isValid={
                                      touched.financialInstitutionRoth &&
                                      !errors.financialInstitutionRoth
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Financial Institution is a required field.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Form.Row>
                            </>
                          )}
                          {this.props.error && (
                            <Alert type="error" msg={this.props.error} />
                          )}

                          <div style={{ paddingTop: 20 }}>
                            <IconHeader
                              variant="labelHeader"
                              headerText="Rollover Instructions"
                            />
                            <IconSubheader subheader="To start rolling over funds to your new account, contact your plan provider and request a rollover. Try calling the phone number on your statement and provide them the instructions below." />
                            <Row style={{ paddingTop: 20 }}>
                              <ListGroup
                                as="ol"
                                numbered
                                className="custom-list-group"
                              >
                                <ListGroup.Item as="li">
                                  1. Make check payable to: “Apex Clearing
                                  Corporation, FBO [Your Name]”
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                  2. Make sure the check has the IRA account
                                  number printed on it. {tooltip}
                                  <br />
                                  <div
                                    style={{
                                      marginTop: 20,
                                      marginLeft: 15,
                                      padding: 20,
                                      background: "#F7FEFF",
                                      borderRadius: 5,
                                    }}
                                  >
                                    Some plan administrators will only mail the
                                    check to your address. If your account
                                    information is not on the check, please
                                    hand-write it on the check and forward it to
                                    the Apex Clearing Corporation, FBO Your Name
                                    address.
                                  </div>
                                  <br />
                                  <div
                                    style={{
                                      marginLeft: 15,
                                      paddingTop: 5,
                                      cursor: "pointer",
                                      color: "#009BAA",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        showCheckExampleModal: true,
                                      })
                                    }
                                  >
                                    See Example
                                  </div>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                  3. Send the check to the address below:
                                  <div
                                    style={{ marginLeft: 15, paddingTop: 5 }}
                                  >
                                    <address>
                                      Apex Clearing Corporation
                                      <br />
                                      Attn. Banking Department
                                      <br />
                                      350 North St. Paul Street, Suite 1300
                                      <br />
                                      Dallas, Texas 75201
                                    </address>
                                  </div>
                                </ListGroup.Item>
                              </ListGroup>
                            </Row>
                          </div>

                          <div
                            style={{
                              borderTop: "1px solid #E7E7E8",
                              marginTop: 20,
                              paddingBottom: 30,
                            }}
                          ></div>

                          <div className="terms-of-service">
                            <p className="terms-label">TRUSTED CONTACT</p>
                            <p>
                              Under FINRA Rule 4512 Apex Clearing Corporation is
                              required to disclose to you (the customer) that
                              Apex Clearing Corporation or an associated person
                              of Apex Clearing Corporation is authorized to
                              contact the trusted contact person and disclose
                              information about the customer’s account to
                              address possible financial exploitation, to
                              confirm the specifics of the customer’s current
                              contact information, health status, or the
                              identity of any legal guardian, executor, trustee
                              or holder of a power of attorney, or as otherwise
                              permitted by FINRA Rule 2165.
                            </p>

                            <p className="terms-label">
                              IRREVOCABLE ROLLOVER DESIGNATION
                            </p>
                            <p>
                              I attest and acknowledge that the funds deposited
                              are eligible to be contributed to this IRA, that
                              the assets are the same assets that were
                              distributed as a Direct Rollover or as an Indirect
                              Rollover less than 60 days ago, and that I am
                              allowed only one 60-day rollover distribution from
                              an IRA in a rolling 12-month period regardless of
                              the number of IRAs I own. Due to the important tax
                              consequences of rolling over a cash balance, or
                              securities, I have been advised to consult with a
                              tax professional and I attest that the custodian
                              has not provided any tax advice. By selecting this
                              option, I certify that no part of his contribution
                              contains amounts required to be distributed under
                              Internal Revenue Code Sections 408(a)(6) and
                              401(a)(9), and I acknowledge that I am making an
                              irrevocable election to designate this
                              contribution as a rollover contribution.
                            </p>
                          </div>

                          <Form.Row>
                            <Form.Group
                              as={Col}
                              sm={12}
                              controlId="formBasicRolloverAgreement"
                            >
                              <Form.Check
                                type="checkbox"
                                id="rolloverAgreement"
                                name="rolloverAgreement"
                                label="I Agree"
                                checked={values.rolloverAgreement}
                                value={values.rolloverAgreement}
                                onChange={(e) => {
                                  setFieldValue(
                                    "rolloverAgreement",
                                    e.target.checked
                                  );
                                }}
                              />
                              <p
                                style={{
                                  fontStyle: "italic",
                                  fontSize: 14,
                                  paddingTop: 10,
                                  paddingLeft: 4,
                                  color: "#0a2540",
                                }}
                              >
                                By checking {'"I Agree"'} you are confirming
                                that all information provided by me is true and
                                correct and may be relied upon by the custodian.
                                I assume full responsibility for these
                                transactions and release, indemnify and hold
                                Apex Clearing Corporation and its officers,
                                directors, employees, affiliates, assigns,
                                agents, employees or successors harmless from
                                and against any and all liabilities, damages,
                                losses, costs (including attorney’s fees),
                                claims or actions arising from or related to any
                                errors, improper instructions, or
                                misrepresentations in this request.
                              </p>
                            </Form.Group>
                          </Form.Row>
                          <div className="submit-row btn-row">
                            <Button
                              type="button"
                              btnLabel={"Cancel"}
                              color={"cancel"}
                              name="cancel"
                              onClick={() => this.props.push("/dashboard")}
                            />
                            <Button
                              type="submit"
                              btnLabel="Submit"
                              disabled={!values.rolloverAgreement}
                              withArrow={true}
                              loading={this.props.isFetching}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </div>
            </article>
          </section>
        </div>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  userConstants.SUBMIT_USER_ROLLOVER_INFO,
]);

const errorSelector = createErrorSelector([
  userConstants.SUBMIT_USER_ROLLOVER_INFO,
]);

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  const accounts = state.accounts.iraAccounts;

  const activeRollovers = filter(accounts, {
    isProcessingRollover: true,
    isRollover: true,
    state: "AccountActive",
  });

  const rolloverAccount = filter(accounts, {
    isRollover: true,
  });
  const rolloverAccountType = get(rolloverAccount, "[0].accountType", null);
  const rolloverAccountNumber = get(rolloverAccount, "[0].accountNumber", null);
  const rolloverAccountId = get(rolloverAccount, "[0].id", null);

  const traditionalAccount = find(accounts, { accountType: "TRADITIONAL_IRA" });
  const rothAccount = find(accounts, { accountType: "ROTH_IRA" });

  const traditionalAccountNumber = get(traditionalAccount, "accountNumber");
  const traditionalAccountId = get(traditionalAccount, "id");
  const rothAccountNumber = get(rothAccount, "accountNumber");
  const rothAccountId = get(rothAccount, "id");

  return {
    userState,
    accounts,
    activeRollovers,
    rolloverAccountType,
    error: errorSelector(state),
    isFetching: loadingSelector(state),
    isRolloverAccount: isRolloverSelector(state),
    isRolloverSameAsCurrent: activeRolloverAccountSameAsCurrent(state),
    isRolloverIsMixed: activeRolloverIsMixed(state),
    traditionalAccountNumber,
    rothAccountNumber,
    rolloverAccountNumber,
    rothAccountId,
    traditionalAccountId,
    rolloverAccountId,
  };
};

const mapDispatchToProps = {
  push,
  submitUserRolloverInfo,
  setAccountSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualRolloverForm));
