import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { isEmpty, get, some } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { bankConstants, payrollConstants } from "actions/types";
import { FiAlertTriangle } from "react-icons/fi";
import { isEmployerSelector } from "store/selectors/user";
import {
  getPayrollHistory,
  cancelPayrollsByBankId,
} from "actions/payrollActions";
import { deleteEmployerBank } from "actions/bankActions";

import Button from "components/Button";
import Alert from "components/Alert";
import IconCircle from "components/IconCircle";
import IconHeader from "components/IconHeader";

class RemoveBank extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    isEmployer: PropTypes.bool,
    getPayrollHistory: PropTypes.func,
    cancelPayrollsByBankId: PropTypes.func,
    deleteEmployerBank: PropTypes.func,
    bankId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = { isDeleting: false };
  }

  _removeBankLink = () => {
    const bankId = this.props.bankId;
    if (this.props.isEmployer) {
      this.props.getPayrollHistory(this.props.client).then((data) => {
        if (data && data.error) {
          return Promise.resolve();
        }
        const payrollHistory = get(data, "data.payrollHistory", []);
        const approved = payrollHistory.filter(
          (payroll) => payroll.status === "APPROVED"
        );
        const processing = payrollHistory.filter(
          (payroll) => payroll.status === "PROCESSING"
        );
        if (this._isBankInList(approved, bankId)) {
          return this.setState({
            showApprovedModal: true,
          });
        } else if (this._isBankInList(processing, bankId)) {
          return this.setState({
            showProcessingModal: true,
          });
        } else {
          return this._deleteEmployerBank().then(() => {
            if (!this.props.error) {
              toast.success("Bank account removed successfully");
            } else {
              toast.error("There was a problem removing bank account");
            }
          });
        }
      });

      return;
    }
  };

  _isBankInList = (payrolls) => {
    const bankId = this.props.bankId;
    return some(payrolls, (payroll) => payroll.bankId === bankId);
  };

  _deleteEmployerBank = (cancelPayrolls = false) => {
    this.setState({
      showApprovedModal: false,
    });

    const bankId = this.props.bankId;

    if (!cancelPayrolls) {
      return this.props.deleteEmployerBank(this.props.client, bankId);
    }
    return this.props
      .cancelPayrollsByBankId(this.props.client, bankId)
      .then((data) => {
        if (data && data.error) {
          return Promise.resolve();
        }
        return this.props.deleteEmployerBank(this.props.client, bankId);
      });
  };

  render() {
    const header = "Are you sure you want to remove your bank account?";
    return (
      <div>
        <Card>
          <Card.Body>
            <IconCircle
              type="error"
              icon={
                <FiAlertTriangle color="white" stroke="#B12121" size={16} />
              }
            />
            <IconHeader variant="centeredText" headerText={header} />

            {!isEmpty(this.props.error) && (
              <Alert type="error" msg={this.props.error} />
            )}

            <div className="remove-bank-action">
              <div className="bank-cancel">
                <Button
                  size="sm"
                  color="cancel"
                  name="cancel"
                  btnLabel="Cancel"
                  onClick={() => this.setState({ isDeleting: false })}
                />
              </div>
              <Button
                size="sm"
                color="red"
                name="submit"
                btnLabel="Remove"
                onClick={() => this._removeBankLink()}
                withArrow={true}
                loading={this.props.isLoading}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const actions = [
  payrollConstants.GET_PAYROLL_HISTORY,
  payrollConstants.CANCEL_PAYROLLS,
  bankConstants.DELETE_EMPLOYER_BANK,
];

const loadingSelector = createLoadingSelector([actions]);
const errorSelector = createErrorSelector([actions]);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isLoading: loadingSelector(state),
    isEmployer: isEmployerSelector(state),
  };
};

const mapDispatchToProps = {
  deleteEmployerBank,
  getPayrollHistory,
  cancelPayrollsByBankId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(RemoveBank));
