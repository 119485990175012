import React from "react";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  getCompanyInfo,
  updateRequiredOrderForm,
} from "actions/employerActions";
import Button from "components/Button";
import Alert from "components/Alert";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { withApollo } from "@apollo/client/react/hoc";
import IconSpinner from "components/IconSpinner";
import TermsModal from "pages/signUp/TermsModal";
import { employerConstants } from "actions/types";
import { getUserProfile, getUserState } from "actions/userActions";
import { userEmail, userProfile } from "store/selectors/user";
import { isEmpty } from "lodash";
import EmployerAdminNameForm from "components/EmployerAdminNameForm";

const TYPE_TO_ORDER_FORM = {
  ICON_ORDER_FORM_AGREEMENT: "employerOrder",
  ISOLVED_ORDER_FORM_AGREEMENT: "isolvedOrder",
  ICON_SALES_QUOTE_ORDER_FORM_AGREEMENT: "employerSalesQuoteOrder",
};

class OrderFormAgreements extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    push: PropTypes.func,
    error: PropTypes.string,
    orderForm: PropTypes.string,
    isFetching: PropTypes.bool,
    updateRequiredOrderForm: PropTypes.func,
    getCompanyInfo: PropTypes.func,
    getUserState: PropTypes.func,
    getUserProfile: PropTypes.func,
    userProfile: PropTypes.object,
    userEmail: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      termsOfOrder: false,
      submitted: false,
      showTermsOfOrder: false,
      loading: false,
      theController: {},
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.props.getCompanyInfo(this.props.client),
      this.props.getUserProfile(this.props.client),
    ]);

    this.setState({ loading: false });
  }

  _submitOrderAgreements = (e) => {
    e.preventDefault();
    // update required updates
    this.props
      .updateRequiredOrderForm(this.props.client, this.props.orderForm)
      .then(() => {
        if (!this.props.error) {
          this.props.getUserState(this.props.client).then(() => {
            this.props.push("/dashboard");
          });
        }
      });

    if (this._isMounted) {
      this.setState({
        submitted: true,
      });
    }
  };

  _handleCheckBoxChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  render() {
    let content;
    const { firstName, lastName } = this.props.userProfile;

    if (this.state.loading) {
      content = <IconSpinner centered />;
    } else if (isEmpty(firstName) || isEmpty(lastName)) {
      content = <EmployerAdminNameForm />;
    } else {
      content = this.renderForm();
    }

    return content;
  }

  renderForm = () => {
    const termsInfo = {
      ...this.props.userProfile,
      userEmail: this.props.userEmail,
    };
    return (
      <div id="employer-terms">
        <TermsModal
          termsInfo={termsInfo}
          type={TYPE_TO_ORDER_FORM[this.props.orderForm]}
          show={this.state.showTermsOfOrder}
          onClose={() => this.setState({ showTermsOfOrder: false })}
        />
        <Form noValidate onSubmit={this._submitOrderAgreements}>
          <div className="mega-container">
            <div className="step-container is-active" data-circle-percent="100">
              <section className="form-sec-2col">
                <section className="page-title-wrap">
                  <article className="text-cell">
                    <h1 className="page-title">Agreements</h1>
                    <p className="page-subtext">
                      Please review the following Order Form(s) agreements
                    </p>

                    <div id="form-employer-company-profile">
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          sm={12}
                          controlId="formBasictermsOfOrder"
                        >
                          <Form.Check
                            type="checkbox"
                            id="termsOfOrder"
                            name="termsOfOrder"
                            label="Order Form"
                            value={this.state.termsOfOrder}
                            onChange={this._handleCheckBoxChange}
                          />
                          <div
                            className="modal-link"
                            onClick={() => {
                              this.setState({
                                showTermsOfOrder: true,
                              });
                              window.analytics.track("Viewed Order Form");
                            }}
                          >
                            (View Order Form)
                          </div>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          sm={12}
                          controlId="formBasicFinalAgreementSpacing"
                        >
                          {" "}
                        </Form.Group>
                      </Form.Row>

                      <div className="submit-row">
                        {this.props.error && (
                          <Alert type="error" msg={this.props.error} />
                        )}
                        <Button
                          name="submit"
                          disabled={!this.state.termsOfOrder}
                          withArrow={true}
                          btnLabel="Accept"
                          loading={this.props.isFetching}
                        />
                      </div>
                    </div>
                  </article>
                </section>
              </section>
            </div>
          </div>
        </Form>
      </div>
    );
  };
}

const actions = [
  employerConstants.GET_EMPLOYER_REQUIRED_STEPS,
  employerConstants.UPDATE_EMPLOYER_REQUIRED_ORDER_FORM,
];

const loadingSelector = createLoadingSelector(actions);

const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  isFetching: loadingSelector(state),
  userEmail: userEmail(state),
  userProfile: userProfile(state),
});

const mapDispatchToProps = {
  push,
  updateRequiredOrderForm,
  getUserState,
  getUserProfile,
  getCompanyInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(OrderFormAgreements));
