import gql from "graphql-tag";
import { get } from "lodash";

function register(
  client,
  { company: employer, organizationId, needsStatePlan }
) {
  return new Promise((resolve, reject) => {
    const ADD_COMPANY = gql`
      mutation Register(
        $name: String!
        $dba: String
        $ein: String
        $type: String
        $naicsCode: String!
        $size: String!
        $url: String
        $address1: String!
        $address2: String
        $city: String!
        $state: String!
        $postalCode: String!
        $phone: String!
        $phoneExt: String
        $fax: String
        $referralCode: String
        $organizationId: ID
        $payrollProvider: String!
        $planCode: String
        $needsStatePlan: Boolean!
      ) {
        addCompany(
          name: $name
          dba: $dba
          ein: $ein
          type: $type
          naicsCode: $naicsCode
          size: $size
          url: $url
          address1: $address1
          address2: $address2
          city: $city
          state: $state
          postalCode: $postalCode
          phone: $phone
          phoneExt: $phoneExt
          fax: $fax
          referralCode: $referralCode
          organizationId: $organizationId
          payrollProvider: $payrollProvider
          needsStatePlan: $needsStatePlan
          planCode: $planCode
        ) {
          company {
            id
            name
            dba
            type
            naicsCode
            size
            url
            referralCode
            planCode
            limits {
              key
              value
            }
            address {
              id
              address1
              address2
              city
              state
              postalCode
              phone
              phoneExt
              fax
            }
          }
          error
        }
      }
    `;
    const result = client.mutate({
      mutation: ADD_COMPANY,
      variables: {
        name: employer.name,
        dba: employer.dba,
        ein: employer.ein,
        type: employer.type,
        naicsCode: employer.naicsCode,
        size: employer.size,
        url: employer.url,
        address1: employer.address1,
        address2: employer.address2,
        city: employer.city,
        state: employer.state,
        postalCode: employer.postalCode,
        phone: employer.phone,
        phoneExt: employer.phoneExt,
        fax: employer.fax,
        payrollProvider: employer.payrollProvider,
        organizationId,
        needsStatePlan,
        planCode: employer.planCode,
      },
    });
    result.then(
      (response) => {
        if (response.data.error) {
          reject(response.data.error);
        } else {
          resolve(response.data.addCompany);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

export function submitEmployerAgents(client, employerAgent) {
  return new Promise((resolve, reject) => {
    const REGISTER_AGENT = gql`
      mutation postEmployerAgent(
        $firstName: String!
        $lastName: String!
        $email: String!
        $role: String!
      ) {
        postEmployerAgent(
          firstName: $firstName
          lastName: $lastName
          email: $email
          role: $role
        ) {
          error
        }
      }
    `;
    const result = client.mutate({
      mutation: REGISTER_AGENT,
      variables: {
        firstName: employerAgent.firstName,
        lastName: employerAgent.lastName,
        email: employerAgent.email,
        role: employerAgent.role,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

// get company details
function getCompany(client) {
  return new Promise((resolve, reject) => {
    const GET_COMPANY = gql`
      query getCompany {
        company {
          id
          name
          dba
          ein
          type
          naicsCode
          size
          url
          planId
          enabledProductFeatures
          planCode
          payrollProvider
          billingType
          limits {
            key
            value
          }
          address {
            id
            address1
            address2
            city
            state
            postalCode
            phone
            phoneExt
            fax
          }
          planTypes {
            id
            description
            name
          }
          finchStatus
          payrollIntegrationStatus
          hasRunFirstPayroll
          referralId
          referralPlans {
            min
            max
            planId
            referralCredit
            refereeCredit
          }
          progressiveOnboardingState
        }
      }
    `;
    const result = client.query({
      query: GET_COMPANY,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.company);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function findEmployerByUser(client) {
  return new Promise((resolve, reject) => {
    const COMPANY_BY_USER = gql`
      query CompanyByUser {
        companyByUser {
          company {
            id
            name
            dba
            ein
            type
            naicsCode
            size
            url
            address {
              id
              address1
              address2
              city
              state
              postalCode
              phone
              phoneExt
              fax
            }
          }
          error
        }
      }
    `;
    const result = client.query({
      query: COMPANY_BY_USER,
    });
    result.then(
      (response) => {
        if (response.data.companyByUser.error) {
          reject(response.data.companyByUser.error);
        } else {
          resolve(response.data.companyByUser.company);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

// get Employer Accounts
function getAllEmployerAccounts(client) {
  return new Promise((resolve, reject) => {
    const GET_ALL_ACCOUNTS = gql`
      query employerAccountList {
        employerAccountList {
          accountId
          id
          accountType
          accountSubtype
          userId
          bankName
          bankAlias
          linkStatus
          state
        }
      }
    `;
    const result = client.query({
      query: GET_ALL_ACCOUNTS,
      variables: {},
      fetchPolicy: "network-only",
    });
    result.then(
      (data) => {
        resolve(data.data.employerAccountList);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

// get Employer Groups
function getAllEmployerGroups(client) {
  return new Promise((resolve, reject) => {
    const GET_ALL_GROUPS = gql`
      query getEmployerGroups {
        getEmployerGroups {
          id
          name
          payrollFrequency
          useUniqueInviteCodes
          associatedBankAccount {
            id
            bankName
          }
          associatedLinkRequests {
            status
            id
          }
          associatedSchedule {
            start
            end
            payrollProcessingDate
            onOrAfterPayrollProcessingDate
            status
            gracePeriodEndDate
          }
          schedule {
            anchorPayDate
            displayAnchorPayDate
            displayDayOne
            displayDayTwo
          }
          planType
          companyGroupIndex
        }
      }
    `;
    const result = client.query({
      query: GET_ALL_GROUPS,
      variables: {},
      fetchPolicy: "network-only",
    });
    result.then(
      (data) => {
        resolve(data.data.getEmployerGroups);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getEmployerGroup(client, groupId) {
  return new Promise((resolve, reject) => {
    const GET_ALL_GROUPS = gql`
      query getEmployerGroup($groupId: ID!) {
        getEmployerGroup(groupId: $groupId) {
          id
          name
          payrollFrequency
          useUniqueInviteCodes
          associatedBankAccount {
            id
            bankName
          }
          associatedLinkRequests {
            status
            id
          }
          planType
          companyGroupIndex
        }
      }
    `;
    const result = client.query({
      query: GET_ALL_GROUPS,
      variables: { groupId },
      fetchPolicy: "network-only",
    });
    result.then(
      (data) => {
        resolve(data.data.getEmployerGroup);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

// Add Employer Group
function addEmployerGroup(client, companyId, group) {
  return new Promise((resolve, reject) => {
    const ADD_EMPLOYER_GROUP = gql`
      mutation addEmployerGroup(
        $name: String!
        $companyId: String
        $payrollFrequency: Frequency!
        $payrollProcessingOffset: Int!
        $dayOne: Int
        $dayTwo: Int
        $anchorPayDate: Date!
        $userBankId: ID!
        $planType: PlanType!
        $useUniqueInviteCodes: Boolean!
        $displayDayOne: Int
        $displayDayTwo: Int
        $displayAnchorPayDate: Date
      ) {
        addEmployerGroup(
          input: {
            name: $name
            payrollFrequency: $payrollFrequency
            payrollProcessingOffset: $payrollProcessingOffset
            dayOne: $dayOne
            dayTwo: $dayTwo
            anchorPayDate: $anchorPayDate
            userBankId: $userBankId
            planType: $planType
            useUniqueInviteCodes: $useUniqueInviteCodes
            displayDayOne: $displayDayOne
            displayDayTwo: $displayDayTwo
            displayAnchorPayDate: $displayAnchorPayDate
            companyId: $companyId
          }
        ) {
          id
          name
          payrollFrequency
          useUniqueInviteCodes
          associatedBankAccount {
            id
            bankName
          }
        }
      }
    `;

    const result = client.mutate({
      mutation: ADD_EMPLOYER_GROUP,
      variables: {
        name: group.name,
        payrollFrequency: group.payrollFrequency,
        payrollProcessingOffset: group.payrollProcessingOffset,
        anchorPayDate: group.anchorPayDate,
        userBankId: group.associatedBankAccount,
        dayOne: group.dayOne,
        dayTwo: group.dayTwo,
        planType: group.planType,
        useUniqueInviteCodes: group.useUniqueInviteCodes,
        displayAnchorPayDate: group.displayAnchorPayDate,
        displayDayOne: group.displayDayOne,
        displayDayTwo: group.displayDayTwo,
        companyId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.addEmployerGroup);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

// Update Employer Group
function updateEmployerGroup(client, group, companyId) {
  return new Promise((resolve, reject) => {
    const UPDATE_EMPLOYER_GROUP = gql`
      mutation updateEmployerGroup(
        $id: ID!
        $companyId: ID
        $name: String!
        $userBankId: ID!
        $useUniqueInviteCodes: Boolean!
        $displayDayOne: Int
        $displayDayTwo: Int
        $displayAnchorPayDate: Date
      ) {
        updateEmployerGroup(
          id: $id
          name: $name
          userBankId: $userBankId
          useUniqueInviteCodes: $useUniqueInviteCodes
          displayDayOne: $displayDayOne
          displayDayTwo: $displayDayTwo
          displayAnchorPayDate: $displayAnchorPayDate
          companyId: $companyId
        ) {
          id
          name
          payrollFrequency
          useUniqueInviteCodes
          companyGroupIndex
          associatedBankAccount {
            id
            bankName
          }
          associatedLinkRequests {
            status
            id
          }
          schedule {
            displayAnchorPayDate
            displayDayOne
            displayDayTwo
          }
        }
      }
    `;

    const result = client.mutate({
      mutation: UPDATE_EMPLOYER_GROUP,
      variables: {
        id: group.id,
        name: group.name,
        userBankId: group.associatedBankAccount,
        useUniqueInviteCodes: group.useUniqueInviteCodes,
        displayAnchorPayDate: group.displayAnchorPayDate,
        displayDayOne: group.displayDayOne,
        displayDayTwo: group.displayDayTwo,
        companyId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.updateEmployerGroup);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

// Delete Employer Group
function deleteEmployerGroup(client, id, companyId) {
  return new Promise((resolve, reject) => {
    const DELETE_GROUP = gql`
      mutation deleteEmployerGroup($id: ID!, $companyId: ID) {
        deleteEmployerGroup(id: $id, companyId: $companyId)
      }
    `;
    const result = client.mutate({
      mutation: DELETE_GROUP,
      variables: { id, companyId },
    });
    result.then(
      () => {
        resolve(id);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function inviteEmployees(client, userEmails, groupId) {
  return new Promise((resolve, reject) => {
    const INVITE_EMPLOYEE = gql`
      mutation inviteUsers($groupId: ID!, $userEmails: [String!]) {
        inviteUsers(groupId: $groupId, userEmails: $userEmails) {
          id
          invitationCode
          companyId
          userEmail
          groupId
          expiresAt
          status
        }
      }
    `;

    const result = client.mutate({
      mutation: INVITE_EMPLOYEE,
      variables: {
        groupId,
        userEmails,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.inviteUsers);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function resendInvitation(
  client,
  { userEmail, groupId, id: existingInviteId, firstName }
) {
  return new Promise((resolve, reject) => {
    const INVITE_EMPLOYEE = gql`
      mutation resendInvitation(
        $groupId: ID!
        $userEmail: String!
        $existingInviteId: ID!
        $firstName: String
      ) {
        resendInvitation(
          groupId: $groupId
          userEmail: $userEmail
          existingInviteId: $existingInviteId
          firstName: $firstName
        ) {
          id
          invitationCode
          companyId
          userEmail
          groupId
          expiresAt
          firstName
          status
        }
      }
    `;

    const result = client.mutate({
      mutation: INVITE_EMPLOYEE,
      variables: {
        groupId,
        existingInviteId,
        firstName,
        userEmail,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.resendInvitation);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function revokeInvitation(client, invitationId) {
  return new Promise((resolve, reject) => {
    const REVOKE_INVITE = gql`
      mutation revokeInvitation($invitationId: ID!) {
        revokeInvitation(invitationId: $invitationId) {
          id
          invitationCode
          companyId
          userEmail
          groupId
          expiresAt
          firstName
          status
        }
      }
    `;

    const result = client.mutate({
      mutation: REVOKE_INVITE,
      variables: {
        invitationId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.revokeInvitation);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getEmployeeInvites(client) {
  return new Promise((resolve, reject) => {
    const EMPLOYEE_INVITES = gql`
      query getAllEmployeeInvitesForCompany {
        getAllEmployeeInvitesForCompany {
          id
          invitationCode
          companyId
          userEmail
          groupId
          expiresAt
          status
        }
      }
    `;
    const result = client.query({
      query: EMPLOYEE_INVITES,
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        resolve(response.data.getAllEmployeeInvitesForCompany);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getTakeRateEmployeesForCompany(client, groupId, timeframe) {
  return new Promise((resolve, reject) => {
    const GET_TAKE_RATE_EMPLOYEES = gql`
      query getTakeRateEmployeesForCompany($groupId: ID, $timeframe: String!) {
        getTakeRateEmployeesForCompany(
          groupId: $groupId
          timeframe: $timeframe
        ) {
          notStarted
          opening
          enrolled
        }
      }
    `;
    const result = client.query({
      query: GET_TAKE_RATE_EMPLOYEES,
      variables: { groupId, timeframe },
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        resolve(response.data.getTakeRateEmployeesForCompany);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getLinkedEmployees(client) {
  return new Promise((resolve, reject) => {
    const LINKED_EMPLOYEES = gql`
      query getLinkedEmployees {
        getLinkedEmployees {
          id
          employeeId
          company {
            id
            name
          }
          groupWithSchedule {
            group {
              id
              name
              payrollFrequency
              companyGroupIndex
              useUniqueInviteCodes
              associatedBankAccount {
                id
              }
            }
          }
          contribution {
            amount
            id
          }
          userProfile {
            firstName
            lastName
            email
            lastFourOfSsn
            dob
          }
        }
      }
    `;
    const result = client.query({
      query: LINKED_EMPLOYEES,
    });
    result.then(
      (response) => {
        resolve(response.data.getLinkedEmployees);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getPendingLinkedEmployees(client) {
  return new Promise((resolve, reject) => {
    const LINKED_EMPLOYEES = gql`
      query getEmployerLinkRequests {
        getEmployerLinkRequests {
          id
          status
          employeeId
          company {
            id
            name
          }
          group {
            useUniqueInviteCodes
            id
            name
          }
          userProfile {
            firstName
            lastName
            email
            dob
            lastFourOfSsn
          }
        }
      }
    `;
    const result = client.query({
      query: LINKED_EMPLOYEES,
    });
    result.then(
      (response) => {
        resolve(response.data.getEmployerLinkRequests);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getUserProfilesForGroup(client, groupId) {
  return new Promise((resolve, reject) => {
    const GET_USER_PROFILES = gql`
      query getUserProfilesForGroup($groupId: ID!) {
        getUserProfilesForGroup(groupId: $groupId) {
          hasMetadata
          employees {
            id
            firstName
            lastName
            lastFourOfSsn
            state
            directoryInfo {
              location
              department
            }
          }
        }
      }
    `;
    const result = client.query({
      query: GET_USER_PROFILES,
      variables: { groupId },
    });
    result.then(
      (response) => {
        resolve(response.data.getUserProfilesForGroup);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function approvePendingLinkedEmployees(client, linkIds) {
  return new Promise((resolve, reject) => {
    const APPROVE_LINK = gql`
      mutation approveEmployerLinkRequests($requestIds: [ID!]) {
        approveEmployerLinkRequests(requestIds: $requestIds) {
          id
          status
          employeeId
          company {
            id
            name
          }
          group {
            id
            name
          }
          userProfile {
            firstName
            lastName
            email
            dob
            lastFourOfSsn
          }
        }
      }
    `;

    const result = client.mutate({
      mutation: APPROVE_LINK,
      variables: {
        requestIds: linkIds,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.approveEmployerLinkRequests);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function rejectPendingLinkedEmployees(client, linkIds) {
  return new Promise((resolve, reject) => {
    const REJECT_LINK = gql`
      mutation rejectEmployerLinkRequests($requestIds: [ID!]) {
        rejectEmployerLinkRequests(requestIds: $requestIds) {
          id
          status
          employeeId
          company {
            id
            name
          }
          group {
            id
            name
          }
          userProfile {
            firstName
            lastName
            email
            dob
            lastFourOfSsn
          }
        }
      }
    `;

    const result = client.mutate({
      mutation: REJECT_LINK,
      variables: {
        requestIds: linkIds,
      },
    });
    result.then(
      (data) => {
        console.log(data);
        resolve(data.data.rejectEmployerLinkRequests);
      },
      (error) => {
        console.log(error);
        reject(error);
      }
    );
  });
}

function processPayroll(client, payrollInput) {
  return new Promise((resolve, reject) => {
    const PROCESS_PAYROLL = gql`
      mutation processPayroll($payrollInput: PayrollInput!) {
        processPayroll(input: $payrollInput)
      }
    `;

    const result = client.mutate({
      mutation: PROCESS_PAYROLL,
      variables: {
        payrollInput,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.processPayroll);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function approveContributionChanges(client, contributionChangeIds, groupId) {
  return new Promise((resolve, reject) => {
    const APPROVE_CONTRIBUTIONS = gql`
      mutation approveContributionChanges(
        $contributionChangeIds: [ID!]
        $groupId: ID!
      ) {
        approveContributionChanges(
          contributionChangeIds: $contributionChangeIds
          groupId: $groupId
        ) {
          id
          status
        }
      }
    `;

    const result = client.mutate({
      mutation: APPROVE_CONTRIBUTIONS,
      variables: {
        contributionChangeIds,
        groupId,
      },
    });
    result.then(
      (data) => {
        console.log(data);
        resolve(data.data.approveContributionChanges);
      },
      (error) => {
        console.log(error);
        reject(error);
      }
    );
  });
}

function rejectContributionChanges(client, contributionRejections, groupId) {
  return new Promise((resolve, reject) => {
    const REJECT_CONTRIBUTIONS = gql`
      mutation rejectContributionChanges(
        $contributionRejections: [ContributionRejection!]
        $groupId: ID!
      ) {
        rejectContributionChanges(
          contributionRejections: $contributionRejections
          groupId: $groupId
        ) {
          id
          status
        }
      }
    `;

    const result = client.mutate({
      mutation: REJECT_CONTRIBUTIONS,
      variables: {
        contributionRejections,
        groupId,
      },
    });
    result.then(
      (data) => {
        console.log(data);
        resolve(data.data.rejectContributionChanges);
      },
      (error) => {
        console.log(error);
        reject(error);
      }
    );
  });
}

function deleteEmployerLinks(client, linkIds) {
  return new Promise((resolve, reject) => {
    const DELETE_EMPLOYER_LINKS = gql`
      mutation deleteEmployerLinks($linkIds: [ID!]) {
        deleteEmployerLinks(linkIds: $linkIds)
      }
    `;

    const result = client.mutate({
      mutation: DELETE_EMPLOYER_LINKS,
      variables: {
        linkIds,
      },
    });
    result.then(
      (data) => {
        console.log(data);
        resolve(data.data.deleteEmployerLinks);
      },
      (error) => {
        console.log(error);
        reject(error);
      }
    );
  });
}

function getAdministrators(client) {
  return new Promise((resolve, reject) => {
    const GET_AGENTS = gql`
      query getAllAgentLinksForCompany {
        getAllAgentLinksForCompany {
          user {
            id
            email
            username
            userRoleId
          }
          link {
            id
            invitationExpiresAt
            status
            companyId
            locked
            role
          }
        }
      }
    `;
    const result = client.query({
      query: GET_AGENTS,
      variables: {},
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.getAllAgentLinksForCompany);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function resendAdministratorInvite(client, { agentId, companyId }) {
  return new Promise((resolve, reject) => {
    const RESEND_INVITATION = gql`
      mutation resendAgentInvitation($agentId: ID!, $companyId: ID) {
        resendAgentInvitation(agentId: $agentId, companyId: $companyId) {
          link {
            id
            invitationExpiresAt
            status
            locked
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: RESEND_INVITATION,
      variables: { agentId, companyId },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.resendAgentInvitation);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function inviteAdministrator(
  client,
  { email, username, firstName, companyId, role, lastName }
) {
  return new Promise((resolve, reject) => {
    const SEND_INVITATION = gql`
      mutation inviteEmployerAgent(
        $email: String!
        $username: String!
        $firstName: String!
        $lastName: String!
        $role: String
        $companyId: ID
      ) {
        inviteEmployerAgent(
          email: $email
          username: $username
          firstName: $firstName
          lastName: $lastName
          role: $role
          companyId: $companyId
        ) {
          user {
            id
            email
            username
          }
          link {
            id
            invitationExpiresAt
            status
            companyId
            locked
            role
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: SEND_INVITATION,
      variables: { email, username, firstName, role, companyId, lastName },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.inviteEmployerAgent);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function lockAdministrator(client, { agentId, companyId }) {
  return new Promise((resolve, reject) => {
    const LOCK_ADMINISTRATOR = gql`
      mutation lockAgent($agentId: ID!, $companyId: ID) {
        lockAgent(agentId: $agentId, companyId: $companyId) {
          user {
            id
          }
          link {
            id
            status
            locked
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: LOCK_ADMINISTRATOR,
      variables: { agentId, companyId },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.lockAgent);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function unlockAdministrator(client, { agentId, companyId }) {
  return new Promise((resolve, reject) => {
    const UNLOCK_ADMINISTRATOR = gql`
      mutation unlockAgent($agentId: ID!, $companyId: ID) {
        unlockAgent(agentId: $agentId, companyId: $companyId) {
          user {
            id
          }
          link {
            id
            status
            locked
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UNLOCK_ADMINISTRATOR,
      variables: { agentId, companyId },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.unlockAgent);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function uploadEmployeesDocumentRequest(client, groupId, file) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation uploadEmployeeDocument($groupId: ID!, $file: DocumentInput!) {
        uploadEmployeeDocument(groupId: $groupId, file: $file) {
          id
          documentId
          message
          status
          groupId
          filename
          createdAt
          group {
            planType
            name
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        groupId,
        file,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.uploadEmployeeDocument);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getEmployeeDocumentUploadJobsRequest(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getEmployeeDocumentUploadJobs($companyId: ID) {
        getEmployeeDocumentUploadJobs(companyId: $companyId) {
          id
          documentId
          message
          status
          groupId
          filename
          createdAt
          group {
            planType
            name
          }
          errors {
            reason
            type
            format
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId },
    });
    result.then(
      (data) => {
        resolve(data.data.getEmployeeDocumentUploadJobs);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getPaginatedEmployees(client, { limit, offset, search, groupId }) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getPaginatedEmployees(
        $limit: Int!
        $offset: Int!
        $groupId: ID!
        $search: String
      ) {
        getPaginatedEmployees(
          limit: $limit
          offset: $offset
          search: $search
          groupId: $groupId
        ) {
          count
          rows {
            id
            employeeId
            company {
              id
              name
            }
            groupWithSchedule {
              group {
                id
                name
                payrollFrequency
                companyGroupIndex
                useUniqueInviteCodes
                associatedBankAccount {
                  id
                }
              }
            }
            contribution {
              amount
              id
              contributionChangeRequests {
                status
                id
                userCurrentContributionAmount
                userNewContributionAmount
              }
            }
            userProfile {
              firstName
              lastName
              email
              lastFourOfSsn
              dob
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { limit, offset, search, groupId },
    });
    result.then(
      (data) => {
        resolve(data.data.getPaginatedEmployees);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function resendInvitations(client, { invitations, groupIds }) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation resendInvitations($invitationIds: [ID!], $groupIds: [ID!]) {
        resendInvitations(invitationIds: $invitationIds, groupIds: $groupIds) {
          id
          invitationCode
          companyId
          userEmail
          groupId
          expiresAt
          firstName
          status
          createdAt
        }
      }
    `;

    const result = client.mutate({
      mutation: request,
      variables: {
        invitationIds: invitations,
        groupIds,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.resendInvitations);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function setEmployerSession(client, companyId) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation setEmployerSession($companyId: ID!) {
        setEmployerSession(companyId: $companyId)
      }
    `;

    const result = client.mutate({
      mutation: request,
      variables: {
        companyId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.setEmployerSession);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getLinkedCompanies(client) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getLinkedCompanies {
        getLinkedCompanies {
          companies {
            id
            name
          }
          organization {
            id
            name
            companyLimit
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.getLinkedCompanies);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getPaginatedInvites(
  client,
  { limit, offset, search, groupId }
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getPaginatedInvites(
        $limit: Int!
        $offset: Int!
        $search: String
        $groupId: ID
      ) {
        getPaginatedInvites(
          limit: $limit
          offset: $offset
          search: $search
          groupId: $groupId
        ) {
          count
          rows {
            id
            invitationCode
            companyId
            userEmail
            groupId
            expiresAt
            status
            firstName
            createdAt
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: {
        limit,
        offset,
        search,
        groupId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.getPaginatedInvites);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function changeAdministratorRole(client, { agentId, role, companyId }) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation changeRole($agentId: ID!, $role: String!, $companyId: ID) {
        changeRole(agentId: $agentId, role: $role, companyId: $companyId) {
          link {
            id
            invitationExpiresAt
            status
            locked
            role
          }
        }
      }
    `;

    const result = client.mutate({
      mutation: request,
      variables: {
        agentId,
        companyId,
        role,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.changeRole);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getBeneficialOwners(client) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getBeneficialOwners {
        getBeneficialOwners {
          id
          businessMemberHandle
          role
          ownershipStake
          state
          memberProfile {
            legalName
            firstName
            lastName
            alias
            email
            phoneNumber
            ssn
            citizenshipCountry
            birthCountry
            permanentResident
            dob
            address1
            address2
            city
            state
            postalCode
            maritalStatus
            numberOfDependents
            employeeId
            phone
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.getBeneficialOwners);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function validateSelfServe(client) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation validateSelfServe {
        validateSelfServe
      }
    `;

    const result = client.mutate({
      mutation: request,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.validateSelfServe);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getSetupIntent(client) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getSetupIntent {
        getSetupIntent {
          client_secret
          status
          payment_method
          next_action {
            type
            verify_with_microdeposits {
              arrival_date
              hosted_verification_url
              microdeposit_type
            }
          }
          last_setup_error {
            code
            decline_code
            message
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.getSetupIntent);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function createSetupIntent(client) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation createSetupIntent {
        createSetupIntent {
          client_secret
          status
          payment_method
        }
      }
    `;

    const result = client.mutate({
      mutation: REQUEST,
      variables: {},
    });

    result.then(
      (data) => {
        resolve(data.data.createSetupIntent);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function setInvoicePaymentMethod(client, paymentMethod) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation setInvoicePaymentMethod($paymentMethod: String!) {
        setInvoicePaymentMethod(paymentMethod: $paymentMethod)
      }
    `;

    const result = client.mutate({
      mutation: REQUEST,
      variables: { paymentMethod },
    });
    result.then(
      (data) => {
        resolve(data.data.setInvoicePaymentMethod);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getBillingPortalURL(client, returnUrl) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getBillingPortalURL($returnUrl: String!) {
        getBillingPortalURL(returnUrl: $returnUrl)
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { returnUrl },
    });
    result.then(
      (data) => {
        resolve(data.data.getBillingPortalURL);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getProgressiveOnboarding(client) {
  return new Promise((resolve, reject) => {
    const GET_PROGRESSIVE_ONBOARDING = gql`
      query getProgressiveOnboarding {
        getProgressiveOnboarding
      }
    `;
    const result = client.query({
      query: GET_PROGRESSIVE_ONBOARDING,
      variables: {},
    });
    result.then(
      (response) => {
        resolve(response.data.getProgressiveOnboarding);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateProgressiveOnboarding(client, eventName) {
  return new Promise((resolve, reject) => {
    const UPDATE_PROGRESSIVE_ONBOARDING = gql`
      mutation updateProgressiveOnboarding($eventName: String!) {
        updateProgressiveOnboarding(eventName: $eventName)
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_PROGRESSIVE_ONBOARDING,
      variables: {
        eventName,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.updateProgressiveOnboarding);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function uploadRoster(client, { rows, groupId, companyId }) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation uploadRoster(
        $groupId: ID!
        $rows: [RosterRow!]!
        $companyId: ID
      ) {
        uploadRoster(groupId: $groupId, rows: $rows, companyId: $companyId) {
          id
          documentId
          message
          status
          groupId
          filename
          createdAt
          group {
            planType
            name
          }
          errors {
            reason
            type
            format
          }
        }
      }
    `;

    const result = client.mutate({
      mutation: REQUEST,
      variables: { rows, groupId, companyId },
    });
    result.then(
      (data) => {
        resolve(data.data.uploadRoster);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function stageEmployeeInvites(client, { groupId, userEmails }) {
  return new Promise((resolve, reject) => {
    const STAGE_EMPLOYEE_INVITE = gql`
      mutation stageUserInvites($groupId: ID!, $userEmails: [String!]) {
        stageUserInvites(groupId: $groupId, userEmails: $userEmails)
      }
    `;

    const result = client.mutate({
      mutation: STAGE_EMPLOYEE_INVITE,
      variables: {
        groupId,
        userEmails,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.stageUserInvites);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function linkFinch(client, token) {
  return new Promise((resolve, reject) => {
    const MUTATION = gql`
      mutation linkFinch($token: String!) {
        linkFinch(token: $token)
      }
    `;

    const result = client.mutate({
      mutation: MUTATION,
      variables: {
        token,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.linkFinch);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function resyncEmployeeRoster(client) {
  return new Promise((resolve, reject) => {
    const MUTATION = gql`
      mutation resyncEmployeeRoster {
        resyncEmployeeRoster
      }
    `;

    const result = client.mutate({
      mutation: MUTATION,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.resyncEmployeeRoster);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function processRoster(client) {
  return new Promise((resolve, reject) => {
    const MUTATION = gql`
      mutation processRoster {
        processRoster
      }
    `;

    const result = client.mutate({
      mutation: MUTATION,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.processRoster);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function approveRoster(client, rejectedEmployeeIds) {
  return new Promise((resolve, reject) => {
    const MUTATION = gql`
      mutation approveRoster($rejectedEmployeeIds: [ID!]) {
        approveRoster(rejectedEmployeeIds: $rejectedEmployeeIds)
      }
    `;

    const result = client.mutate({
      mutation: MUTATION,
      variables: { rejectedEmployeeIds },
    });
    result.then(
      (data) => {
        resolve(data.data.approveRoster);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getFinchStatus(client) {
  return new Promise((resolve, reject) => {
    const QUERY = gql`
      query getFinchStatus {
        getFinchStatus
      }
    `;

    const result = client.query({
      query: QUERY,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.getFinchStatus);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getEmployeeRoster(client, { offset, limit, filter }) {
  return new Promise((resolve, reject) => {
    const QUERY = gql`
      query getEmployeeRoster($offset: Int!, $limit: Int!, $filter: String) {
        getEmployeeRoster(offset: $offset, limit: $limit, filter: $filter) {
          totalRows
          currentOffset
          limit
          filterValues {
            state
          }
          results {
            email
            phoneNumber
            employeeId
            dob
            firstName
            lastName
            address {
              line1
              line2
              city
              state
              postalCode
              country
            }
            socialSecurityNumber
            status
            linkStatus
          }
        }
      }
    `;

    const result = client.query({
      query: QUERY,
      variables: {
        offset,
        limit,
        filter,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.getEmployeeRoster);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function reconnectFinch(client, token) {
  return new Promise((resolve, reject) => {
    const MUTATION = gql`
      mutation reconnectFinch($token: String!) {
        reconnectFinch(token: $token)
      }
    `;

    const result = client.mutate({
      mutation: MUTATION,
      variables: {
        token,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.reconnectFinch);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function referCompany(client, email) {
  return new Promise((resolve, reject) => {
    const MUTATION = gql`
      mutation referCompany($email: String!) {
        referCompany(email: $email)
      }
    `;

    const result = client.mutate({
      mutation: MUTATION,
      variables: {
        email,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.referCompany);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getPayrollGroupContributionAbilityRequest(client, groupId) {
  return new Promise((resolve, reject) => {
    const request = gql`
      query getPayrollGroupContributionAbility($groupId: ID!) {
        getEmployerGroup(groupId: $groupId) {
          id
          contributionAbility {
            payrollPriorYearCutoffDate
            priorYearCutoffDate
            isWithinPayrollPriorYearContributionWindow
            isWithinPriorYearContributionWindow
            currentYear {
              taxYear
              amountRemaining
              canProcessPayroll
            }
            priorYear {
              taxYear
              amountRemaining
              canProcessPayroll
            }
            allYears {
              taxYear
              amountRemaining
              canProcessPayroll
            }

            userContributionAbilities {
              userId
              currentYear {
                taxYear
                totalContributionAmount
                amountRemaining
                limit
                canContribute
              }
              priorYear {
                taxYear
                totalContributionAmount
                amountRemaining
                limit
                canContribute
              }
              isWithinPriorYearContributionWindow
              allYears {
                taxYear
                totalContributionAmount
                amountRemaining
                limit
                canContribute
              }
            }
          }
        }
      }
    `;
    const result = client.query({
      query: request,
      variables: {
        groupId,
      },
    });
    result.then(
      (data) => {
        resolve(get(data, "data.getEmployerGroup.contributionAbility", {}));
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function currentPayrolls(client, companyId) {
  return new Promise((resolve, reject) => {
    const CURRENT_PAYROLLS = gql`
      query currentPayrolls($companyId: ID) {
        currentPayrolls(companyId: $companyId) {
          payrollId
          start
          end
          payrollProcessingDate
          onOrAfterPayrollProcessingDate
          lastPayrollApprovalDate
          status
          gracePeriodEndDate
          payPeriodStart
          payPeriodEnd
          periodState
          payPeriodId
          groupId
          userCount
          payrollType
          amount
        }
      }
    `;
    const result = client.query({
      query: CURRENT_PAYROLLS,
      variables: {
        companyId,
      },
      fetchPolicy: "network-only",
    });
    result.then(
      (data) => {
        resolve(data.data.currentPayrolls);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function hydrateDashboard(client) {
  return new Promise((resolve, reject) => {
    const DASHBOARD_HYDRATE = gql`
      query hydrateDashboard {
        employerAccountList {
          accountId
          id
          accountType
          accountSubtype
          userId
          bankName
          bankAlias
          linkStatus
          state
        }
        getEmployerGroups {
          id
          name
          payrollFrequency
          useUniqueInviteCodes
          associatedBankAccount {
            id
            bankName
          }
          associatedLinkRequests {
            status
            id
          }
          schedule {
            anchorPayDate
            displayAnchorPayDate
            displayDayOne
            displayDayTwo
          }
          planType
          companyGroupIndex
        }
        getAllEmployeeInvitesForCompany {
          id
          invitationCode
          companyId
          userEmail
          groupId
          expiresAt
          status
        }
        getEmployerLinkRequests {
          id
          status
          employeeId
          company {
            id
            name
          }
          group {
            useUniqueInviteCodes
            id
            name
          }
          userProfile {
            firstName
            lastName
            email
            dob
            lastFourOfSsn
          }
        }
        getTotalAmountSaved {
          totalAmount
        }
        getLinkedEmployees {
          id
          employeeId
          company {
            id
            name
          }
          groupWithSchedule {
            group {
              id
              name
              payrollFrequency
              companyGroupIndex
              useUniqueInviteCodes
              associatedBankAccount {
                id
              }
            }
          }
          contribution {
            amount
            id
          }
          userProfile {
            firstName
            lastName
            email
            lastFourOfSsn
            dob
          }
        }
      }
    `;

    const result = client.query({
      query: DASHBOARD_HYDRATE,
      variables: {},
      fetchPolicy: "network-only",
    });
    result.then(
      (data) => {
        resolve(data.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function skipBilling(client) {
  return new Promise((resolve, reject) => {
    const SKIP_BILLING = gql`
      mutation skipBilling {
        skipBilling
      }
    `;
    const result = client.mutate({
      mutation: SKIP_BILLING,
      variables: {},
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getEmployerRequiredStepsRequest(client) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getEmployerRequiredUpdates {
        getEmployerRequiredUpdates {
          isCompleted
          allSteps
          pendingSteps
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: {},
    });
    result.then(
      (response) => {
        if (response.data.getEmployerRequiredUpdates) {
          resolve(response.data.getEmployerRequiredUpdates);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateRequiredOrderFormsRequest(client, completedOrderForm) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation updateRequiredOrderForm(
        $completedOrderForm: EmployerRequiredStep!
      ) {
        updateRequiredOrderForm(completedOrderForm: $completedOrderForm) {
          isCompleted
          allSteps
          pendingSteps
        }
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        completedOrderForm,
      },
    });
    result.then(
      (response) => {
        if (response.data.updateRequiredOrderForm) {
          resolve(response.data.updateRequiredOrderForm);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getPayrollIntegrationStatus(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
        query getPayrollIntegrationStatus($companyId: ID!)
        getPayrollIntegrationStatus($companyId: companyId) {
        }
    `;
    const result = client.query({
      mutation: REQUEST,
      variables: { companyId },
    });
    result.then(
      (response) => {
        if (response.data.getPayrollIntegrationStatus) {
          resolve(response.data.getPayrollIntegrationStatus);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function companyTermsDate(client) {
  return new Promise((resolve, reject) => {
    const QUERY = gql`
      query companyTermsDate {
        companyTermsDate {
          createdAt
        }
      }
    `;
    const result = client.query({
      query: QUERY,
      variables: {},
    });
    result.then(
      (response) => {
        if (response.data.companyTermsDate) {
          resolve(response.data.companyTermsDate);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function getSalesQuote(client, quoteCode) {
  return new Promise((resolve, reject) => {
    const QUERY = gql`
      query getSalesQuote($quoteCode: String!) {
        getSalesQuote(quoteCode: $quoteCode) {
          billingFrequency
          contractLength
          subscriptionFee
          setupFee
          discountPercent
          discountLength
          isExpired
          salesQuote {
            companySize
          }
        }
      }
    `;
    const result = client.query({
      query: QUERY,
      variables: {
        quoteCode,
      },
    });
    result.then(
      (response) => {
        if (response.data.getSalesQuote) {
          resolve(response.data.getSalesQuote);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

export const employerService = {
  register,
  submitEmployerAgents,
  getCompany,
  findEmployerByUser,
  approvePendingLinkedEmployees,
  getAllEmployerAccounts,
  rejectPendingLinkedEmployees,
  getAllEmployerGroups,
  addEmployerGroup,
  getPendingLinkedEmployees,
  updateEmployerGroup,
  deleteEmployerGroup,
  inviteEmployees,
  resendInvitation,
  getEmployeeInvites,
  getTakeRateEmployeesForCompany,
  getLinkedEmployees,
  processPayroll,
  getUserProfilesForGroup,
  approveContributionChanges,
  rejectContributionChanges,
  deleteEmployerLinks,
  getAdministrators,
  resendAdministratorInvite,
  inviteAdministrator,
  lockAdministrator,
  revokeInvitation,
  unlockAdministrator,
  uploadEmployeesDocumentRequest,
  getEmployeeDocumentUploadJobsRequest,
  resendInvitations,
  getEmployerGroup,
  getPaginatedEmployees,
  setEmployerSession,
  getLinkedCompanies,
  getPaginatedInvites,
  changeAdministratorRole,
  getBeneficialOwners,
  validateSelfServe,
  getSetupIntent,
  createSetupIntent,
  setInvoicePaymentMethod,
  getBillingPortalURL,
  getProgressiveOnboarding,
  updateProgressiveOnboarding,
  uploadRoster,
  stageEmployeeInvites,
  linkFinch,
  getFinchStatus,
  getEmployeeRoster,
  resyncEmployeeRoster,
  processRoster,
  approveRoster,
  reconnectFinch,
  referCompany,
  getPayrollGroupContributionAbilityRequest,
  currentPayrolls,
  skipBilling,
  hydrateDashboard,
  getEmployerRequiredStepsRequest,
  updateRequiredOrderFormsRequest,
  getPayrollIntegrationStatus,
  companyTermsDate,
  getSalesQuote,
};
