import React from "react";
import PropTypes from "prop-types";
import {
  confirmEmail,
  getUserState,
  submitEin,
  submitSsn,
} from "actions/userActions";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Auth0Context } from "utils/react-auth0-wrapper";
import { Formik } from "formik";
import { push } from "connected-react-router";
import { Col, Form } from "react-bootstrap";
import { userType } from "statics/propTypes";
import { get, isEmpty } from "lodash";
import classnames from "classnames";

import "./IndividualAuthentication.scss";

import Button from "components/Button";
import InputMask from "react-input-mask";
import Alert from "components/Alert";

import { testSsn } from "utils/fieldValidators";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { userConstants } from "actions/types";
import {
  IndividualEmailVerificationComplete,
  IndividualLoginCreated,
  IndividualSubmitSsnPending,
} from "statics/states";
import { ScrollToFieldError } from "utils/form";
import LinkSecondaryAuth from "../LinkSecondaryAuth";
import {
  userHasMoreThanOneAuth,
  userIs401kRollIn,
} from "../../../store/selectors/user";

/* eslint-disable react/no-children-prop */

let yup = require("yup");

class IndividualAuthentication extends React.Component {
  static propTypes = {
    user: userType,
    getUserState: PropTypes.func,
    userState: PropTypes.string,
    confirmEmail: PropTypes.func,
    submitSsn: PropTypes.func,
    submitEin: PropTypes.func,
    push: PropTypes.func,
    client: PropTypes.object,
    confirmEmailSuccess: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
    onSubmit: PropTypes.func,
    isFetching: PropTypes.bool,
    requireEin: PropTypes.bool,
    isKelly: PropTypes.bool,
    shouldRequireSecondaryAuth: PropTypes.bool,
    hasSecondaryAuth: PropTypes.bool,
    error: PropTypes.string,
    companyId: PropTypes.string,
  };
  static contextType = Auth0Context;
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      shouldSubmitIdentity: true,
      shouldVerifyEmail: true,
      ssn: "",
    };
  }

  componentDidMount = async () => {
    this.props.getUserState(this.props.client);
    this.setState({
      shouldVerifyEmail:
        this.props.userState === IndividualLoginCreated ||
        this.props.isEmailVerified,
      shouldSubmitIdentity:
        this.props.userState === IndividualLoginCreated ||
        this.props.userState === IndividualSubmitSsnPending ||
        this.props.userState === IndividualEmailVerificationComplete,
    });
  };

  submitVerification = async ({ ssn, ein }) => {
    if (!this.props.requireEin) {
      this.props.submitSsn(this.props.client, ssn).then(() => {
        this.props.getUserState(this.props.client);
        this.setState({
          shouldSubmitIdentity: false,
        });
      });
    } else {
      this.props
        .submitEin(this.props.client, { ein, companyId: this.props.companyId })
        .then(() => {
          this.props.getUserState(this.props.client);
          this.setState({
            shouldSubmitIdentity: false,
          });
        });
    }
  };

  verificationCheckMark = () => {
    return (
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    );
  };

  buildPageTitle = () => {
    if (!this.props.isEmailVerified && this.state.shouldSubmitIdentity) {
      return "Account verification ";
    } else if (this.props.isEmailVerified && this.state.shouldSubmitIdentity) {
      return "Account verification ";
    } else if (this.props.isEmailVerified && !this.state.shouldSubmitIdentity) {
      return "Success!";
    }
  };

  buildPageSubtextText = () => {
    if (!this.props.isEmailVerified && this.state.shouldSubmitIdentity) {
      return "We need you to verify the following: ";
    } else if (this.props.isEmailVerified && this.state.shouldSubmitIdentity) {
      return "We need you to verify the following: ";
    }
  };

  emailTitle = () => {
    if (!this.props.isEmailVerified) {
      return "Your email";
    } else if (this.props.isEmailVerified) {
      return "Email verification complete";
    }
  };

  secondaryAuthTitle = () => {
    if (this.props.hasSecondaryAuth) {
      return "Alternative Authentication Method Linked";
    } else {
      return "Link Alternative Authentication Method";
    }
  };

  emailSubtext = () => {
    if (!this.props.isEmailVerified) {
      return "We sent you an email with a confirmation link. Please click the link in that email and then return to this screen.";
    } else if (this.props.isEmailVerified) {
      return "Your email was successfully verified and may now be used to login as well as receive communications from Icon.";
    }
  };

  secondaryAuthSubtext = () => {
    if (this.props.hasSecondaryAuth) {
      return "You have successfully established an alternative authentication method.";
    } else {
      return "In order to ensure ongoing access to your Icon Account, you will need to setup an alternative authentication method.";
    }
  };

  render() {
    const identityKey = this.props.requireEin ? "confirmEin" : "ssn";
    const isKelly = this.props.isKelly;
    const einLabel = isKelly ? "ALT ID" : "Employee Identification Number";
    /*
     * TODO this should require secondary auth will be based on set paramaters, once we know what those are
     * */
    const shouldRequireSecondaryAuth = this.props.shouldRequireSecondaryAuth;
    const disable =
      !this.props.isEmailVerified ||
      (shouldRequireSecondaryAuth && !this.props.hasSecondaryAuth);

    const schema = yup.object({
      ssn: yup
        .string()
        .when("requireEin", {
          is: false,
          then: yup
            .string()
            .required()
            .test("ssn", "${path} must be of format 111-11-1111", testSsn),
          otherwise: yup.string(),
        })
        .label("Social Security Number"),
      ein: yup
        .string()
        .when("requireEin", {
          is: true,
          then: yup.string().required(),
          otherwise: yup.string(),
        })
        .max(32)
        .min(1)
        .label(einLabel),
      confirmEin: yup
        .string()
        .when("requireEin", {
          is: true,
          then: yup
            .string()
            .required()
            .test("eins-match", `${einLabel} must match`, function (value) {
              return this.parent.ein === value;
            }),
          otherwise: yup.string(),
        })
        .max(32)
        .min(1)
        .label(`Confirm ${einLabel}`),
    });

    let showEmailVerifyStatus = shouldRequireSecondaryAuth
      ? this.props.hasSecondaryAuth
      : true;

    return (
      <div className="individual-authentication">
        <section>
          <article className="text-cell">
            <p className="page-eyebrow">VERIFY EMAIL</p>
            <p className="page-title">{this.buildPageTitle()}</p>
            <p className="page-subtext">{this.buildPageSubtextText()}</p>
          </article>
        </section>
        {shouldRequireSecondaryAuth && (
          <div className="authentication-col">
            <div className="email-col">
              <div className="circle-wrapper">
                <div
                  className={classnames("progression-circle", {
                    ["is-active"]:
                      shouldRequireSecondaryAuth &&
                      !this.props.hasSecondaryAuth,
                    ["is-verified"]: this.props.hasSecondaryAuth,
                  })}
                >
                  A
                </div>
              </div>
              <div className="email-authentication">
                <p className="email-title">
                  {this.secondaryAuthTitle()}{" "}
                  {this.props.hasSecondaryAuth && (
                    <span>{this.verificationCheckMark()}</span>
                  )}
                </p>
                <p className="email-subtext">{this.secondaryAuthSubtext()}</p>
                {!this.props.hasSecondaryAuth && <LinkSecondaryAuth />}
              </div>
            </div>
          </div>
        )}

        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={this.submitVerification}
          initialValues={{
            ssn: "",
            ein: "",
            requireEin: this.props.requireEin,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />

              <div className="authentication-col">
                <div className="email-col">
                  <div className="circle-wrapper">
                    <div
                      className={classnames("progression-circle", {
                        ["is-active"]:
                          (this.props.userState === IndividualLoginCreated ||
                            !this.props.isEmailVerified) &&
                          (!shouldRequireSecondaryAuth ||
                            this.props.hasSecondaryAuth),
                        ["is-verified"]:
                          this.props.isEmailVerified && showEmailVerifyStatus,
                      })}
                    >
                      {shouldRequireSecondaryAuth ? "B" : "A"}
                    </div>
                  </div>
                  <div className="email-authentication">
                    <p className="email-title">
                      {this.emailTitle()}{" "}
                      {this.props.isEmailVerified && showEmailVerifyStatus && (
                        <span>{this.verificationCheckMark()}</span>
                      )}
                    </p>
                    <p className="email-subtext">{this.emailSubtext()}</p>
                  </div>
                </div>

                {!this.props.requireEin && (
                  <div className="ssn-col">
                    <div className="circle-wrapper">
                      <div
                        className={classnames("progression-circle", {
                          ["is-active"]: this.props.isEmailVerified,
                          ["is-verified"]: !errors.ssn && !isEmpty(values.ssn),
                        })}
                      >
                        {shouldRequireSecondaryAuth ? "C" : "B"}
                      </div>
                    </div>

                    <div className="ssn-authentication">
                      <p className="ssn-title">
                        Your identity{" "}
                        {!errors.ssn && !isEmpty(values.ssn) && (
                          <span>{this.verificationCheckMark()}</span>
                        )}
                      </p>
                      <p className="ssn-subtext">
                        We are required by law to verify your social security
                        number. We use bank level security to protect your
                        identity.{" "}
                      </p>
                      <p
                        className={classnames("ssn-label", {
                          ["ssn-not-verified"]: !this.props.isEmailVerified,
                        })}
                      >
                        Social security number{" "}
                      </p>{" "}
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md={4}
                          sm={12}
                          controlId="formBasicSsnField"
                        >
                          <div className="enter-ssn">
                            <div className="password">
                              <InputMask
                                disabled={disable}
                                name="ssn"
                                mask="999-99-9999"
                                placeholder="000-00-0000"
                                value={values.ssn}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="password__input"
                                isInvalid={touched.ssn && !!errors.ssn}
                                isValid={touched.ssn && !errors.ssn}
                                children={(props) => (
                                  <Form.Control disabled={disable} {...props} />
                                )}
                              ></InputMask>
                            </div>
                          </div>
                        </Form.Group>
                      </Form.Row>
                    </div>
                  </div>
                )}
                {this.props.requireEin && (
                  <div className="ssn-col">
                    <div className="circle-wrapper">
                      <div
                        className={classnames("progression-circle", {
                          ["is-active"]: this.props.isEmailVerified,
                          ["is-verified"]: !errors.ein && !isEmpty(values.ein),
                        })}
                      >
                        B
                      </div>
                    </div>

                    <div className="ssn-authentication">
                      <p className="ssn-title">
                        {!isKelly && "Your employee identifier "}
                        {isKelly && "Your ALT ID "}
                        {!errors.ssn && !isEmpty(values.ssn) && (
                          <span>{this.verificationCheckMark()}</span>
                        )}
                      </p>
                      <p className="ssn-subtext">
                        {!isKelly &&
                          "We use bank level security to protect your identity."}
                        {isKelly &&
                          "Your ALT ID is a unique identifying ID number, located in the box to the right of your name and address on your paystub or on your ePaystub."}
                      </p>
                      <p
                        className={classnames("ssn-label", {
                          ["ssn-not-verified"]: !this.props.isEmailVerified,
                        })}
                      >
                        {!isKelly && "Employee identification number"}
                        {isKelly && "ALT ID"}
                      </p>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md={4}
                          sm={12}
                          controlId="formBasicEinField"
                        >
                          <div className="enter-ssn">
                            <div className="password">
                              <Form.Control
                                name="ein"
                                placeholder={isKelly ? "ALT ID" : "EIN"}
                                value={values.ein}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={!this.props.isEmailVerified}
                                isInvalid={touched.ein && !!errors.ein}
                                isValid={touched.ein && !errors.ein}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.ein}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md={4}
                          sm={12}
                          controlId="formBasicEinConfirmField"
                        >
                          <div className="enter-ssn">
                            <div className="password">
                              <Form.Control
                                name="confirmEin"
                                placeholder={
                                  isKelly ? "Confirm ALT ID" : "Confirm EIN"
                                }
                                disabled={!this.props.isEmailVerified}
                                value={values.confirmEin}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={
                                  touched.confirmEin && !!errors.confirmEin
                                }
                                isValid={
                                  touched.confirmEin && !errors.confirmEin
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.confirmEin}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                        </Form.Group>
                      </Form.Row>
                    </div>
                  </div>
                )}
              </div>
              <section className="form-sec-2col">
                <article className="col-form">
                  <div className="submit-row">
                    {this.props.error && (
                      <Alert type="error" msg={this.props.error} />
                    )}

                    <Button
                      disabled={
                        disable ||
                        !!errors[identityKey] ||
                        isEmpty(values[identityKey])
                      }
                      withArrow={true}
                      name="submit"
                      loading={this.props.isFetching}
                    />
                  </div>
                </article>
              </section>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const actions = [userConstants.SUBMIT_SSN, userConstants.SUBMIT_EIN];

const loadingSelector = createLoadingSelector(actions);

const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state, ownProps) => {
  const { user } = state;
  const confirmEmailSuccess = state.user.confirmEmailSuccess;
  const userEmailSuccess = state.user.emailConfirmed;
  return {
    user,
    userState: get(state.user, "userState.state", ""),
    confirmEmailSuccess,
    userEmailSuccess,
    ownProps,
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    hasSecondaryAuth: userHasMoreThanOneAuth(state),
    shouldRequireSecondaryAuth: userIs401kRollIn(state),
  };
};

const mapDispatchToProps = {
  confirmEmail,
  getUserState,
  submitSsn,
  submitEin,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualAuthentication));
