import React from "react";
import PropTypes from "prop-types";
import { get, includes } from "lodash";
import ProgressBar from "components/ProgressBar";
import CircularProgressBar from "components/CircularProgressBar";
import {
  confirmEmail,
  getEmployerLinkRequests,
  getUserState,
} from "actions/userActions";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Auth0Context } from "utils/react-auth0-wrapper";
import { push } from "connected-react-router";
import env from "@beam-australia/react-env";
import Button from "components/Button";
import queryString from "query-string";
import IndividualAuthentication from "pages/signUp/individual/IndividualAuthentication";
import IconSpinner from "components/IconSpinner";
import PromisePoller from "components/PromisePoller";

import { userType } from "statics/propTypes";
import { createLoadingSelector } from "store/selectors";
import { userConstants } from "actions/types";
import { einVerificationId } from "statics/featureIds";
import {
  auth0PlanCodeSelector,
  emailConfirmedSelector,
} from "store/selectors/auth0";
import { EmployerApplicationApproved } from "statics/states";
import {
  isEmployerAdminSelector,
  isEmployerAgentSelector,
} from "store/selectors/user";
import { COMPANY_INFO, VERIFY_EMAIL } from "statics/onboardingSteps";

class VerifyEmail extends React.Component {
  static propTypes = {
    user: userType,
    getUserState: PropTypes.func,
    confirmEmail: PropTypes.func,
    getEmployerLinkRequests: PropTypes.func,
    push: PropTypes.func,
    client: PropTypes.object,
    confirmEmailSuccess: PropTypes.bool,
    requireEin: PropTypes.bool,
    isFetching: PropTypes.bool,
    isKelly: PropTypes.bool,
    companyId: PropTypes.string,
    planCode: PropTypes.string,

    companyState: PropTypes.shape({
      state: PropTypes.string,
    }),
    isEmployerAdmin: PropTypes.bool,
    isEmployerAgent: PropTypes.bool,
    initialPlanCode: PropTypes.string,
  };
  static contextType = Auth0Context;
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      complete: false,
      verified: emailConfirmedSelector(this.props),
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    this.props.getEmployerLinkRequests(this.props.client);

    window.analytics.page("Individual Email Verification");
  }

  async componentDidUpdate() {
    var self = this;
    if (
      this.state.verified &&
      this.props.confirmEmailSuccess &&
      !this.state.complete
    ) {
      const { getUser } = self.context;

      if (this._isMounted) {
        this.setState({
          complete: true,
        });
      }

      const user = await getUser();

      this.props.getUserState(this.props.client, user);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  verify = async () => {
    const self = this;
    try {
      const { getTokenSilently, getUser } = self.context;
      await getTokenSilently({
        cacheMode: "off",

        authorizationParams: {
          audience: `https://${env("AUTH0_AUDIENCE")}`,
        },
      });

      const user = await getUser();
      if (user.email_verified) {
        // Call our own verifyEmail function to our backend that will check Auth0 via management API
        await this.props.confirmEmail(this.props.client);

        // If it also says verified, then app_metadata will be updated via a Redux Action
        // Local verified state is true, and when app_metatdata also becomes true, user can advance
        if (self._isMounted) {
          self.setState({
            verified: true,
          });
        }

        return user.email_verified;
      }
      return user.email_verified;
    } catch (e) {
      console.log("Email Verify Error: " + e);
    }
  };

  render() {
    const isEmailVerified =
      this.state.verified &&
      (this.props.confirmEmailSuccess ||
        this.props.user.auth0User.email_verified);

    const poller = isEmailVerified ? null : (
      <PromisePoller
        interval={4000}
        minutesToPollFor={10}
        promiseToPoll={this.verify}
      />
    );

    if (this.hasIndividualRole()) {
      return (
        <div>
          {this.buildProgressBar(true)}
          {this.props.isFetching && <IconSpinner centered />}
          {!this.props.isFetching && (
            <div className="mega-container">
              {poller}
              <div
                className="step-container is-active"
                data-circle-percent={this.getVerifiedProgressBarPercentage(
                  isEmailVerified
                )}
              >
                <section className="page-title-wrap">
                  <article className="progress-cell">
                    <ul className="circular-progress-wrap">
                      <CircularProgressBar
                        strokeWidth="8"
                        sqSize="75"
                        percentage={this.getVerifiedProgressBarPercentage(
                          isEmailVerified
                        )}
                      />
                    </ul>
                  </article>
                </section>
                <div id="form-employer-company-profile">
                  <section className="form-sec-2col">
                    <IndividualAuthentication
                      isEmailVerified={isEmailVerified}
                      requireEin={this.props.requireEin}
                      onSubmit={this.onContinueClick}
                      isKelly={this.props.isKelly}
                      companyId={this.props.companyId}
                    />
                  </section>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
    if (isEmailVerified) {
      return this.renderVerified();
    }
    return this.renderChecking();
  }

  hasIndividualRole = () => {
    return (
      this.props.user.userRoleId !== undefined &&
      this.props.user.userRoleId.toString() === "3"
    );
  };

  isNewAdmin = () => {
    const companyState = get(this.props.companyState, "state", "");

    const isAgent = this.props.isEmployerAgent;
    const isAdmin = this.props.isEmployerAdmin;

    const companyActive = companyState === EmployerApplicationApproved;
    const isOnboardingSecondaryAdmin = isAdmin && companyActive;

    return isAgent || isOnboardingSecondaryAdmin;
  };

  buildProgressBar = (isVerified) => {
    if (this.hasIndividualRole()) {
      return (
        <ProgressBar
          isEmployer={false}
          activeStepId={VERIFY_EMAIL.id}
          progressPercent={"15"}
        />
      );
    } else if (this.isNewAdmin()) {
      return (
        <ProgressBar
          isAdministrator={true}
          activeStepId={VERIFY_EMAIL.id}
          showBillingRoute={!!this.props.initialPlanCode}
          progressPercent={this.getVerifiedProgressBarPercentage(true)}
        />
      );
    } else {
      return (
        <ProgressBar
          isEmployer={true}
          showBillingRoute={!!this.props.initialPlanCode}
          activeStepId={isVerified ? COMPANY_INFO.id : VERIFY_EMAIL.id}
          progressPercent={"50"}
        />
      );
    }
  };

  buildVerifiedText = () => {
    if (this.hasIndividualRole()) {
      return "Success! Please continue and begin your enrollment.";
    } else if (this.isNewAdmin()) {
      return "Success! Please continue to the company dashboard.";
    } else {
      return "Success! Please continue and add your company information.";
    }
  };

  onContinueClick = () => {
    const invitationCode = queryString.parse(
      window.location.search
    ).invitationCode;
    if (this.hasIndividualRole()) {
      this.props.push(
        `/individual/info${
          invitationCode ? `/?invitationCode=${invitationCode}` : ""
        }`
      );
    } else if (this.isNewAdmin()) {
      this.props.push("/dashboard");
    } else {
      this.props.push("/employer/enroll");
    }
  };

  getVerifiedProgressBarPercentage = (isVerified) => {
    if (this.hasIndividualRole()) {
      return "25";
    } else if (this.isNewAdmin() && isVerified) {
      return "100";
    } else {
      return "50";
    }
  };

  renderVerified = () => {
    var articleStyle = {
      paddingBottom: 0,
    };

    return (
      <div>
        {this.buildProgressBar(true)}
        <div className="mega-container">
          <div
            className="step-container is-active"
            data-circle-percent={this.getVerifiedProgressBarPercentage(true)}
          >
            <section className="page-title-wrap">
              <article className="text-cell">
                <h1 className="page-title">Email Verified</h1>
                <p className="page-subtext">{this.buildVerifiedText()}</p>
              </article>
              <article className="progress-cell">
                <ul className="circular-progress-wrap">
                  <CircularProgressBar
                    strokeWidth="8"
                    sqSize="75"
                    percentage={this.getVerifiedProgressBarPercentage(true)}
                  />
                </ul>
              </article>
            </section>
            <div id="form-employer-company-profile">
              <section className="form-sec-2col">
                <article className="col-form" style={articleStyle}></article>
              </section>
              <section className="form-sec-2col">
                <article className="col-form">
                  <div className="submit-row">
                    <Button
                      name="submit"
                      withArrow={true}
                      onClick={() => this.onContinueClick()}
                    />
                  </div>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderChecking = () => {
    var articleStyle = {
      paddingBottom: 0,
    };

    return (
      <div>
        {this.buildProgressBar()}
        <div className="mega-container">
          <PromisePoller
            interval={4000}
            minutesToPollFor={10}
            promiseToPoll={this.verify}
          />
          <div className="step-container is-active" data-circle-percent={"50"}>
            <section className="page-title-wrap">
              <article className="text-cell">
                <h1 className="page-title">Verify Your Email</h1>
                <p className="page-subtext">
                  We sent you an email with a confirmation link. Please click
                  the link in that email and then return here.
                </p>
              </article>
              <article className="progress-cell">
                <ul className="circular-progress-wrap">
                  <CircularProgressBar
                    strokeWidth="8"
                    sqSize="75"
                    percentage={this.getVerifiedProgressBarPercentage()}
                  />
                </ul>
              </article>
            </section>
            <div id="form-employer-company-profile">
              <section className="form-sec-2col">
                <article className="col-form" style={articleStyle}></article>
              </section>
              <section className="form-sec-2col">
                <article className="col-form">
                  <div className="submit-row"></div>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const isFetchingSelector = createLoadingSelector(
  userConstants.USER_EMPLOYER_LINK_REQUESTS
);

const mapStateToProps = (state, ownProps) => {
  const { user } = state;
  const confirmEmailSuccess = state.user.confirmEmailSuccess;
  const userEmailSuccess = state.user.emailConfirmed;
  const requireEin = includes(
    get(
      state.user.employerLinkRequests,
      "0.company.enabledProductFeatures",
      []
    ),
    einVerificationId
  );

  const isKelly = get(state.user.employerLinkRequests, "0.isKelly", false);
  const companyId = get(state.user.employerLinkRequests, "0.company.id", false);

  return {
    user,
    confirmEmailSuccess,
    userEmailSuccess,
    ownProps,
    isFetching: isFetchingSelector(state),
    requireEin,
    isKelly,
    companyId,
    companyState: state.user.companyState,
    isEmployerAdmin: isEmployerAdminSelector(state),
    isEmployerAgent: isEmployerAgentSelector(state),
    initialPlanCode: auth0PlanCodeSelector(state),
  };
};

const mapDispatchToProps = {
  confirmEmail,
  getUserState,
  getEmployerLinkRequests,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(VerifyEmail));
