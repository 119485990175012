import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Form, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import * as yup from "yup";
import { acceptInvitation } from "actions/userActions";
import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { userConstants } from "actions/types";
import {
  invitationCodeParser,
  noWhitespaceRegex,
  validIconInvite,
  whiteSpaceError,
} from "../../../../utils/fieldValidators";

import Button from "components/Button";
import Alert from "components/Alert";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

const schema = yup.object({
  invitationCode: yup
    .string()
    .label("Invitation code")
    .required()
    .min(7)
    .max(10)
    .matches(noWhitespaceRegex, whiteSpaceError)
    .matches(validIconInvite),
});

class AcceptInvite extends React.PureComponent {
  static propTypes = {
    acceptInvitation: PropTypes.func,
    setEmployerLink: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      invitationCode: "",
    };
  }

  _linkEmployer = (values) => {
    const invitationCode = values.invitationCode;
    this.props
      .acceptInvitation(this.props.client, invitationCode)
      .then((res) => {
        if (!this.props.error) {
          this.props.setEmployerLink(res);
        }
      });
  };

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <IconHeader variant="cardHeader" headerText="Employer Plan" />
          <IconSubheader
            variant="subheading"
            subheader="In order to link with your employer please provide your Plan ID or invitation code you should have received in an email from your employer."
          />
        </Modal.Header>
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={(values) => {
            this._linkEmployer(values);
          }}
          initialValues={{
            invitationCode: this.state.invitationCode,
          }}
        >
          {({
            setFieldValue,
            handleSubmit,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId="formBasicInvitationCode"
                  >
                    <Form.Label>Employer Plan ID</Form.Label>
                    <Form.Control
                      name="invitationCode"
                      placeholder="Plan ID"
                      value={values.invitationCode}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        const value = invitationCodeParser(
                          event,
                          values.invitationCode
                        );
                        setFieldValue("invitationCode", value);
                      }}
                      isInvalid={
                        touched.invitationCode && !!errors.invitationCode
                      }
                      isValid={touched.invitationCode && !errors.invitationCode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.invitationCode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {this.props.error && (
                  <Alert type="error" msg={this.props.error} />
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  name="submit"
                  withArrow={true}
                  btnLabel="Continue"
                  disabled={!values.invitationCode}
                  loading={this.props.isFetching}
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const actions = [
  userConstants.USER_EMPLOYER_LINK,
  userConstants.USER_ACCEPT_INVITATION,
];

const isFetchingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    isFetching: isFetchingSelector(state),
    error: errorSelector(state),
    linkedEmployers: state.user.linkedEmployers,
  };
};

const mapDispatchToProps = {
  acceptInvitation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AcceptInvite));
