import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";
import { isEmpty, get } from "lodash";
import { Row, Col, Card } from "react-bootstrap";
import { FiAlertTriangle } from "react-icons/fi";
import {
  expiringIndividualBankSelector,
  bankSelector,
  isBankVerifiedSelector,
} from "store/selectors/bank";
import { bankAccountType } from "statics/propTypes";
import { payrollPeriodsToEnglishMapping } from "utils/timeHelper";
import { getTransactions } from "actions/transactionActions";
import { transactionType } from "statics/propTypes";
import { transactionConstants, bankConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getIraAccountIdSelector } from "store/selectors/user";
import { shouldReAuthBank } from "utils/reAuthDateValidation";
import { deleteBank } from "actions/bankActions";

import IconCircle from "components/IconCircle";
import IconHeader from "components/IconHeader";
import Alert from "components/Alert";
import PlaidLinkUpdateCard from "../../../../components/PlaidLinkUpdateCard";
import ReAuthPlaidLink from "components/ReAuthPlaidLink";
import ContributionSource from "./ContributionSource";
import AddBankCard from "pages/dashboards/AddBankCard";
import ContributionModal from "pages/dashboards/individualDashboard/dashboard/ContributionModal";
import OneTimeContributionCard from "./OneTimeContributionCard";
import Button from "components/Button";

class Banks extends React.PureComponent {
  static propTypes = {
    deleteBank: PropTypes.func,
    bank: bankAccountType,
    error: PropTypes.string,
    bankIsVerified: PropTypes.bool,
    getTransactions: PropTypes.func,
    transactions: PropTypes.arrayOf(transactionType),
    iraAccountId: PropTypes.string,
    client: PropTypes.object,
    isLoading: PropTypes.bool,
    individualReAuthRequired: PropTypes.bool,
    expiringIndividualBanks: PropTypes.array,
    onSuccess: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      showOneTimeContributionModal: false,
      initialFetching: true,
      isDeleting: false,
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.getTransactions(this.props.client, this.props.iraAccountId),
    ]).then(() => this.setState({ initialFetching: false }));
  }

  _removeBankLink = () => {
    const bankId = this.props.bank.id;
    this.props.deleteBank(this.props.client, bankId).then(() => {
      if (!this.props.error) {
        toast.success("Successfully removed your bank account.");
      }
    });
  };

  _getBankRow = () => {
    const { bank } = this.props;
    if (isEmpty(bank)) return null;

    const contributionSource = {
      bankName: bank.bankName,
      accountSubtype: bank.accountSubtype,
      accountId: bank.accountId,
      id: bank.id,
    };

    let contributionInfo;

    const { recurringContribution } = bank;

    if (!recurringContribution) {
      contributionInfo = {};
    } else {
      contributionInfo = {
        ...recurringContribution,
        schedule:
          payrollPeriodsToEnglishMapping[recurringContribution.frequency],
        end: recurringContribution.nextScheduledDate,
        anchorPayDate: recurringContribution.nextScheduledDate,
      };
    }

    const bankIsPendingFinalVerification = bank.state === "BankAccountVerified";

    if (!this.props.bankIsVerified) return null;

    return (
      <Col md={12}>
        <Row>
          <ContributionSource
            isEmployer={false}
            contributionSource={contributionSource}
            contributionInfo={contributionInfo}
            isPendingVerification={bankIsPendingFinalVerification}
          />
        </Row>

        <Row>
          <OneTimeContributionCard />
        </Row>
      </Col>
    );
  };

  _handleOnSuccess = (token, metadata, userLegalName) => {
    if (this.props.onSuccess) {
      this.props.onSuccess(token, metadata, userLegalName);
      toast.success("Bank account successfully re-authenticated.");
    }
  };

  _getPlaidLinkUpdateCard = (bank) => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ marginRight: "10px" }}>
          <Button
            size="sm"
            color="red"
            name="action"
            btnLabel="Remove Bank"
            onClick={() => this.setState({ isDeleting: true })}
          />
        </div>
        <div>
          <ReAuthPlaidLink bank={bank} onSuccess={this._handleOnSuccess} />
        </div>
      </div>
    );
  };

  _handleRemoveBank = () => {
    const header = "Are you sure you want to remove your bank account?";
    return (
      <>
        <Card>
          <Card.Body>
            <IconCircle
              type="error"
              icon={
                <FiAlertTriangle color="white" stroke="#B12121" size={16} />
              }
            />
            <IconHeader variant="centeredText" headerText={header} />

            {!isEmpty(this.props.error) && (
              <Alert type="error" msg={this.props.error} />
            )}
            <div className="remove-bank-action">
              <div className="bank-cancel">
                <Button
                  size="sm"
                  color="cancel"
                  name="cancel"
                  btnLabel="Cancel"
                  onClick={() => this.setState({ isDeleting: false })}
                />
              </div>
              <Button
                size="sm"
                color="red"
                name="submit"
                btnLabel="Remove"
                onClick={this._removeBankLink}
                withArrow={true}
                loading={this.props.isLoading}
              />
            </div>
          </Card.Body>
        </Card>
      </>
    );
  };

  render() {
    const bankRow = this._getBankRow();
    const showAddBankCard = isEmpty(bankRow) || !this.props.bankIsVerified;

    const { bank } = this.props;

    const bankAccountName = get(bank, "bankName");
    const lastFourDigits = get(bank, "accountId");
    const description = `Re-authenticate your ${bankAccountName} bank account ending in ${lastFourDigits} to resume payroll deductions.`;

    return (
      <div className="banks-container">
        {this.state.showOneTimeContributionModal && (
          <ContributionModal
            show={this.state.showOneTimeContributionModal}
            onSuccess={() => {
              this.setState({ showOneTimeContributionModal: false });
            }}
            onClose={() => {
              this.setState({ showOneTimeContributionModal: false });
            }}
            depositOnly={true}
          />
        )}
        <div className="bank-row-header">
          <h1 className="section-title">Bank Account</h1>
        </div>

        {showAddBankCard && (
          <Row>
            <Col md={6}>
              <AddBankCard />
            </Col>
          </Row>
        )}

        {this.props.individualReAuthRequired ? (
          <Row>
            <Col md={6}>
              {this.state.isDeleting ? (
                this._handleRemoveBank()
              ) : (
                <PlaidLinkUpdateCard
                  icon={
                    <FiAlertTriangle color="white" stroke="#B12121" size={16} />
                  }
                  title="Bank Re-Authentication Required!"
                  description={description}
                  color="red"
                  withArrow={true}
                  onClick={this._getPlaidLinkUpdateCard(bank)}
                />
              )}
            </Col>
          </Row>
        ) : (
          <> {bankRow}</>
        )}
      </div>
    );
  }
}

const actions = [
  transactionConstants.GET_TRANSACTIONS,
  bankConstants.OAUTH_PLAID_LINK_TOKEN,
  bankConstants.DELETE_BANK,
];

const loadingSelector = createLoadingSelector([actions]);
const errorSelector = createErrorSelector([actions]);

const mapStateToProps = (state) => {
  const bank = bankSelector(state);
  const bankId = get(bank, "id");

  const bankAccountId = bankId;
  const expiringBanks = expiringIndividualBankSelector(state);
  const needsReAuth = shouldReAuthBank(expiringBanks, bankAccountId);

  const individualReAuthRequired =
    bankSelector(state) &&
    !isEmpty(expiringIndividualBankSelector(state)) &&
    needsReAuth;

  return {
    bank,
    error: errorSelector(state),
    bankIsVerified: isBankVerifiedSelector(state),
    isFetching: loadingSelector(state),
    iraAccountId: getIraAccountIdSelector(state),
    transactions: state.transactions.transactions,
    individualReAuthRequired,
  };
};

const mapDispatchToProps = {
  push,
  getTransactions,
  deleteBank,
};

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(Banks));
