import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get, isEmpty } from "lodash";
import { formatCurrency } from "utils/number";
import { accountTypesToEnglishMapping } from "statics/accountTypes";

import Button from "components/Button";
import IconHeader from "components/IconHeader";
import Alert from "components/Alert";
import SelectBox from "../../../../signUp/individual/SelectBox";

class DistributionAccountType extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    client: PropTypes.object,
    accountId: PropTypes.string,
    accounts: PropTypes.arrayOf(PropTypes.object),
    goToNamedStep: PropTypes.func,
    onAccountSelect: PropTypes.func,
  };

  constructor() {
    super();
    this.state = {
      selectedAccountType: "",
    };
  }

  _setSelectedAccountType = (accountId) => {
    this.setState({ selectedAccountType: accountId });

    if (this.props.onAccountSelect) {
      this.props.onAccountSelect(accountId);
    }
  };

  render() {
    const { accounts, error, goToNamedStep } = this.props;

    const articleStyle = {
      paddingBottom: 0,
    };

    return (
      <div id="account-selection">
        <div className="mega-container">
          <div className="step-container is-active" data-circle-percent="100">
            <section className="page-title-wrap">
              <article className="text-cell">
                <IconHeader
                  variant="pageHeader"
                  headerText="Which account would you like to withdrawal funds from?"
                />
              </article>
            </section>
            <div>
              <section
                className="form-sec-2col"
                style={{ paddingTop: 20, paddingBottom: 20 }}
              >
                <article className="col-form" style={articleStyle}>
                  <div className="account-types">
                    {accounts.map((account) => {
                      return (
                        <SelectBox
                          label={
                            accountTypesToEnglishMapping[account.accountType]
                          }
                          description={`${formatCurrency(
                            account.balances.totalAccountBalance
                          )}`}
                          key={account.id}
                          displayChevron={true}
                          onSelect={() => {
                            this._setSelectedAccountType(account.id);
                          }}
                          isSelected={
                            this.state.selectedAccountType === account.id
                          }
                        />
                      );
                    })}
                  </div>
                </article>
              </section>
              <section className="form-sec-2col">
                {error && <Alert type="error" msg={error} />}
                <div className="submit-row btn-row">
                  <Button
                    onClick={() => goToNamedStep("addressConfirmation")}
                    btnLabel="Go Back"
                    color="cancel"
                  />
                  <Button
                    style={{ marginLeft: 10 }}
                    name="action"
                    disabled={isEmpty(this.state.selectedAccountType)}
                    onClick={() => goToNamedStep("distributionAmount")}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const iraAccounts = state.accounts.iraAccounts;

  const accountsWithBalance = iraAccounts.filter(
    (account) => get(account, "balances.totalAccountBalance", 0) !== 0
  );

  const accounts = accountsWithBalance;

  return {
    accounts,
  };
};

const mapDispatchToProps = { push };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(DistributionAccountType));
