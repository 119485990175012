import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth0 } from "utils/react-auth0-wrapper";
import * as Sentry from "@sentry/react";
import queryString from "query-string";
import { individualSite } from "../utils/determineSite";
import PropTypes from "prop-types";

const SentryRoute = Sentry.withSentryRouting(Route);

const PublicRoute = ({ ...props }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const invitationCode = queryString.parse(
    window.location.search
  ).invitationCode;
  const connection = queryString.parse(window.location.search).connection;
  const isRootRoute = props.path === "/";
  const handleRootRoute = individualSite() && isRootRoute;
  if (isAuthenticated) {
    // Even though they went to a public route, they have an active session
    // Let's redirect them to our Home route that will get them to the right spot
    return <Redirect to={`/login/${window.location.search}`} />;
  } else if (handleRootRoute) {
    // If they are not authenticated and they are on the root route, we want to redirect them to the login page (may remove in future)
    loginWithRedirect({
      authorizationParams: {
        screen_hint: invitationCode ? "signup" : "login",
        ...(connection && { connection }),
      },
      appState: {
        targetUrl: `${location.pathname}${
          location.search ? location.search : ""
        }`,
      },
    });
  } else {
    return <SentryRoute {...props} />;
  }
};

PublicRoute.propTypes = {
  path: PropTypes.string,
};

export default PublicRoute;
