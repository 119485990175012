import React from "react";
import PropTypes from "prop-types";
import ProgressBar from "components/ProgressBar";
import CircularProgressBar from "components/CircularProgressBar";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import moment from "moment";
import { every, isEmpty, isNull, pick, transform, trim } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { userService } from "services/userService";
import {
  getUserState,
  updateIraAccountIdentityInfo,
} from "actions/userActions";
import { withApollo } from "@apollo/client/react/hoc";
import Button from "components/Button";
import AddressValidator from "components/AddressValidator";
import Alert from "components/Alert";
import "./IndividualEnroll.scss";
import InfoTooltip from "components/InfoTooltip";
import IconSpinner from "components/IconSpinner";
import IndividualExtraDetails from "./IndividualExtraDetails";
import IndividualVerifyInfo from "./IndividualVerifyInfo";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import StateSelect from "components/StateSelect";
import { userConstants } from "actions/types";
import { userIs401kRollIn } from "../../../store/selectors/user";
import {
  IndividualAddress1Validator,
  individualAddress2Validator,
  NAME_LENGTH_ERROR,
  NAME_MESSAGE,
  noWhitespaceRegex,
  postalCodeValidator,
  PRINTABLE_ASCII_MESSAGE,
  testFirstLastName,
  testName,
  testPhoneNumber,
  testPrintableAsciiCharacters,
  testSsn,
  validateUsersAddress,
  whiteSpaceError,
  ALPHANUMERIC_SPACES_MESSAGE,
  testAlphanumericSpaces,
} from "utils/fieldValidators";
import { scrollToTop } from "utils/dom";
import { PERSONAL_INFO } from "statics/onboardingSteps";
import { ScrollToFieldError } from "utils/form";

/* eslint-disable react/no-children-prop */

let yup = require("yup");

const schema = yup.object({
  firstName: yup
    .string()
    .trim()
    .label("First")
    .matches(noWhitespaceRegex, whiteSpaceError)
    .test("first-name", ALPHANUMERIC_SPACES_MESSAGE, testAlphanumericSpaces)
    .required()
    .test("is-valid-name", NAME_MESSAGE, testName)
    .min(1, "Must be at least one character.")
    .max(20)
    .test("first-last-name-length", NAME_LENGTH_ERROR, testFirstLastName),
  lastName: yup
    .string()
    .trim()
    .label("Last")
    .matches(noWhitespaceRegex, whiteSpaceError)
    .test("last-name", ALPHANUMERIC_SPACES_MESSAGE, testAlphanumericSpaces)
    .required()
    .test("is-valid-name", NAME_MESSAGE, testName)
    .min(1, "Must be at least one character.")
    .max(20, "Must be less than 20 characters.")
    .test("first-last-name-length", NAME_LENGTH_ERROR, testFirstLastName),
  legalName: yup
    .string()
    .trim()
    .label("Legal Name")
    .matches(noWhitespaceRegex, whiteSpaceError)
    .test("legal-name", ALPHANUMERIC_SPACES_MESSAGE, testAlphanumericSpaces)
    .optional()
    .test("is-valid-legal-name", NAME_MESSAGE, testName)
    .max(30),
  dob: yup
    .date()
    .label("Date Of Birth")
    .required()
    .test("dob", "You must be at least 18 to create an account.", (value) => {
      return moment().diff(moment(value), "years") >= 18;
    })
    .test(
      "dob",
      "You must be under 100 years old to create an account.",
      (value) => {
        return moment().diff(moment(value), "years") < 100;
      }
    ),

  ssn: yup
    .string()
    .trim()
    .required()
    .label("Social Security Number")
    .test("ssn", "${path} must be of format 111-11-1111", testSsn),
  confirmSsn: yup
    .string()
    .trim()
    .required()
    .label("Confirm Social Security Number")
    .test("ssn-match", "Social Security Numbers must match", function (value) {
      return this.parent.ssn === value;
    }),
  address1: IndividualAddress1Validator,
  address2: individualAddress2Validator,
  city: yup
    .string()
    .label("City")
    .required()
    .test("city", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .test("city", ALPHANUMERIC_SPACES_MESSAGE, testAlphanumericSpaces)
    .max(50)
    .matches(noWhitespaceRegex, whiteSpaceError),
  state: yup
    .string()
    .label("State")
    .required()
    .test("state", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .max(14),
  postalCode: postalCodeValidator,
  phone: yup
    .string()
    .trim()
    .label("Phone")
    .required()
    .test("phone", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .test(
      "us-phone",
      "${path} is not a valid phone number. Ex: (123) 456-7890 or 1234567890",
      testPhoneNumber
    )
    .max(15),
});

const verifyIdentity = (identity) => {
  const userIdentity = pick(identity, [
    "firstName",
    "lastName",
    "dob",
    "address1",
    "city",
    "state",
    "postalCode",
    "phone",
    "ssn",
  ]);
  return every(userIdentity, (val) => !isNull(val) && !isEmpty(val));
};

class IndividualEnroll extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    updateIraAccountIdentityInfo: PropTypes.func,
    getUserState: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    userState: PropTypes.string,
    isPaychex: PropTypes.bool,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      identity: {
        firstName: "",
        lastName: "",
        legalName: "",
        dob: "",
        ssn: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        phone: "",
      },
      submitted: false,
      showAddressModal: false,
      loading: true,
      shouldVerifyData: false,
      showLastEnrollmentPage: false,
      fetchingAddress: false,
      confirmSsn: "",
      showProfileInformationForm: false,
    };

    userService.getIraAccountForm(this.props.client).then(
      (data) => {
        let identity = {
          firstName: data.identity.firstName,
          lastName: data.identity.lastName,
          legalName: data.identity.legalName,
          address1: data.identity.address1,
          address2: data.identity.address2,
          city: data.identity.city,
          state: data.identity.state,
          postalCode: data.identity.postalCode,
          phone: data.identity.phone,
          dob: data.identity.dob,
          ssn: data.identity.ssn,
        };

        const identityIsVerified = verifyIdentity(identity);

        if (this._isMounted) {
          this.setState({
            submitted: false,
            identity,
            loading: false,
            shouldVerifyData: identityIsVerified,
          });
        }
      },
      () => {
        if (this._isMounted) {
          this.setState({
            submitted: false,
            loading: false,
          });
        }
      }
    );
  }

  componentDidMount() {
    this._isMounted = true;
    scrollToTop();
    window.analytics.page("Individual Information");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submitIndividual = async (values, skipAddressValidation = false) => {
    // verify user address, if it matches USPS just continue on
    // if USPS endpoint fails continue on
    // if there is a mismatch then open modal to allow customer to decide which to use
    if (!skipAddressValidation) {
      try {
        this.setState({
          fetchingAddress: true,
        });

        const userAddress = {
          address1: values.address1,
          address2: values.address2,
          state: values.state,
          postalCode: values.postalCode,
          city: values.city,
        };

        const validatedAddress = await userService.getValidatedAddress(
          this.props.client,
          userAddress
        );
        this.setState({
          fetchingAddress: false,
        });

        const userAddressIsValid = validateUsersAddress(
          userAddress,
          validatedAddress
        );

        if (!userAddressIsValid) {
          this.setState({
            showAddressModal: true,
            validatedAddress,
          });

          return;
        }
      } catch (error) {
        // if the validation endpoint fails we dont want to prevent user from saving so continue on and log error
        console.log(error);
      }
    }

    let identity = {
      firstName: values.firstName,
      lastName: values.lastName,
      legalName: values.legalName,
      dob: values.dob,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      phone: values.phone,
      ssn: values.ssn,
    };

    this.props
      .updateIraAccountIdentityInfo(this.props.client, identity)
      .then(() => {
        this.props.getUserState(this.props.client);
        if (!this.props.error) {
          this.setState({
            showLastEnrollmentPage: true,
            shouldVerifyData: false,
          });
          // if there was an error we want to make sure we are showing the info page w/ error
        } else {
          this.setState({
            showAddressModal: false,
            shouldVerifyData: false,
            showLastEnrollmentPage: false,
          });
        }
      });

    window.analytics.track("Submitted Individual Information", {
      userState: this.props.userState,
    });

    if (this._isMounted) {
      this.setState({
        submitted: true,
      });
    }
  };

  _onEditAddress = () => {
    this.setState({
      showAddressModal: false,
      shouldVerifyData: false,
      showLastEnrollmentPage: false,
    });
  };

  _closeModal = (shouldShowEditMode = false) => {
    if (shouldShowEditMode) {
      this._onEditAddress();
      return;
    }
    this.setState({
      showAddressModal: false,
    });
  };

  _showWelcomeToIconText = () => {
    const content = {
      paychex: {
        title: "Welcome To Icon.",
        introText:
          "Rolling over your 401(k) is easy - we'll guide you though the two-step process.",
        steps: [
          "Create your rollover IRA, and answer a few questions so we can recommend an investment strategy for you.",
          "Confirm your rollover from Paychex.",
        ],
        closingText:
          "Start enjoying the benefits of your new retirement account right away.",
      },
      default: {
        title: "Welcome to the Icon IRA – Your Retirement, Simplified.",
        introText: "Creating your account is easy—just two simple steps:",
        steps: [
          "Create your IRA in minutes",
          "Answer a few quick questions, and we'll build an investment strategy tailored to you.",
        ],
        closingText:
          "Your savings will be invested in a portfolio designed to grow with you. We'll handle the monitoring and rebalancing—so you stay on track. Let's get started.",
      },
    };

    const { title, introText, steps, closingText } = this.props.isPaychex
      ? content.paychex
      : content.default;

    return (
      <div id="welcome-to-icon">
        <p className="welcome-title">{title}</p>
        <div className="welcome-steps">
          <p>{introText}</p>
          <ol>
            {steps.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ol>
          <p>{closingText}</p>
        </div>
        <Button
          name="submit"
          btnLabel="Let's get started"
          withArrow={true}
          onClick={() => this.setState({ showProfileInformationForm: true })}
        />
      </div>
    );
  };

  _renderIndividualForm = () => {
    const { identity } = this.state;

    var articleStyle = {
      paddingBottom: 0,
    };
    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={(values) => {
          this.setState({ identity: values });
          this.submitIndividual(
            transform(
              values,
              (result, value, key) => (result[key] = trim(value))
            )
          );
        }}
        enableReinitialize={false}
        initialValues={{
          ...identity,
          confirmSsn: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <ScrollToFieldError />
            <div className="mega-container">
              <div
                className="step-container is-active"
                data-circle-percent="30"
              >
                <section className="page-title-wrap">
                  <article className="text-cell">
                    <p className="page-eyebrow">PERSONAL INFO</p>
                    <p className="page-title">Tell us about yourself.</p>
                    <p className="page-subtext">
                      We need some personal information in order to get you up
                      and running.
                    </p>
                  </article>
                  <article className="progress-cell">
                    <ul className="circular-progress-wrap">
                      <CircularProgressBar
                        strokeWidth="8"
                        sqSize="75"
                        percentage="30"
                      />
                    </ul>
                  </article>
                </section>
                <div id="form-individual-info">
                  <section className="form-sec-2col">
                    <article className="col-form" style={articleStyle}>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formBasicFirstName">
                          <Form.Label>Legal First Name</Form.Label>
                          <Form.Control
                            name="firstName"
                            placeholder="Legal first name"
                            value={values.firstName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.firstName && !!errors.firstName}
                            isValid={touched.firstName && !errors.firstName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formBasicLastName">
                          <Form.Label>Legal Last Name</Form.Label>
                          <Form.Control
                            name="lastName"
                            placeholder="Legal last name"
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={touched.lastName && !!errors.lastName}
                            isValid={touched.lastName && !errors.lastName}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        {" "}
                        <Form.Group as={Col} controlId="formBasicSsnField">
                          <Form.Label>
                            Social Security Number{" "}
                            <InfoTooltip tooltipBody="Your Social Security number helps us verify your identity and esnsure we're creating a bank account that follows all government regulations." />
                          </Form.Label>
                          <InputMask
                            name="ssn"
                            mask="999-99-9999"
                            placeholder="000-00-0000"
                            value={values.ssn}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.ssn && !!errors.ssn}
                            isValid={touched.ssn && !errors.ssn}
                            children={(props) => <Form.Control {...props} />}
                          ></InputMask>
                          <Form.Control.Feedback type="invalid">
                            {errors.ssn}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          controlId="formBasicSsnConfirmField"
                        >
                          <Form.Label>
                            Confirm Social Security Number
                          </Form.Label>
                          <InputMask
                            name="confirmSsn"
                            mask="999-99-9999"
                            placeholder="000-00-0000"
                            value={values.confirmSsn}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.confirmSsn && !!errors.confirmSsn
                            }
                            isValid={touched.confirmSsn && !errors.confirmSsn}
                            children={(props) => <Form.Control {...props} />}
                          ></InputMask>
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmSsn}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md={4}
                          sm={12}
                          controlId="formBasicDateOfBirth"
                        >
                          <Form.Label>Date of Birth</Form.Label>
                          <InputMask
                            name="dob"
                            placeholder="MM/DD/YYYY"
                            mask="99/99/9999"
                            value={values.dob}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.dob && !!errors.dob}
                            isValid={touched.dob && !errors.dob}
                            children={(props) => <Form.Control {...props} />}
                          ></InputMask>
                          <Form.Control.Feedback>
                            {errors.dob}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Label>
                        Home Address{" "}
                        <InfoTooltip tooltipBody="This is used for identity verification, so please use your permanent residential address P.O. boxes will be rejected." />
                      </Form.Label>
                      <Form.Group controlId="formBasicaddress1">
                        <Form.Control
                          name="address1"
                          placeholder="Address 1"
                          value={values.address1}
                          onChange={handleChange}
                          isInvalid={touched.address1 && !!errors.address1}
                          isValid={touched.address1 && !errors.address1}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.address1}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="formBasicaddress2">
                        <Form.Control
                          name="address2"
                          placeholder="Address 2"
                          value={values.address2}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={touched.address2 && !!errors.address2}
                          isValid={touched.address2 && !errors.address2}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.address2}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formBasicCity">
                          <Form.Control
                            name="city"
                            placeholder="City"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.city && !!errors.city}
                            isValid={touched.city && !errors.city}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.city}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} sm={4} controlId="formBasicState">
                          <StateSelect
                            value={values.state}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.state}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          sm={3}
                          controlId="formBasicPostalCode"
                        >
                          <Form.Control
                            name="postalCode"
                            placeholder="Zip"
                            value={values.postalCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.postalCode && !!errors.postalCode
                            }
                            isValid={touched.postalCode && !errors.postalCode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.postalCode}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} sm={5} controlId="formBasicPhone">
                          <InputMask
                            maskChar=" "
                            mask="(999) 999-9999"
                            name="phone"
                            placeholder="Phone"
                            value={values.phone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.phone && !!errors.phone}
                            isValid={touched.phone && !errors.phone}
                            children={(props) => <Form.Control {...props} />}
                          ></InputMask>
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </article>
                  </section>

                  <section className="form-sec-2col">
                    <article className="col-form">
                      {this.props.error && (
                        <Alert type="error" msg={this.props.error} />
                      )}
                      <div className="submit-row">
                        <Button
                          name="submit"
                          withArrow={true}
                          loading={
                            this.props.isFetching || this.state.fetchingAddress
                          }
                        />
                      </div>
                    </article>
                  </section>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    let content;

    if (this.state.loading) {
      content = <IconSpinner centered />;
    } else if (this.state.shouldVerifyData) {
      content = (
        <IndividualVerifyInfo
          identity={this.state.identity}
          submitIndividual={this.submitIndividual}
          onEditAddress={this._onEditAddress}
          isFetching={this.props.isFetching || this.state.fetchingAddress}
        />
      );
    } else if (this.state.showLastEnrollmentPage) {
      content = <IndividualExtraDetails />;
    } else if (this.state.showProfileInformationForm) {
      content = this._renderIndividualForm();
    } else {
      content = this._showWelcomeToIconText();
    }
    return (
      <div className="individual-enroll">
        {this.state.showAddressModal && (
          <AddressValidator
            show={this.state.showAddressModal}
            onSubmit={this.submitIndividual}
            validatedAddress={this.state.validatedAddress}
            onClose={this._closeModal}
            values={this.state.identity}
          />
        )}
        <ProgressBar
          isEmployer={false}
          activeStepId={PERSONAL_INFO.id}
          progressPercent={"40"}
        />
        <div>{content}</div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  userConstants.USER_ON_BOARDING_IDENTITY_UPDATE,
]);

const errorSelector = createErrorSelector([
  userConstants.USER_ON_BOARDING_IDENTITY_UPDATE,
]);

const mapStateToProps = (state) => ({
  userState: state.user.userState.state,
  error: errorSelector(state),
  isFetching: loadingSelector(state),
  isPaychex: userIs401kRollIn(state),
});

const mapDispatchToProps = {
  updateIraAccountIdentityInfo,
  getUserState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualEnroll));
