import { get } from "lodash";
import env from "@beam-australia/react-env";

export const getPath = () => {
  const domain = env("AUTH0_DOMAIN");
  const clientId = env("AUTH0_CLIENTID");
  const domainPath = `https://${env("AUTH0_DOMAIN")}`;
  if (domain === "login-pre-production.iconsavingsplan.com") {
    return `${domainPath}/${clientId}`;
  }
  return domainPath;
};

export const intercomHashSelector = (state) => {
  const intercomHashPath = `${getPath()}/intercom_hash`;
  const auth0User = get(state, `user.auth0User`);
  return auth0User[intercomHashPath];
};

export const changeEmailSelector = (state) => {
  const changeEmailPath = `${getPath()}/change_email`;
  const auth0User = get(state, `user.auth0User`);
  return auth0User[changeEmailPath] || "";
};

export const emailConfirmedSelector = (state) => {
  const emailConfirmedPath = `${getPath()}/email_confirmed`;
  const auth0User = get(state, `user.auth0User`);
  return auth0User[emailConfirmedPath];
};

export const iconIdSelector = (auth0User) => {
  const iconUserIdPath = `${getPath()}/icon_id`;
  return get(auth0User, `${iconUserIdPath}`);
};

export const auth0PlanCodeSelector = (state) => {
  const planCodePath = `${getPath()}/plan_code`;
  const auth0User = get(state, `user.auth0User`);

  return get(auth0User, planCodePath);
};

export const auth0ReferralCodeSelector = (state) => {
  const referralCodePath = `${getPath()}/referral_code`;
  const auth0User = get(state, `user.auth0User`);

  return get(auth0User, referralCodePath);
};

export const auth0AccountSessionSelector = (state) => {
  const activeAccountIdPath = `${getPath()}/active_account_id`;
  const auth0User = get(state, `user.auth0User`);

  return get(auth0User, activeAccountIdPath);
};

export const hasPreTaxFundsSelector = (state) => {
  const hasPreTaxFundsPath = `${getPath()}/has_pretax_funds`;
  const auth0User = get(state, `user.auth0User`);

  return get(auth0User, hasPreTaxFundsPath);
};

export const hasRothFundsSelector = (state) => {
  const hasRothFundsPath = `${getPath()}/has_roth_funds`;
  const auth0User = get(state, `user.auth0User`);

  return get(auth0User, hasRothFundsPath);
};

export const hasBothPreTaxAndRothFundsSelector = (state) => {
  const hasPreTaxFunds = hasPreTaxFundsSelector(state);
  const hasRothFunds = hasRothFundsSelector(state);

  return hasPreTaxFunds && hasRothFunds;
};
