import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import history from "utils/history";
import { clearSession } from "utils/session";

const DEFAULT_REDIRECT_CALLBACK = () => {
  history.replace({}, document.title, window.location.pathname);
};

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        ...initOptions,
        authorizationParams: {
          audience: initOptions.audience,
          redirect_uri: initOptions.redirect_uri,
        },
      });
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    try {
      return await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        loginWithPopup,
        handleRedirectCallback,
        getUser: (...p) => auth0Client.getUser(...p),
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: async (...p) => {
          try {
            return await auth0Client.getTokenSilently(...p);
          } catch (e) {
            // error here means the user was logged out/session expired
            clearSession();
          }
        },
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => {
          auth0Client.logout(...p);
          clearSession();
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.node,
  onRedirectCallback: PropTypes.func,
};
