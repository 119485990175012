import React from "react";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { toast } from "react-toastify";
import { chain, filter, find, get, map } from "lodash";

import Alert from "components/Alert";
import Button from "components/Button";
import IconSpinner from "components/IconSpinner";

import { createSalesQuote, getPricingData } from "actions/adminActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { formatCurrency } from "utils/number";
import { ScrollToFieldError, zeroToNull } from "utils/form";
import { push } from "connected-react-router";
import { orderFormCompanySizeRange } from "statics/businessCodes";

const yup = require("yup");
const schema = yup.object({
  companyName: yup.string().label("Company Name").required(),
  companySize: yup.string().label("Company Size").required(),
  companyContact: yup.string().email().label("Company Contact").required(),
  selectedSubscriptionId: yup.string().label("Monthly Pricing").required(),

  selectedSetupFeeId: yup.string().label("Setup Fee").required(),
  discountPercent: yup
    .number()
    .label("Discount Percent")
    .max(100, "Discount Percent must be less than or equal to 100"),
  discountLength: yup
    .string()
    .label("Discount Length")
    .when("discountPercent", {
      is: (discountPercent) => discountPercent > 0,
      then: yup.string().required(),
    }),
});

const noFee = {
  id: 0,
  unitAmount: 0,
};

const YEAR = "year";

const DISCOUNT_LENGTHS = [
  {
    value: "ONE_YEAR",
    label: "One Year",
  },
  {
    value: "TWO_YEARS",
    label: "Two Years",
  },
  {
    value: "FOREVER",
    label: "Forever",
  },
];

const DISCOUNT_PERCENTAGES = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 5,
    label: "5%",
  },
  {
    value: 10,
    label: "10%",
  },
  {
    value: 15,
    label: "15%",
  },
  {
    value: 20,
    label: "20%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 30,
    label: "30%",
  },
];

const CONTRACT_LENGTHS = [
  {
    value: "MONTHLY",
    label: "Monthly",
  },
  {
    value: "ONE_YEAR",
    label: "One Year",
  },
  {
    value: "TWO_YEARS",
    label: "Two Years",
  },
  {
    value: "THREE_YEARS",
    label: "Three Years",
  },
  {
    value: "FOUR_YEARS",
    label: "Four Years",
  },
  {
    value: "FIVE_YEARS",
    label: "Five Years",
  },
];

const BILLING_FREQUENCIES = [
  {
    value: "MONTHLY",
    label: "Monthly",
  },
  {
    value: "YEARLY",
    label: "Annual",
  },
  {
    value: "YEARLY_BILLED_MONTHLY",
    label: "Annual (Billed Monthly)",
  },
];

const REFERRING_PARTNERS = [
  {
    value: "acrisure",
    label: "Acrisure",
  },
  {
    value: "viventium",
    label: "Viventium",
  },
  {
    value: "paylocity",
    label: "Paylocity",
  },
  {
    value: "pinkpayroll",
    label: "Pink Payroll",
  },
];

const BILLING_FREQUENCY_TO_BILLING_INTERVAL = {
  MONTHLY: "month",
  YEARLY: YEAR,
};

class SalesQuoteForm extends React.PureComponent {
  static propTypes = {
    getPricingData: PropTypes.func,
    onSuccess: PropTypes.func,
    createSalesQuote: PropTypes.func,
    push: PropTypes.func,
    onClose: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    isSubmitting: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      initialFetching: true,
      setupFees: [],
      subscriptions: [],
    };
  }

  async componentDidMount() {
    const {
      data: { setupFees, subscriptions },
    } = await this.props.getPricingData(this.props.client);

    this.setState({
      setupFees: [...setupFees, noFee],
      subscriptions: [...subscriptions, noFee],
      initialFetching: false,
    });
  }

  _onSubmit = (vals) => {
    // in the case where we aren't applying a setup fee, just pass in null
    const setupFeeId = zeroToNull(vals.selectedSetupFeeId);
    const subscriptionId = zeroToNull(vals.selectedSubscriptionId);
    const discountPercent = zeroToNull(vals.discountPercent);

    const metadata = {
      subscriptionId,
      setupFeeId,
      discountPercent,
      discountLength: discountPercent ? vals.discountLength : null,
      billingFrequency: vals.billingFrequency,
      contractLength: vals.contractLength,
      referralCode: vals.referralCode,
    };

    this.props
      .createSalesQuote(this.props.client, {
        metadata,
        companyName: vals.companyName,
        companySize: vals.companySize,
        companyContact: vals.companyContact,
      })
      .then(() => {
        if (!this.props.error) {
          toast.success("Successfully created sales quote.");
          this.props.push("/dashboard/sales_quotes");
        }
      });
  };

  subscriptionOptions = (billingFrequency) => {
    return chain(this.state.subscriptions)
      .filter((subscription) => {
        const subInterval = get(subscription, "recurring.interval");
        if (["MONTHLY", "YEARLY"].includes(billingFrequency)) {
          return (
            subInterval ===
            BILLING_FREQUENCY_TO_BILLING_INTERVAL[billingFrequency]
          );
        } else if (billingFrequency === "YEARLY_BILLED_MONTHLY") {
          const metadata = get(subscription, "metadata", {});
          return metadata.pricingSubtype === "YEARLY_BILLED_MONTHLY";
        }
        return subInterval === YEAR;
      })
      .map((subscription) => {
        let label = formatCurrency(subscription.unitAmount);
        if (billingFrequency === "YEARLY_BILLED_MONTHLY") {
          label = `Annual - ${formatCurrency(
            subscription.unitAmount * 12
          )}, Billed Monthly: ${formatCurrency(subscription.unitAmount)}`;
        }
        return (
          <option value={subscription.id} key={subscription.id}>
            {label}
          </option>
        );
      })
      .value();
  };

  discountLengthOptions = () => {
    return map(DISCOUNT_LENGTHS, (discountLength) => {
      return (
        <option value={discountLength.value} key={discountLength.value}>
          {discountLength.label}
        </option>
      );
    });
  };

  contractLengthOptions = () => {
    return map(CONTRACT_LENGTHS, (contractLength) => {
      return (
        <option value={contractLength.value} key={contractLength.value}>
          {contractLength.label}
        </option>
      );
    });
  };
  billingFrequencyOptions = (values) => {
    let frequencyOptions = BILLING_FREQUENCIES;
    if (values.contractLength !== "MONTHLY") {
      frequencyOptions = filter(BILLING_FREQUENCIES, (frequency) => {
        return frequency.value !== "MONTHLY";
      });
    } else {
      frequencyOptions = filter(BILLING_FREQUENCIES, (frequency) => {
        return frequency.value === "MONTHLY";
      });
    }

    return map(frequencyOptions, (billingFrequency) => {
      return (
        <option value={billingFrequency.value} key={billingFrequency.value}>
          {billingFrequency.label}
        </option>
      );
    });
  };

  discountOptions = () => {
    return map(DISCOUNT_PERCENTAGES, (discountLength) => {
      return (
        <option value={discountLength.value} key={discountLength.value}>
          {discountLength.label}
        </option>
      );
    });
  };

  getBillingPricingLabel(billingFrequency) {
    const selectedBillingFrequency = find(BILLING_FREQUENCIES, {
      value: billingFrequency,
    });

    return selectedBillingFrequency.label;
  }

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }
    return (
      <div className="billing-form">
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={this._onSubmit}
          enableReinitialize={true}
          initialValues={{
            selectedSubscriptionId: "",
            selectedSetupFeeId: "",
            discountPercent: 0,
            discountLength: "",
            companyName: "",
            companySize: "",
            companyContact: "",
            billingFrequency: "",
            contractLength: "",
            referralCode: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            handleBlur,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <Form.Group>
                <Form.Row>
                  <Form.Group as={Col} sm={6} controlId="formBasicUserEmail">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      name="companyName"
                      placeholder="Company Name"
                      value={values.companyName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={touched.companyName && !!errors.companyName}
                      isValid={touched.companyName && !errors.companyName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={6} controlId="formBasicUserEmail">
                    <Form.Label>Company Contact (Email)</Form.Label>
                    <Form.Control
                      name="companyContact"
                      placeholder="Company Contact"
                      value={values.companyContact}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={
                        touched.companyContact && !!errors.companyContact
                      }
                      isValid={touched.companyContact && !errors.companyContact}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyContact}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="companySize">
                    <Form.Label>Company Size</Form.Label>
                    <Form.Control
                      as="select"
                      name="companySize"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.companySize && !!errors.companySize}
                    >
                      <option value="">Select Company Size</option>
                      {orderFormCompanySizeRange.map((size) => (
                        <option key={size.key} value={size.value}>
                          {size.description}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.companySize}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="referrer">
                    <Form.Label>Partner Referral (optional)</Form.Label>
                    <Form.Control
                      as="select"
                      name="referralCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.referralCode && !!errors.referralCode}
                    >
                      <option value="">None</option>
                      {REFERRING_PARTNERS.map((partner) => (
                        <option key={partner.value} value={partner.value}>
                          {partner.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.referralCode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Row>
                  <Form.Group as={Col} sm={6} controlId="contractLength">
                    <Form.Label>Contract Length</Form.Label>
                    <Form.Control
                      sm={4}
                      as="select"
                      name="contractLength"
                      value={values.contractLength}
                      onChange={(e) => {
                        setFieldValue("contractLength", e.target.value);
                        // if we are changing from or into monthly we need to reset the billing frequency
                        if (
                          e.target.value === "MONTHLY" ||
                          values.contractLength === "MONTHLY"
                        ) {
                          setFieldValue("billingFrequency", "");
                        }
                        setFieldValue("selectedSubscriptionId", "");
                      }}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.contractLength && !!errors.contractLength
                      }
                      isValid={touched.contractLength && !errors.contractLength}
                    >
                      <option value="" disabled>
                        Select Contract Length
                      </option>
                      {this.contractLengthOptions()}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.contractLength}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {values.contractLength && (
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="billingFrequency">
                      <Form.Label>Billing Frequency</Form.Label>
                      <Form.Control
                        sm={4}
                        as="select"
                        name="billingFrequency"
                        value={values.billingFrequency}
                        onChange={(e) => {
                          setFieldValue("billingFrequency", e.target.value);
                          setFieldValue("selectedSubscriptionId", "");
                        }}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.billingFrequency && !!errors.billingFrequency
                        }
                        isValid={
                          touched.billingFrequency && !errors.billingFrequency
                        }
                      >
                        <option value="" disabled>
                          Select Billing Frequency
                        </option>
                        {this.billingFrequencyOptions(values)}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.billingFrequency}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                )}
              </Form.Group>
              {!!values.billingFrequency && (
                <Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="formSubscription">
                      <Form.Label>
                        {this.getBillingPricingLabel(values.billingFrequency)}{" "}
                        Pricing
                      </Form.Label>
                      <Form.Control
                        sm={4}
                        as="select"
                        name="selectedSubscriptionId"
                        value={values.selectedSubscriptionId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.selectedSubscriptionId &&
                          !!errors.selectedSubscriptionId
                        }
                        isValid={
                          touched.selectedSubscriptionId &&
                          !errors.selectedSubscriptionId
                        }
                      >
                        <option value="" disabled>
                          Select Subscription
                        </option>
                        {this.subscriptionOptions(values.billingFrequency)}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.selectedSubscriptionId}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="formSetupFee">
                      <Form.Label>Setup Fee</Form.Label>
                      <Form.Control
                        sm={4}
                        as="select"
                        name="selectedSetupFeeId"
                        value={values.selectedSetupFeeId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.selectedSetupFeeId &&
                          !!errors.selectedSetupFeeId
                        }
                        isValid={
                          touched.selectedSetupFeeId &&
                          !errors.selectedSetupFeeId
                        }
                      >
                        <option value="" disabled>
                          Select One-Time Fee
                        </option>
                        {this.state.setupFees.map((fee) => (
                          <option value={fee.id} key={fee.id}>
                            {formatCurrency(fee.unitAmount)}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.selectedSetupFeeId}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={4} controlId={"discountPercent"}>
                      <Form.Label>Discount Percent</Form.Label>
                      <Form.Control
                        sm={4}
                        as="select"
                        name="discountPercentage"
                        value={values.discountPercent}
                        onChange={(e) => {
                          setFieldValue("discountPercent", +e.target.value);
                        }}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.discountPercent && !!errors.discountPercent
                        }
                        isValid={
                          touched.discountPercent && !errors.discountPercent
                        }
                      >
                        <option value="" disabled>
                          Select Discount Percentage
                        </option>
                        {this.discountOptions()}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.discountPercent}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  {values.discountPercent > 0 && (
                    <Form.Row>
                      <Form.Group as={Col} sm={6} controlId="discountLength">
                        <Form.Label>Discount Length</Form.Label>
                        <Form.Control
                          sm={4}
                          as="select"
                          name="discountLength"
                          value={values.discountLength}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.discountLength && !!errors.discountLength
                          }
                          isValid={
                            touched.discountLength && !errors.discountLength
                          }
                        >
                          <option value="" disabled>
                            Select Discount Length
                          </option>
                          {this.discountLengthOptions()}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.discountLength}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  )}
                </Form.Group>
              )}

              <Form.Group>
                {this.props.error && (
                  <Alert type="error" msg={this.props.error} />
                )}
                <div className="btn-row">
                  <Button
                    onClick={() => this.props.push("/dashboard/sales_quotes")}
                    btnLabel={"Cancel"}
                    color={"cancel"}
                    name="cancel"
                  />
                  <Button
                    btnLabel="Generate Quote"
                    name="submit"
                    withArrow={true}
                    loading={this.props.isSubmitting}
                  />
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const errorSelector = createErrorSelector([
  adminConstants.GET_PRICING_SHEET,
  adminConstants.CREATE_SALES_QUOTE,
]);
const loadingSelector = createLoadingSelector(
  adminConstants.CREATE_SALES_QUOTE
);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isSubmitting: loadingSelector(state),
  };
};

const mapDispatchToProps = {
  getPricingData,
  createSalesQuote,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(SalesQuoteForm));
