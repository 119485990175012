import React from "react";
import PropTypes from "prop-types";

import { get, some, every, isEmpty } from "lodash";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { userConstants } from "actions/types";
import { Row } from "react-bootstrap";
import { withApollo } from "@apollo/client/react/hoc";
import { createSupportRequest } from "actions/userActions";
import { isProcessingRolloverSelector } from "store/selectors/user";
import { createLoadingSelector, createErrorSelector } from "store/selectors";

import IconHeader from "components/IconHeader";
import ZendeskSupportRequest from "components/ZendeskSupportRequest";
import IndividualRolloverAccountType from "../../../signUp/individual/IndividualRolloverAccountType";
import IndividualCancelRollover from "../../../signUp/individual/IndividualCancelRollover";
import DistributionWorkflow from "./distributions/DistributionWorkflow";
import SimpleAccordion from "../../../../components/SimpleAccordion";

class SupportPage extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    createSupportRequest: PropTypes.func,
    name: PropTypes.string,
    email: PropTypes.string,
    error: PropTypes.string,
    pathname: PropTypes.string,
    userState: PropTypes.string,

    isSubmitting: PropTypes.bool,
    client: PropTypes.object,
    accounts: PropTypes.array,
    userHasTwoAccounts: PropTypes.bool,
    rolloverSupportFlag: PropTypes.bool,
    isProcessingRollover: PropTypes.bool,
    shouldNotDisplayDistributionSupport: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      showRolloverAccountTypeSelection: false,
      showCloseRolloverAccount: false,
      showDisbursementFlow: false,
      showSupportTicket: false,
    };
  }

  _supportPage = () => {
    var articleStyle = {
      paddingBottom: 0,
    };

    const cancelRollover =
      !this.props.isProcessingRollover || !this.props.rolloverSupportFlag;

    const items = [
      {
        index: 1,
        disable: !this.props.rolloverSupportFlag,
        title: "Thinking About Rolling Over an Account?",
        content:
          "Start your account rollover today to consolidate your savings and take the next step toward achieving your financial goals.",
        actionText: "Start Rollover",
        onClick: () => {
          this.setState({
            showRolloverAccountTypeSelection: true,
          });
        },
      },

      {
        index: 2,
        disable: cancelRollover,
        title: "Need to Cancel Your Rollover Request?",
        content:
          "Follow these simple steps to quickly stop your rollover process.",
        actionText: "Cancel Rollover",
        onClick: () => {
          this.setState({
            showCloseRolloverAccount: true,
          });
        },
      },
      {
        index: 3,
        title: "Need to Update Your Address?",
        content:
          "Easily update your address with these simple steps. Make sure to have your driver’s license or state-issued ID handy.",
        actionText: "Update Address",
        onClick: () => {
          this.props.push("/dashboard/profile/info");
        },
      },
      {
        index: 4,
        disable: this.props.shouldNotDisplayDistributionSupport,
        value: "withdrawal",
        title: "Looking to Access Your Funds?",
        content: "Follow these simple steps to easily access your funds.",
        actionText: "Withdraw Funds",
        onClick: () => {
          this.setState({
            showDisbursementFlow: true,
          });
        },
      },
    ];

    const allItemsHidden = every(items, { disable: true });
    if (isEmpty(items) || allItemsHidden) {
      return null;
    }

    const filteredSupportItems = items.filter((item) => !item.disable);

    return (
      <div>
        <section>
          <article style={articleStyle}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconHeader
                variant="pageHeader"
                headerText="How can we help you?"
              />

              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 30,
                }}
              >
                <div>
                  {filteredSupportItems.map((item) => (
                    <SimpleAccordion
                      style={{ fontColor: "#009BAA" }}
                      defaultStyle={false}
                      key={item.value}
                      title={item.title}
                      body={item.content}
                      onClick={item.onClick}
                      actionText={item.actionText}
                    />
                  ))}
                </div>
              </Row>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 50,
              }}
            >
              <p style={{ color: "#0a2540", fontSize: 14 }}>
                Can&apos;t find what your are looking for?{" "}
                <a
                  onClick={() => {
                    this.setState({
                      showSupportTicket: true,
                    });
                  }}
                  style={{ color: "#009baa", cursor: "pointer" }}
                >
                  Tell us what you need, and we’ll be happy to assist!
                </a>
              </p>
            </div>
          </article>
        </section>
      </div>
    );
  };

  render() {
    let content;

    if (this.state.showRolloverAccountTypeSelection) {
      content = <IndividualRolloverAccountType />;
    } else if (this.state.showCloseRolloverAccount) {
      content = <IndividualCancelRollover />;
    } else if (this.state.showDisbursementFlow) {
      content = <DistributionWorkflow />;
    } else if (this.state.showSupportTicket) {
      content = <ZendeskSupportRequest />;
    } else {
      content = this._supportPage();
    }

    return <>{content}</>;
  }
}

const actions = [userConstants.CREATE_REQUEST];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector([actions]);

const mapStateToProps = (state) => {
  const { firstName, lastName, email } = get(state, "user.profile", {});

  const accounts = state.accounts.iraAccounts;

  const userState = state.user.userState.state;
  const pathname = get(state.router, "location.pathname");
  const featureFlags = get(state.user, "featureFlags", []);
  const rolloverSupportFlag = some(featureFlags, {
    key: "RolloverSupport",
    value: "true",
  });

  const shouldNotDisplayDistributionSupport = accounts.every(
    (account) => get(account, "balances.totalAccountBalance", 0) === 0
  );

  return {
    email,
    name: `${firstName} ${lastName}`,
    userState,
    pathname,
    isProcessingRollover: isProcessingRolloverSelector(state),
    error: errorSelector(state),
    isSubmitting: loadingSelector(state),
    rolloverSupportFlag,
    accounts,
    shouldNotDisplayDistributionSupport,
  };
};

const mapDispatchToProps = {
  push,
  createSupportRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(SupportPage));
