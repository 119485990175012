import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import StepWizard from "react-step-wizard";
import IconSpinner from "components/IconSpinner";
import StepInfo from "components/StepInfo";
import { getUserProfile, updateUserProfile } from "actions/userActions";
import UserProfileForm from "pages/dashboards/individualDashboard/profile/UserProfileForm";
import UploadDocumentation from "pages/dashboards/individualDashboard/profile/UploadDocumentation";
import { filter, isEqual, map, pick, toLower } from "lodash";
import { ID_DOCUMENT } from "statics/docTypes";
import { createLoadingSelector } from "store/selectors";
import { userConstants } from "actions/types";
import { toast } from "react-toastify";

const stepToLabels = {
  1: {
    subheader: "Update your personal information.",
  },
  2: {
    subheader: "Upload verifying documentation.",
  },
};

const steps = [
  {
    index: 1,
    label: "Update Info",
  },
  {
    index: 2,
    label: "Upload Documentation",
  },
];

class UserProfileWorkflow extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    getUserProfile: PropTypes.func,
    updateUserProfile: PropTypes.func,
    isFetching: PropTypes.bool,
    userInfo: PropTypes.object,
    client: PropTypes.object,
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
    documents: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      initialFetching: true,
      activeStep: "1",
      userInfo: {},
    };
  }

  async componentDidMount() {
    await this.props.getUserProfile(this.props.client);
    this.setState({ initialFetching: false, userInfo: this.props.userInfo });
  }

  submitUserProfile = () => {
    const payload = pick(this.state.userInfo, [
      "legalName",
      "firstName",
      "lastName",
      "address1",
      "address2",
      "city",
      "state",
      "postalCode",
      "phoneNumber",
      "email",
    ]);

    payload.documents = map(this.props.documents, (doc) => {
      return {
        type: ID_DOCUMENT,
        url: doc.docUrl,
        contentType: doc.contentType,
        name: doc.name,
        metadata: { subType: doc.subDocType },
      };
    });

    this.props.updateUserProfile(this.props.client, payload).then(() => {
      toast.success("Profile updated successfully");
      this.props.onSuccess();
    });
  };

  onStepChange = ({ activeStep }) => {
    this.setState({ activeStep });
  };

  updateUserInfo = (userInfo) => {
    this.setState({
      userInfo: {
        ...this.state.userInfo,
        ...userInfo,
      },
    });
  };

  checkIfNameChanged = () => {
    const { userInfo } = this.state;
    const { firstName, lastName } = userInfo;
    return (
      firstName !== this.props.userInfo.firstName ||
      lastName !== this.props.userInfo.lastName
    );
  };

  checkIfPhoneChanged = () => {
    const { userInfo } = this.state;
    const { phoneNumber } = userInfo;
    return phoneNumber !== this.props.userInfo.phoneNumber;
  };

  checkIfAddressChanged = () => {
    const userStateAddress = {
      address1: toLower(this.state.userInfo.address1),
      address2: toLower(this.state.userInfo.address2),
      state: toLower(this.state.userInfo.state),
      postalCode: toLower(this.state.userInfo.postalCode),
      city: toLower(this.state.userInfo.city),
    };

    const userOriginalAddress = {
      address1: toLower(this.props.userInfo.address1),
      address2: toLower(this.props.userInfo.address2),
      state: toLower(this.props.userInfo.state),
      postalCode: toLower(this.props.userInfo.postalCode),
      city: toLower(this.props.userInfo.city),
    };

    return !isEqual(userStateAddress, userOriginalAddress);
  };

  render() {
    const activeSectionLabels = stepToLabels[this.state.activeStep];

    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    const nameWasChanged = this.checkIfNameChanged();
    const addressWasChanged = this.checkIfAddressChanged();
    const phoneWasChanged = this.checkIfPhoneChanged();

    return (
      <>
        <p className="steps-header">{activeSectionLabels.subheader}</p>
        <StepInfo steps={steps} activeStepNumber={this.state.activeStep} />
        <StepWizard
          onStepChange={this.onStepChange}
          isLazyMount={true}
          transitions={{
            enterRight: "",
            enterLeft: "",
            exitRight: "",
            exitLeft: "",
          }}
        >
          <UserProfileForm
            userInfo={this.state.userInfo}
            updateUserInfo={this.updateUserInfo}
            stepName={"updateInfo"}
            onCancel={this.props.onCancel}
          />
          <UploadDocumentation
            userInfo={this.state.userInfo}
            stepName={"uploadDocuments"}
            addressWasChanged={addressWasChanged}
            nameWasChanged={nameWasChanged}
            phoneWasChanged={phoneWasChanged}
            onSubmit={this.submitUserProfile}
            documents={this.props.documents}
            isUpdating={this.props.isFetching}
          />
        </StepWizard>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  userConstants.USER_PROFILE_UPDATE,
]);

const mapStateToProps = (state) => ({
  userInfo: state.user.profile,
  isFetching: loadingSelector(state),
  documents: filter(state.userDocuments.documents, { docType: ID_DOCUMENT }),
});

const mapDispatchToProps = {
  getUserProfile,
  updateUserProfile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(UserProfileWorkflow));
