import React from "react";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import { trim } from "lodash";
import { Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { userService } from "services/userService";
import {
  getUserState,
  updateIraAccountEmploymentPosition,
  updateIraAccountTrustedContact,
  updateIraAccountUserCitizenship,
} from "actions/userActions";

import { withApollo } from "@apollo/client/react/hoc";
import Button from "components/Button";
import Alert from "components/Alert";
import InfoTooltip from "components/InfoTooltip";
import "./IndividualEnroll.scss";
import { countryValues } from "statics/countriesAndStates";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { userConstants } from "actions/types";
import {
  booleanValue,
  NAME_MESSAGE,
  noWhitespaceRegex,
  PRINTABLE_ASCII_MESSAGE,
  testName,
  testPrintableAsciiCharacters,
  whiteSpaceError,
  ALPHANUMERIC_SPACES_MESSAGE,
  testAlphanumericSpaces,
} from "utils/fieldValidators";
import { scrollToTop } from "utils/dom";
import { ScrollToFieldError } from "utils/form";

/* eslint-disable react/no-children-prop */

let yup = require("yup");
const positionRegex = /^[\w\s-,]+$/;

const schema = yup.object({
  isCitizen: yup.boolean().required().label("Citizen of the United States"),
  birthCountry: yup
    .string()
    .test(
      "birth-country",
      PRINTABLE_ASCII_MESSAGE,
      testPrintableAsciiCharacters
    )
    .label("Country you were born in"),
  citizenshipCountry: yup
    .string()
    .test(
      "citizenship-country",
      PRINTABLE_ASCII_MESSAGE,
      testPrintableAsciiCharacters
    )
    .label("Country of citizenship"),
  position: yup
    .string()
    .trim()
    .label("Your Job or Position Title")
    .test("position", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .test("position", ALPHANUMERIC_SPACES_MESSAGE, testAlphanumericSpaces)
    .matches(
      positionRegex,
      "Job title can only contain alphanumeric characters and the following characters: '-', and ','"
    )
    .required(),
  trustedContact: yup.boolean().required().label("Trusted Contact"),
  trustedFirstName: yup
    .string()
    .matches(noWhitespaceRegex, whiteSpaceError)
    .test(
      "trusted-first-name",
      ALPHANUMERIC_SPACES_MESSAGE,
      testAlphanumericSpaces
    )
    .when("trustedContact", {
      is: true,
      then: yup
        .string()
        .required()
        .test("is-valid-first-name", NAME_MESSAGE, testName),
      otherwise: yup
        .string()
        .test("is-valid-first-name", NAME_MESSAGE, testName),
    })
    .label("Contact First Name"),
  trustedLastName: yup
    .string()
    .matches(noWhitespaceRegex, whiteSpaceError)
    .test(
      "trusted-last-name",
      ALPHANUMERIC_SPACES_MESSAGE,
      testAlphanumericSpaces
    )
    .when("trustedContact", {
      is: true,
      then: yup
        .string()
        .required()
        .test("is-valid-last-name", NAME_MESSAGE, testName),
      otherwise: yup
        .string()
        .test("is-valid-last-name", NAME_MESSAGE, testName),
    })
    .label("Contact Last Name"),
  trustedEmail: yup
    .string()
    .matches(noWhitespaceRegex, whiteSpaceError)
    .when("trustedContact", {
      is: true,
      then: yup.string().email().required(),
      otherwise: yup.string(),
    })
    .label("Contact Email"),
});

class IndividualExtraDetails extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    updateIraAccountEmploymentPosition: PropTypes.func,
    updateIraAccountUserCitizenship: PropTypes.func,
    updateIraAccountTrustedContact: PropTypes.func,
    getUserState: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    userState: PropTypes.string,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      employmentPosition: {
        position: "",
      },
      userCitizenship: {
        citizenshipCountry: "",
        permanentResident: false,
        birthCountry: "",
      },
      trustedContact: {
        trustedContact: false,
        trustedFirstName: "",
        trustedLastName: "",
        trustedEmail: "",
      },

      isCitizen: true,
      submitted: false,
      loading: false,
    };

    userService.getIraAccountForm(this.props.client).then(
      (data) => {
        let employmentPosition = {
          position: data.employmentStatus.position,
        };

        let userCitizenship = {
          citizenshipCountry: data.identity.citizenshipCountry,
          permanentResident: false,
          birthCountry: data.identity.birthCountry,
        };

        let trustedContact = {
          trustedContact: data.trustedContact.trustedContact,
          trustedFirstName: data.trustedContact.firstName,
          trustedLastName: data.trustedContact.lastName,
          trustedEmail: data.trustedContact.email,
        };

        if (this._isMounted) {
          this.setState({
            submitted: false,
            trustedContact,
            employmentPosition,
            userCitizenship,
            loading: false,
          });
        }
      },
      () => {
        if (this._isMounted) {
          this.setState({
            submitted: false,
            loading: false,
          });
        }
      }
    );
  }

  componentDidMount() {
    this._isMounted = true;
    scrollToTop();
    window.analytics.page("Individual Information");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submitIndividual = async (values = false) => {
    let employmentPosition = {
      position: trim(values.position),
    };

    let userCitizenship = {
      citizenshipCountry: values.citizenshipCountry,
      permanentResident: false,
      birthCountry: values.birthCountry,
    };

    if (this.state.isCitizen) {
      userCitizenship.citizenshipCountry = "USA";
      userCitizenship.birthCountry = "";
      userCitizenship.permanentResident = false;
    } else {
      userCitizenship.permanentResident = true;
    }

    let trustedContact = {
      trustedContact: booleanValue(values.trustedContact),
      firstName: values.trustedFirstName,
      lastName: values.trustedLastName,
      email: values.trustedEmail,
    };

    const promises = [
      this.props.updateIraAccountEmploymentPosition(
        this.props.client,
        employmentPosition
      ),
      this.props.updateIraAccountUserCitizenship(
        this.props.client,
        userCitizenship
      ),
      this.props.updateIraAccountTrustedContact(
        this.props.client,
        trustedContact
      ),
    ];

    await Promise.all(promises).then(() => {
      this.props.getUserState(this.props.client);
    });

    window.analytics.track("Submitted Individual Information", {
      userState: this.props.userState,
    });

    if (this._isMounted) {
      this.setState({
        submitted: true,
      });
    }
  };

  render() {
    if (this.state.loading) {
      return <div></div>;
    }
    return this.renderExtraDetailsForm();
  }

  renderExtraDetailsForm = () => {
    const { trustedContact, employmentPosition, userCitizenship, isCitizen } =
      this.state;

    var articleStyle = {
      paddingBottom: 35,
    };

    return (
      <div className="individual-extra-details">
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={async (values) => {
            await this.submitIndividual(values);
          }}
          enableReinitialize={true}
          initialValues={{
            ...trustedContact,
            ...employmentPosition,
            ...userCitizenship,
            isCitizen,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div className="mega-container">
                <div
                  className="step-container is-active"
                  data-circle-percent="30"
                >
                  <section className="page-title-wrap">
                    <article className="text-cell">
                      <p className="page-eyebrow">PERSONAL INFO</p>
                      <p className="page-title">Just a few more details.</p>
                      <p className="page-subtext">
                        Last three questions and you are ready to progress.
                      </p>
                    </article>
                  </section>
                  <div id="form-individual-info">
                    <section className="form-sec-2col">
                      <article className="col-form" style={articleStyle}>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={5}
                            controlId="formBasicEmployerPosition"
                          >
                            <Form.Label>What is your job title?</Form.Label>

                            <Form.Control
                              name="position"
                              placeholder=""
                              value={values.position}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              isInvalid={touched.position && !!errors.position}
                              isValid={touched.position && !errors.position}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.position}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </article>
                    </section>

                    <section className="form-sec-2col">
                      <article className="col-form" style={articleStyle}>
                        <Form.Label>
                          Are you a United States citizen?
                        </Form.Label>
                        <Form.Row>
                          <Form.Group as={Col} sm={2}>
                            <Form.Group controlId="isCitizenField">
                              <Form.Control
                                as="select"
                                name="isCitizen"
                                value={values.isCitizen}
                                onChange={(e) => {
                                  setFieldValue(
                                    "isCitizen",
                                    booleanValue(e.target.value)
                                  );
                                }}
                                isInvalid={
                                  touched.isCitizen && !!errors.isCitizen
                                }
                                isValid={touched.isCitizen && !errors.isCitizen}
                                onBlur={handleBlur}
                              >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.isCitizen}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Group>
                        </Form.Row>
                        {values.isCitizen === false ? (
                          <React.Fragment>
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                controlId="citizenshipCountryField"
                                sm={8}
                              >
                                <Form.Label>
                                  Please select your citizenship country:
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  name="citizenshipCountry"
                                  value={values.citizenshipCountry}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  isInvalid={
                                    touched.citizenshipCountry &&
                                    !!errors.citizenshipCountry
                                  }
                                  isValid={
                                    touched.citizenshipCountry &&
                                    !errors.citizenshipCountry
                                  }
                                >
                                  {countryValues.map((item) => (
                                    <option value={item.value} key={item.key}>
                                      {item.description}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.citizenshipCountry}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                sm={8}
                                controlId="birthCountry"
                              >
                                <Form.Label>
                                  Please select the country you were born in:
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  name="birthCountry"
                                  value={values.birthCountry}
                                  onChange={handleChange}
                                  isInvalid={
                                    touched.birthCountry &&
                                    !!errors.birthCountry
                                  }
                                  isValid={
                                    touched.birthCountry && !errors.birthCountry
                                  }
                                  onBlur={handleBlur}
                                >
                                  {countryValues.map((item) => (
                                    <option value={item.value} key={item.key}>
                                      {item.description}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.birthCountry}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Form.Row>
                          </React.Fragment>
                        ) : (
                          <div></div>
                        )}
                      </article>
                    </section>

                    <section className="form-sec-2col">
                      <article className="col-form" style={articleStyle}>
                        <Form.Label>
                          Would you like to add a trusted contact now?{" "}
                          <InfoTooltip
                            tooltipBody="Federal Law lets you specify someone whom we or our financial partners can contact if we are unable to
                        reach you but need to help with something on your
                        account. "
                          />
                        </Form.Label>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={2}
                            F
                            controlId="trustedContact"
                          >
                            <Form.Control
                              as="select"
                              name="trustedContact"
                              value={values.trustedContact}
                              onChange={(e) => {
                                setFieldValue(
                                  "trustedContact",
                                  booleanValue(e.target.value)
                                );
                              }}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.trustedContact &&
                                !!errors.trustedContact
                              }
                              isValid={
                                touched.trustedContact && !errors.trustedContact
                              }
                            >
                              <option value="false">No</option>
                              <option value="true">Yes</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.trustedContact}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        {values.trustedContact === true ? (
                          <React.Fragment>
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                controlId="formTrustedContactFirstName"
                                sm={4}
                              >
                                <Form.Label>Contact First Name</Form.Label>
                                <Form.Control
                                  name="trustedFirstName"
                                  placeholder="First"
                                  value={values.trustedFirstName}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  isInvalid={
                                    touched.trustedFirstName &&
                                    !!errors.trustedFirstName
                                  }
                                  isValid={
                                    touched.trustedFirstName &&
                                    !errors.trustedFirstName
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.trustedFirstName}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                controlId="formTrustedContactLastName"
                                sm={4}
                              >
                                <Form.Label>Contact Last Name</Form.Label>
                                <Form.Control
                                  name="trustedLastName"
                                  placeholder="Last"
                                  value={values.trustedLastName}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  isInvalid={
                                    touched.trustedLastName &&
                                    !!errors.trustedLastName
                                  }
                                  isValid={
                                    touched.trustedLastName &&
                                    !errors.trustedLastName
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.trustedLastName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                sm={8}
                                controlId="formTrustedContactEmail"
                              >
                                <Form.Label>Contact Email Address</Form.Label>
                                <Form.Control
                                  type="email"
                                  name="trustedEmail"
                                  placeholder="contact@gmail.com"
                                  onBlur={handleBlur}
                                  value={values.trustedEmail}
                                  onChange={handleChange}
                                  isInvalid={
                                    touched.trustedEmail &&
                                    !!errors.trustedEmail
                                  }
                                  isValid={
                                    touched.trustedEmail && !errors.trustedEmail
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.trustedEmail}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Form.Row>
                          </React.Fragment>
                        ) : (
                          <div></div>
                        )}
                      </article>
                    </section>
                    <section className="form-sec-2col">
                      <article className="col-form">
                        <div className="submit-row">
                          {this.props.error && (
                            <Alert type="error" msg={this.props.error} />
                          )}
                          <Button
                            name="submit"
                            withArrow={true}
                            loading={this.props.isFetching}
                          />
                        </div>
                      </article>
                    </section>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };
}

const loadingSelector = createLoadingSelector([
  userConstants.USER_ON_BOARDING_EMPLOYMENT_POSITION_UPDATE,
  userConstants.USER_ON_BOARDING_CITIZENSHIP_UPDATE,
  userConstants.USER_ON_BOARDING_TRUSTED_CONTACT_UPDATE,
]);

const errorSelector = createErrorSelector([
  userConstants.USER_ON_BOARDING_EMPLOYMENT_POSITION_UPDATE,
  userConstants.USER_ON_BOARDING_CITIZENSHIP_UPDATE,
  userConstants.USER_ON_BOARDING_TRUSTED_CONTACT_UPDATE,
]);

const mapStateToProps = (state) => ({
  userState: state.user.userState.state,
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = {
  updateIraAccountEmploymentPosition,
  updateIraAccountUserCitizenship,
  updateIraAccountTrustedContact,
  getUserState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualExtraDetails));
