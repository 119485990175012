import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { map, sumBy, includes } from "lodash";
import { FiAnchor, FiUmbrella } from "react-icons/fi";
import { connect } from "react-redux";
import { formatCurrency } from "utils/number";
import { Container, Row, Col, Card } from "react-bootstrap";
import { withApollo } from "@apollo/client/react/hoc";
import { setAccountSession } from "actions/accountActions";
import { auth0AccountSessionSelector } from "store/selectors/auth0";
import { accountTypesToEnglishMapping } from "statics/accountTypes";
import { rolloverStates } from "statics/states";
import {
  IndividualRolloverInfoPending,
  IndividualApplicationInfoComplete,
  IndividualDisclosuresComplete,
  IndividualInvestmentInfoComplete,
  IndividualTermsPending,
} from "statics/states";

import "./IndividualOverview.scss";

class IndividualOverview extends React.PureComponent {
  static propTypes = {
    setAccountSession: PropTypes.func,
    client: PropTypes.object,
    userInfo: PropTypes.object,
    hasBankAccount: PropTypes.bool,
    totalBalance: PropTypes.number,
    activeAccountId: PropTypes.string,
    iraAccounts: PropTypes.array,
    rolloverOnboardingStates: PropTypes.array,
  };

  render() {
    return (
      <>
        <Container id="multi-account-overview">
          <Card className="dashboard-widget balance-kpi">
            <Row>
              <Col>
                <p className="overview-text">
                  Welcome, {this.props.userInfo.firstName}.
                </p>
              </Col>
              <Col style={{ textAlign: "end" }}>
                <p className="overview-text">
                  {formatCurrency(this.props.totalBalance)}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="overview-subtext">
                  Here is an overview of your progress this year.
                </p>
              </Col>
              <Col style={{ textAlign: "end" }}>
                <p className="overview-subtext">Total Icon Balance</p>
              </Col>
            </Row>
          </Card>

          <Card className="dashboard-widget balance-kpi">
            <Row>
              <Col>
                <p className="overview-investments">Investments</p>
              </Col>
              <Col style={{ textAlign: "end" }}>
                <p className="overview-investments">
                  {formatCurrency(this.props.totalBalance)}
                </p>
              </Col>
            </Row>

            {map(this.props.iraAccounts, (account) => {
              const rolloverApplication =
                account.isRollover &&
                includes(this.props.rolloverOnboardingStates, account.state);
              const rolloverInProgress =
                account.isRollover &&
                !account.isProcessingRollover &&
                account.state === rolloverStates.AccountActive;

              const rolloverCompleted = !account.isProcessingRollover;

              const rolloverAccountPendingStates = [
                "AccountApplicationApexAutoAppeal",
                "AccountApplicationApexAutoAppeal",
              ].includes(account.state);

              const processingAccountApplication =
                rolloverCompleted && rolloverAccountPendingStates;

              let status;
              if (rolloverApplication) {
                status = "Resume Rollover Application";
              } else if (processingAccountApplication) {
                status = "Account Application Processing";
              } else if (rolloverInProgress) {
                status = "Rollover In Progress";
              } else if (rolloverCompleted) {
                status = null;
              }
              return (
                <Row
                  key={account.id}
                  className={classnames("account-container", {
                    ["is-selected"]: this.props.activeAccountId === account.id,
                  })}
                  onClick={async () => {
                    await this.props.setAccountSession(
                      this.props.client,
                      account.id
                    );
                    window.location.search = `?redirect_to=/dashboard`;
                  }}
                >
                  <Col>
                    <div className="account-item">
                      <div>
                        <div className="account-circle">
                          <span className="account-icon">
                            {account.accountType === "TRADITIONAL_IRA" ? (
                              <FiAnchor
                                color="white"
                                stroke="#60A4BF"
                                size={16}
                              />
                            ) : (
                              <FiUmbrella
                                color="white"
                                stroke="#60A4BF"
                                size={16}
                              />
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="item-description">
                        <p className="title">
                          {accountTypesToEnglishMapping[account.accountType]}
                        </p>

                        <p className="description">{status}</p>
                      </div>
                    </div>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <p className="account-balance">
                      {formatCurrency(account.balances.totalAccountBalance)}
                    </p>
                  </Col>
                </Row>
              );
            })}
          </Card>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile: userInfo } = state.user;
  const iraAccounts = state.accounts.iraAccounts;
  const accountsWithBalances = iraAccounts.filter(
    (account) => account.balances?.totalAccountBalance
  );
  const totalBalance = sumBy(
    accountsWithBalances,
    "balances.totalAccountBalance"
  );

  const rolloverOnboardingStates = [
    IndividualRolloverInfoPending,
    IndividualApplicationInfoComplete,
    IndividualDisclosuresComplete,
    IndividualInvestmentInfoComplete,
    IndividualTermsPending,
  ];

  return {
    activeAccountId: auth0AccountSessionSelector(state),
    rolloverOnboardingStates,
    totalBalance,
    userInfo,
    iraAccounts,
  };
};

const mapDispatchToProps = { setAccountSession };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualOverview));
