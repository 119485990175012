import moment from "moment";
import { get } from "lodash";

export const shouldReAuthBank = (expiringBanks, bankAccountId) => {
  return expiringBanks.some((expiredBank) => {
    if (expiredBank.bankAccountId !== bankAccountId) {
      return false;
    }

    const expirationDate = get(expiredBank, "expirationDate");
    return (
      expirationDate &&
      moment(expirationDate).isBefore(moment().startOf("day"), "day")
    );
  });
};
