import React, { useEffect } from "react";
import { useAuth0 } from "utils/react-auth0-wrapper";
import queryString from "query-string";

/* purpose is to logout user so they must reverify before validating email change */
const EmailVerificationRedirect = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    window.analytics.reset();
    const verificationId = queryString.parse(
      window.location.search
    ).verificationId;

    logout({
      logoutParams: {
        returnTo:
          window.location.origin +
          "/verify-email?verificationId=" +
          verificationId,
      },
    });
  }, []);

  return <div></div>;
};

export default EmailVerificationRedirect;
