import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { orderFormCompanySizeRange } from "statics/businessCodes";
import { find, get } from "lodash";
import { formatCurrency } from "utils/number";

const discountLengthToLabel = {
  ONE_YEAR: 12,
  TWO_YEARS: 24,
  FOREVER: "ALL",
};

const contractLengthToLabel = {
  MONTHLY: 1,
  ONE_YEAR: 12,
  TWO_YEARS: 24,
  THREE_YEARS: 36,
  FOUR_YEARS: 48,
  FIVE_YEARS: 60,
};

const frequencyLengthToFeePeriod = {
  MONTHLY: 1,
  YEARLY: 12,
  YEARLY_BILLED_MONTHLY: 1,
};

class EmployerSalesQuoteTermsOrder extends React.Component {
  static propTypes = {
    companyName: PropTypes.string,
    companyEIN: PropTypes.string,
    info: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  _content = () => {
    const companyName = this.props.companyName;
    const signerName =
      this.props.info.firstName + " " + this.props.info.lastName;
    const companyEIN = this.props.companyEIN;
    const signerEmail = this.props.info.userEmail;
    const today = moment().format("MMMM Do YYYY");
    const companySize = get(
      this.props.info,
      "salesQuoteData.salesQuote.companySize"
    );

    const {
      setupFee,
      discountPercent,
      discountLength,
      subscriptionFee,
      billingFrequency,
      contractLength,
    } = get(this.props.info, "salesQuoteData", {});
    const { min, max } = find(orderFormCompanySizeRange, (range) => {
      return range.value === companySize;
    });
    const contractLengthInMonths = contractLengthToLabel[contractLength] || 1;
    const recurringFeePeriod =
      frequencyLengthToFeePeriod[billingFrequency] || 1;
    const discountLengthLabel = discountLengthToLabel[discountLength] || 0;
    return (
      <>
        <div className="terms-of-service">
          {/* // Fees */}
          <div>
            <p className="terms-number">1. Fees</p>
            <ol type="a">
              <li>
                This Exhibit A is governed by the terms of the Icon Platform
                Services Agreement. User must have a Platform Services Agreement
                in place, executed by Icon and User.
              </li>
              <li>
                Upon signature by User and submission to Icon, this Exhibit A
                shall become legally binding upon User, unless this Exhibit A is
                rejected by Icon. Icon reserves the right to reject Exhibit A
                for any reason, which includes but is not limited to: (1) the
                signatory below does not have the authority to bind User to this
                Exhibit A, (2) changes have been made to this Exhibit A (other
                than completion of the signature block), or (3) the signature
                block is incomplete or does not match our records or the rest of
                this Exhibit A, or (4) the User does not have a Platform
                Services Agreement in place.
              </li>
              <li>
                The Initial Term of the contract, beginning on the Payment Date,
                shall be for a period of {contractLengthInMonths} month(s).
                Subsequent Terms shall automatically renew for a period of the
                same length as the Initial Term unless User provides written
                notice of termination at least (30) days prior to expiration of
                the current term.
              </li>
              <li>
                If the Company Size has increased by more than 25% between any
                Term, Icon reserves the right to increase the Recurring Fees for
                the Services.
                <p className={"terms-sub-item"}>
                  Number of Employees: {min} to {max}
                </p>
              </li>
              <li>
                Setup Fee for the Services and access to the Platform.
                <p className={"terms-sub-item"}>
                  Setup fee amount: {formatCurrency(setupFee)}
                </p>
              </li>
              <li>
                Recurring Fees for the Services and access to the Platform.
                <p className={"terms-sub-item"}>
                  Recurring fee amount: {formatCurrency(subscriptionFee)}
                </p>
                <p className={"terms-sub-item"}>
                  Recurring fee period: {recurringFeePeriod} month(s)
                </p>
              </li>
              <li>
                Optional Discount to Recurring Fees.
                <p className={"terms-sub-item"}>
                  Discount percent: {discountPercent || 0}%
                </p>
                <p className={"terms-sub-item"}>
                  Discount Length: {discountLengthLabel} month(s)
                </p>
              </li>
              <li>
                Support fee for the Services.
                <p className={"terms-sub-item"}>
                  Employer support: <strong>Included</strong>
                </p>
                <p className={"terms-sub-item"}>
                  Employee support: <strong>Included</strong>
                </p>
              </li>
            </ol>
          </div>
          {/* // Product Terms*/}
          <div>
            <p className="terms-number">2. Product Terms</p>
            <ol type="a">
              <li>
                <strong>Participating Employee</strong> is defined as any
                employee enrolled in Icon that has a linked employer plan to the
                User’s account on the Icon Platform for any amount of time in a
                calendar month.
              </li>
              <li>
                <strong>Company Size</strong> is determined during business
                verification and reflects the total number of W2 and 1099
                workers at the User’s company.
              </li>
              <li>
                <strong>Employer Support</strong> includes Email support during
                normal business hours (9am to 5pm Pacific, M-F, excluding
                Federal Holidays). A dedicated Customer Success agent with
                online and video conference support for initial plan setup and
                administration during the first 60 days of contract term is also
                included.
              </li>
              <li>
                <strong>Employee Support</strong> includes Email and online
                support during normal business hours (9am to 5pm Pacific, M-F,
                excluding Federal Holidays).
              </li>
            </ol>
          </div>

          {/* // Invoicing and Payment */}
          <div>
            <p className="terms-number">3. Invoicing and Payment </p>
            <ol type="a">
              <li>
                Invoiced fees are due net 30 days from the invoice date. User is
                responsible for providing complete and accurate billing and
                contact information to Icon and notifying Icon of any changes to
                such information. Payment must be made digitally via credit card
                or ACH. Initial payment will be invoiced and collected upon the
                Payment Date.
              </li>
            </ol>
          </div>

          {/* // Suspension of Service and Acceleration*/}
          <div>
            <p className="terms-number">
              4. Suspension of Service and Acceleration
            </p>
            <ol type="a">
              <li>
                Terminations prior to the notice period in section 1(c) will
                cause all outstanding fees for the Term to become due. If any
                charge owing by User under this or any other agreement for
                services is 30 days or more overdue, (or 10 or more days overdue
                in the case of amounts User has authorized Icon to charge to
                User’s credit card), Icon may, without limiting its other rights
                and remedies, accelerate User’s unpaid fee obligations under
                such agreements so that all such obligations become immediately
                due and payable, and suspend Services until such amounts are
                paid in full, provided that, other than for customers paying by
                credit card or direct debit whose payment has been declined,
                Icon will give User at least 10 days’ prior notice that its
                account is overdue.
              </li>
            </ol>
          </div>
          <div className="terms-footer">
            <div>
              <p>
                <b>IN WITNESS WHEREOF</b>, the parties, by their duly authorized
                representatives, have executed this Platform Services Agreement
                Exhibit A Order Form as of {today} (the “Effective Date”).
                Payment will begin on {today} (the “Payment Date”).
              </p>
            </div>

            <div className="terms-user-details">
              <span>
                <p className="terms-label">User</p>
                <p>Name: {signerName}</p>
                <p>Email: {signerEmail} </p>
                <p>
                  Company: {companyName} (Tax ID: {companyEIN})
                </p>
              </span>
              <span>
                <p className="terms-label">Icon</p>
                <p>Email: legal@iconsavingsplan.com</p>
                <p>
                  Company: The Icon Savings Corporation (Tax ID: 82-3955531)
                </p>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const content = this._content();
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const companyName = state.employer.company.name;
  const companyEIN = state.employer.company.ein;
  const { info } = ownProps;

  return {
    companyName,
    companyEIN,
    ownProps,
    info,
  };
};

export default connect(mapStateToProps)(EmployerSalesQuoteTermsOrder);
