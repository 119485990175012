import React from "react";
import PropTypes from "prop-types";
import {
  createAndLinkSecondaryAuth,
  linkSecondaryAuth,
} from "actions/userActions";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Auth0Context } from "utils/react-auth0-wrapper";
import IconSpinner from "components/IconSpinner";
import { FcGoogle } from "react-icons/fc";

import { userType } from "statics/propTypes";
import Button from "../../components/Button";
import IndividualSignupForm from "./individual/IndividualSignupForm.js";
import {
  createErrorSelector,
  createLoadingSelector,
} from "../../store/selectors.js";
import { userConstants } from "../../actions/types.js";
import { userEmail } from "../../store/selectors/user.js";

class LinkSecondaryAuth extends React.Component {
  static propTypes = {
    user: userType,
    linkSecondaryAuth: PropTypes.func,
    createAndLinkSecondaryAuth: PropTypes.func,
    client: PropTypes.object,
    isFetching: PropTypes.bool,
    isLinkingSSO: PropTypes.bool,
    error: PropTypes.string,
    userEmail: PropTypes.string,
  };
  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.state = {
      showSignupForm: false,
    };
  }

  authenticateGoogleUser = async () => {
    await this.context.loginWithPopup({
      max_age: 0,
      scope: "openid",
      authorizationParams: {
        max_age: 0,
        scope: "openid",
        connection: "google-oauth2",
      },
    });
    return await this.context.getIdTokenClaims();
  };

  linkAccount = async () => {
    const { sub } = await this.authenticateGoogleUser();
    try {
      await this.props.linkSecondaryAuth(this.props.client, sub);

      if (!this.props.error) {
        window.location.reload();
      }
    } catch (e) {
      this.setState({ auth0Error: e });
    }
  };

  createAndLinkAccount = async (user) => {
    const { email, password, username } = user;
    this.props
      .createAndLinkSecondaryAuth(this.props.client, {
        email,
        password,
        username,
      })
      .then(() => {
        if (!this.props.error) {
          window.location.reload();
        }
      });
  };

  render() {
    return (
      <div>
        {this.props.isFetching && <IconSpinner centered />}
        {!this.props.isFetching && (
          <>
            <div className="btn-row">
              <Button
                name={"submit"}
                btnLabel={"Link with google"}
                onClick={this.linkAccount}
                color="action"
                type={"button"}
                icon={{
                  icon: <FcGoogle color="white" stroke="#60A4BF" size={16} />,
                  position: "left",
                }}
                withArrow={true}
                loading={this.props.isLinkingSSO}
              />
              <Button
                type={"button"}
                name={"action"}
                btnLabel={"Create Icon Login"}
                onClick={() => this.setState({ showSignupForm: true })}
              />
            </div>
          </>
        )}
        {this.state.showSignupForm && (
          <IndividualSignupForm
            error={this.props.error}
            isFetching={this.props.isFetching}
            onSubmit={this.createAndLinkAccount}
            invitationCode={""}
            inviteId={""}
            showInvitationCodeForm={false}
            showTerms={false}
            initialValues={{
              email: this.props.userEmail,
            }}
          />
        )}
      </div>
    );
  }
}

const authActions = [
  userConstants.CREATE_AND_LINK_SECONDARY_AUTH,
  userConstants.LINK_SECONDARY_AUTH,
];

const isFetchingSelector = createLoadingSelector();
const errorSelector = createErrorSelector(authActions);
const isLinkingSSOSelector = createLoadingSelector(
  userConstants.LINK_SECONDARY_AUTH
);
const mapStateToProps = (state, ownProps) => {
  const { user } = state;

  return {
    user,
    ownProps,
    isLinkingSSO: isLinkingSSOSelector(state),
    isFetching: isFetchingSelector(state),
    error: errorSelector(state),
    userEmail: userEmail(state),
  };
};

const mapDispatchToProps = {
  linkSecondaryAuth,
  createAndLinkSecondaryAuth,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(LinkSecondaryAuth));
