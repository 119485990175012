import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { FiChevronDown } from "react-icons/fi";
import { Accordion, Card, Button } from "react-bootstrap";
import IconHeader from "./IconHeader";
import IconSubheader from "./IconSubheader";
import classnames from "classnames";

const rotateChevronStyle = {
  transition: "transform 0.3s ease",
};

const IconInfoCard = ({ sections }) => {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (eventKey) => {
    setOpenSections((prev) => ({
      ...prev,
      [eventKey]: !prev[eventKey],
    }));
  };

  return (
    <Accordion
      defaultActiveKey={sections.length > 0 ? sections[0].key : null}
      className="icon-info-card"
    >
      <Card style={{ border: "none", boxShadow: "none", padding: 30 }}>
        {sections.map((section) => {
          const isOpen = openSections[section.key];

          console.log("isOpen", isOpen);

          return (
            <React.Fragment key={section.key}>
              <Card.Header
                style={{
                  padding: 0,
                }}
              >
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey={section.key}
                  style={{
                    padding: 0,
                    textDecoration: "none",
                    width: "100%",
                  }}
                  onClick={() => toggleSection(section.key)}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      textAlign: "left",
                      paddingBottom: 15,
                    }}
                  >
                    <IconHeader
                      variant="infoCardHeader"
                      headerText={section.title}
                    />
                    <div className={classnames("chevron")}>
                      <FiChevronDown
                        color="white"
                        stroke="#4d4472"
                        size={16}
                        style={{
                          ...rotateChevronStyle,
                          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                        }}
                      />
                    </div>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={section.key}>
                <IconSubheader
                  subheader={section.content}
                  style={{ paddingBottom: 10, fontSize: 14 }}
                />
              </Accordion.Collapse>
            </React.Fragment>
          );
        })}
      </Card>
    </Accordion>
  );
};

IconInfoCard.propTypes = {
  title: PropTypes.string,
  sections: PropTypes.array,
};

export default IconInfoCard;
