import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import { find, get, isEmpty } from "lodash";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Col, Row } from "react-bootstrap";
import { stateRequirementsTable } from "statics/stateTaxRequirements";
import { formatCurrency } from "utils/number";
import { createDisbursementRequest } from "actions/withdrawalActions";
import { withdrawalConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import {
  accountTypesToEnglishMapping,
  distributionTypesToEnglishMapping,
} from "statics/accountTypes";

import Button from "components/Button";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

import "pages/dashboards/DashboardLayout.scss";
import Alert from "components/Alert";

class DistributionSummary extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    push: PropTypes.func,
    createDisbursementRequest: PropTypes.func,
    goToNamedStep: PropTypes.func,
    userState: PropTypes.string,
    selectedAccountType: PropTypes.string,
    distributionAmountSelection: PropTypes.object,
    distributionType: PropTypes.string,
    distributionSpecialReason: PropTypes.string,
    federalTaxSelection: PropTypes.object,
    stateTaxSelection: PropTypes.object,
    isTotalDisbursement: PropTypes.bool,
    accounts: PropTypes.array,
    isUpdating: PropTypes.bool,
    error: PropTypes.string,
    isRothDistribution: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  _submitDistribution = () => {
    const {
      selectedAccountType,
      distributionType,
      distributionSpecialReason,
      federalTaxSelection,
      stateTaxSelection,
      distributionAmountSelection,
      isTotalDisbursement,
    } = this.props;

    const { percentage } = federalTaxSelection;
    const { stateTaxWithholdingAmount, stateTaxWithholdingPercentage } =
      stateTaxSelection;

    const federalTaxPercentage = isEmpty(percentage)
      ? 0
      : parseFloat(percentage);

    const stateTaxPercentage = isEmpty(stateTaxWithholdingPercentage)
      ? 0
      : parseFloat(stateTaxWithholdingPercentage);

    const stateTaxDollarAmount = isEmpty(stateTaxWithholdingAmount)
      ? 0
      : parseFloat(stateTaxWithholdingAmount);

    const disbursementInput = {
      accountId: selectedAccountType,
      distributionType,
      distributionSpecialReason,
      federalTaxWithholdingPercentage: federalTaxPercentage,
      stateTaxWithholdingAmount: stateTaxDollarAmount,
      stateTaxWithholdingPercentage: stateTaxPercentage,
      distributionAmount: distributionAmountSelection.amount,
      isTotalDisbursement,
    };

    this.props
      .createDisbursementRequest(this.props.client, disbursementInput)
      .then(() => {
        if (!this.props.error) {
          toast.success("Successfully processed disbursement request.");
          this.props.push("/dashboard");
        }
      });
  };

  goBackToPreviousStep = () => {
    const { userState } = this.props;

    const stateData = get(stateRequirementsTable, userState);

    const isMandatoryOptOut =
      stateData &&
      stateData.option === "Mandatory Opt Out" &&
      stateData.canOptOut;

    if (isMandatoryOptOut) {
      this.props.goToNamedStep("distributionFederalTax");
    } else {
      this.props.goToNamedStep("distributionStateTax");
    }
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    const {
      selectedAccountType,
      distributionAmountSelection,
      accounts,
      federalTaxSelection,
      stateTaxSelection,
    } = this.props;

    const { percentage } = federalTaxSelection;
    const {
      stateTaxWithholdingAmount,
      stateTaxWithholdingPercentage,
      stateTaxWithholding,
    } = stateTaxSelection;

    const distributionAccount = find(accounts, { id: selectedAccountType });

    const accountType = distributionAccount.accountType;

    const stateTaxType = stateTaxWithholding === "DOLLAR_AMOUNT";
    const stateTaxDollarAmount = isEmpty(stateTaxWithholdingAmount)
      ? "$0"
      : formatCurrency(stateTaxWithholdingAmount);

    const stateTaxPercentage = isEmpty(stateTaxWithholdingPercentage)
      ? "0%"
      : `${stateTaxWithholdingPercentage}%`;

    const distributionStateTax = stateTaxType
      ? stateTaxDollarAmount
      : stateTaxPercentage;

    const distributionFederalTax = isEmpty(percentage)
      ? "0%"
      : `${percentage}%`;

    const distributionType =
      distributionTypesToEnglishMapping[this.props.distributionType];

    return (
      <div id="account-selection">
        <div className="mega-container">
          <section className="page-title-wrap">
            <article className="text-cell">
              <IconHeader
                variant="pageHeader"
                headerText="Please review distribution details"
              />
              <IconSubheader
                variant="subheading"
                subheader="By clicking the withdrawal button, you agree that the information is correct. If you have any questions, please contact our Icon Savings Support team."
              />
            </article>
          </section>
          <div>
            <section
              className="form-sec-2col"
              style={{ paddingBottom: 10, paddingTop: 30 }}
            >
              <article className="col-form" style={articleStyle}>
                <div className="account-types">
                  <Row>
                    <Col>
                      <IconHeader
                        variant="labelHeader"
                        headerText="Distribution Type:"
                      />
                    </Col>
                    <Col>
                      <p className="summary-value">{distributionType}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <IconHeader variant="labelHeader" headerText="Account:" />
                    </Col>
                    <Col>
                      <p className="summary-value">
                        {accountTypesToEnglishMapping[accountType]}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <IconHeader variant="labelHeader" headerText="Amount:" />
                    </Col>
                    <Col>
                      <p className="summary-value">
                        {formatCurrency(distributionAmountSelection.amount)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <IconHeader
                        variant="labelHeader"
                        headerText="Federal Tax:"
                      />
                    </Col>
                    <Col>
                      <p className="summary-value">{distributionFederalTax}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <IconHeader
                        variant="labelHeader"
                        headerText="State Tax:"
                      />
                    </Col>
                    <Col>
                      <p className="summary-value">{distributionStateTax}</p>
                    </Col>
                  </Row>
                </div>
                <div
                  className="tax-penalties"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p style={{ paddingBottom: 10, fontWeight: 500 }}>
                    Please read and agree to the following before submitting
                    your distribution request:
                  </p>{" "}
                  <p>
                    I certify that I am authorized to receive payments from this
                    IRA and that all information provided by me is true and
                    accurate. No tax advice has been given to me by the trustee
                    or custodian. All decisions regarding this withdrawal are my
                    own, and I expressly assume responsibility for any
                    consequences that may arise from this withdrawal. I agree
                    that the trustee or custodian is not responsible for any
                    consequences that may arise from processing this withdrawal
                    authorization.
                  </p>
                </div>

                <p
                  style={{
                    paddingTop: 25,
                    fontStyle: "italic",
                    color: "#0a2540",
                    fontSize: 14,
                  }}
                >
                  After submission an Icon Representative will contact you in
                  the next few business days with a Distribution Form for your
                  signature, and additional instructions.
                </p>
              </article>
            </section>
            {this.props.error && <Alert type="error" msg={this.props.error} />}
            <section className="form-sec-2col">
              <article className="col-form">
                <div className="submit-row btn-row">
                  <Button
                    onClick={this.goBackToPreviousStep}
                    name="cancel"
                    btnLabel="Go Back"
                    color="cancel"
                  />
                  <Button
                    style={{ marginLeft: 10 }}
                    name="submit"
                    btnLabel="Submit"
                    onClick={this._submitDistribution}
                    withArrow
                    disabled={this.props.isUpdating}
                  />
                </div>
              </article>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

const updatingActions = [withdrawalConstants.CREATE_DISBURSEMENT];

const errorSelector = createErrorSelector(updatingActions);
const isUpdating = createLoadingSelector(updatingActions);

const mapStateToProps = (state, ownProps) => {
  const { id, profile: userInfo } = state.user;
  const accounts = state.accounts.iraAccounts;

  return {
    id,
    userInfo,
    ownProps,
    accounts,
    error: errorSelector(state),
    isUpdating: isUpdating(state),
  };
};

const mapDispatchToProps = {
  push,
  createDisbursementRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(DistributionSummary));
