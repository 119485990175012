import React from "react";
import StepWizard from "react-step-wizard";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { PropTypes } from "prop-types";
import { some, find, get } from "lodash";

import IconSpinner from "components/IconSpinner";
import AddressConfirmation from "./AddressConfirmation";
import DistributionAccountType from "./DistributionAccountType";
import DistributionAmount from "./DistributionAmount";
import DistributionType from "./DistributionType";
import DistributionFederalTax from "./DistributionFederalTax";
import DistributionStateTax from "./DistributionStateTax";
import DistributionSummary from "./DistributionSummary";
import DistributionReason from "./DistributionReason";
import DistributionSupport from "./DistributionSupport";

export class DistributionWorkflow extends React.PureComponent {
  static propTypes = {
    userInfo: PropTypes.object,
    accounts: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      selectedAccountType: "",
      distributionAmountSelection: {
        distributionAmount: "",
        amount: "",
      },
      distributionType: "",
      distributionSpecialReason: "",
      federalTaxSelection: {
        federalTaxWithholdingPercentage: "",
        percentage: "",
      },
      stateTaxSelection: {
        stateTaxWithholding: "",
        stateTaxWithholdingPercentage: "",
        stateTaxWithholdingAmount: "",
      },
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: false,
    });
  }

  _onStepChange = ({ activeStep }) => {
    this.setState({ activeStep });
  };

  _handleAccountTypeSelection = (selectedAccountType) => {
    this.setState({ selectedAccountType });
  };

  _handleDistributionAmount = (distributionAmount) => {
    this.setState({
      distributionAmountSelection: {
        ...distributionAmount,
        isTotalDisbursement: distributionAmount.isTotalDisbursement || false,
      },
    });
  };

  _handleDistributionTypeSelection = (distributionType) => {
    this.setState({ distributionType });
  };

  _handleDistributionReasonSelection = (distributionSpecialReason) => {
    this.setState({ distributionSpecialReason });
  };

  handleFederalTaxSelection = (federalTaxSelection) => {
    this.setState({ federalTaxSelection });
  };

  _handleStateTaxSelection = (stateTaxSelection) => {
    this.setState({ stateTaxSelection });
  };

  render() {
    if (this.state.isLoading) {
      return <IconSpinner centered />;
    }

    const accountId = this.state.selectedAccountType;
    const isRothDistribution = some(this.props.accounts, {
      id: accountId,
      accountType: "ROTH_IRA",
    });

    const totalAccountBalance = get(
      find(this.props.accounts, { id: accountId }),
      "balances.totalAccountBalance"
    );

    return (
      <div className="mega-container" id="payroll-workflow">
        <div className="main-content">
          <StepWizard
            showNavigation={false}
            onStepChange={this._onStepChange}
            isLazyMount={true}
            transitions={{
              enterRight: "",
              enterLeft: "",
              exitRight: "",
              exitLeft: "",
            }}
          >
            <AddressConfirmation stepName="addressConfirmation" />
            <DistributionAccountType
              stepName="distributionAccountType"
              onAccountSelect={this._handleAccountTypeSelection}
            />
            <DistributionAmount
              stepName="distributionAmount"
              selectedAccountType={this.state.selectedAccountType}
              distributionAmount={this._handleDistributionAmount}
            />
            <DistributionType
              stepName="distributionType"
              dob={this.props.userInfo.dob}
              accounts={this.props.accounts}
              isRothDistribution={isRothDistribution}
              selectedAccountType={this.state.selectedAccountType}
              onDistributionTypeSelect={this._handleDistributionTypeSelection}
            />
            <DistributionSupport
              stepName="distributionSupport"
              selectedAccountType={this.state.selectedAccountType}
              distributionType={this.state.distributionType}
              distributionAmount={this.state.distributionAmountSelection}
            />
            <DistributionReason
              stepName="distributionReason"
              onDistributionReasonSelect={
                this._handleDistributionReasonSelection
              }
            />
            <DistributionFederalTax
              stepName="distributionFederalTax"
              userState={this.props.userInfo.state}
              isRothDistribution={isRothDistribution}
              federalTaxSelection={this.state.federalTaxSelection}
              onFederalTaxSelection={this.handleFederalTaxSelection}
            />
            <DistributionStateTax
              stepName="distributionStateTax"
              userState={this.props.userInfo.state}
              isRothDistribution={isRothDistribution}
              accountValue={totalAccountBalance}
              withdrawalAmount={this.state.distributionAmountSelection.amount}
              federalTaxSelection={this.state.federalTaxSelection}
              isTotalDisbursement={
                this.state.distributionAmountSelection.isTotalDisbursement
              }
              onStateTaxSelection={this._handleStateTaxSelection}
            />
            <DistributionSummary
              stepName="distributionSummary"
              isRothDistribution={isRothDistribution}
              selectedAccountType={this.state.selectedAccountType}
              distributionAmountSelection={
                this.state.distributionAmountSelection
              }
              distributionType={this.state.distributionType}
              distributionSpecialReason={this.state.distributionSpecialReason}
              federalTaxSelection={this.state.federalTaxSelection}
              stateTaxSelection={this.state.stateTaxSelection}
              isTotalDisbursement={
                this.state.distributionAmountSelection.isTotalDisbursement
              }
            />
          </StepWizard>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id, profile: userInfo } = state.user;
  const accounts = state.accounts.iraAccounts;

  return {
    id,
    userInfo,
    ownProps,
    accounts,
  };
};

export default connect(mapStateToProps)(withApollo(DistributionWorkflow));
