import React from "react";
import classnames from "classnames";
import Collapsible from "react-collapsible";
import { PropTypes } from "prop-types";
import { FiChevronDown } from "react-icons/fi";
import Button from "components/Button";
import "./SimpleAccordion.scss";

const SimpleAccordion = ({ title, onClick, actionText, body }) => {
  return (
    <div className="accordion">
      <Collapsible
        trigger={
          <div className={classnames("accordion-item")}>
            <div className="item">
              <div className="item-description">
                <p className="title">{title}</p>
              </div>
            </div>

            <div className={classnames("trigger")}>
              <FiChevronDown color="white" stroke={"#858c9c"} size={16} />
            </div>
          </div>
        }
      >
        <div className="accordion-body">
          <p className="body">{body}</p>

          {onClick && (
            <div className="accordion-action-btn">
              <Button
                size="sm"
                name="action"
                withArrow={true}
                onClick={onClick}
              >
                {actionText}
              </Button>
            </div>
          )}
        </div>
      </Collapsible>
    </div>
  );
};

SimpleAccordion.propTypes = {
  title: PropTypes.string,
  subtext: PropTypes.string,
  body: PropTypes.func,
  onClick: PropTypes.func,
  actionText: PropTypes.string,
  defaultStyle: PropTypes.bool,
};

export default SimpleAccordion;
