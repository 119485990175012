import gql from "graphql-tag";

/*
  Request to initiate a withdrawal from users IRA
*/
export function makeWithdrawal({
  client,
  iraAccountId,
  amount,
  reason,
  isFullDisbursement,
}) {
  return new Promise((resolve, reject) => {
    const MAKE_WITHDRAWAL = gql`
      mutation makeWithdrawal($withdrawalParams: withdrawalRequest) {
        makeWithdrawal(withdrawalParams: $withdrawalParams)
      }
    `;

    const withdrawalParams = {
      amount,
      isFullDisbursement,
      iraAccountId,
      reason,
    };

    const result = client.mutate({
      mutation: MAKE_WITHDRAWAL,
      variables: {
        withdrawalParams,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function createDisbursementRequest(client, disbursementInput) {
  return new Promise((resolve, reject) => {
    const CREATE_DISBURSEMENT = gql`
      mutation createDisbursementRequest($input: DisbursementInput!) {
        createDisbursementRequest(input: $input)
      }
    `;

    const result = client.mutate({
      mutation: CREATE_DISBURSEMENT,
      variables: {
        input: disbursementInput,
      },
    });

    result.then(
      (data) => {
        resolve(data.data.createDisbursementRequest);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export const withdrawalService = {
  makeWithdrawal,
  createDisbursementRequest,
};
