import React from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Col, Form } from "react-bootstrap";
import { isProduction } from "utils/determineSite";

import Button from "components/Button";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import SelectBox from "../pages/signUp/individual/SelectBox";

const distributionTypes = [
  {
    label: "Federal Withholding Rate",
    key: "CUSTOM",
    description:
      "The default withholding rate is 10%. You can choose to have a different rate by entering a rate between 0% and 100% below. Enter the rate as a whole number (no decimals).",
  },
];

class FederalTax extends React.Component {
  static propTypes = {
    goToNamedStep: PropTypes.func,
    federalTaxWithholdingPercentage: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    federalTaxSelection: PropTypes.object.isRequired,
    isRothDistribution: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      percentage: "",
      federalTaxWithholdingPercentage: "",
    };
  }

  _setFederalTaxWithholdingPercentage = (
    federalTaxWithholdingPercentage,
    setFieldValue
  ) => {
    this.setState({
      federalTaxWithholdingPercentage,
      percentage: "",
    });

    setFieldValue("percentage", "");

    this.props.federalTaxWithholdingPercentage({
      federalTaxWithholdingPercentage,
      percentage: "",
    });
  };

  _onPercentageChange = (event, setFieldValue) => {
    let value = event.target.value;

    if (/^\d*$/.test(value)) {
      setFieldValue("percentage", value);
      this.props.federalTaxWithholdingPercentage({
        federalTaxWithholdingPercentage: "CUSTOM",
        percentage: value,
      });
    }
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    const schema = yup.object({
      percentage: yup
        .number()
        .required("Percentage is required")
        .typeError("Percentage must be a number")
        .integer("Percentage must be a whole number")
        .min(0, "Percentage must be at least 0%")
        .max(100, "Percentage cannot exceed 100%"),
    });

    return (
      <div>
        <div className="mega-container">
          <section className="page-title-wrap">
            <article className="text-cell">
              <IconHeader
                variant="pageHeader"
                headerText="Federal withholding for IRA withdrawals"
              />
              <IconSubheader
                variant="subheading"
                subheader="When you withdraw funds from your IRA, the IRS requires a portion to be withheld for federal taxes. The default rate is 10%, but you can adjust it between 0% and 100% to suit your needs. Specify your preferred withholding amount here."
              />
            </article>
          </section>

          <Formik
            validateOnChange={false}
            validateOnBlur={true}
            validationSchema={schema}
            enableReinitialize={true}
            initialValues={{
              percentage: "",
            }}
          >
            {({ handleBlur, setFieldValue, values, touched, errors }) => (
              <Form noValidate>
                <div>
                  <section
                    className="form-sec-2col"
                    style={{ paddingTop: 20, paddingBottom: 20 }}
                  >
                    <article className="col-form" style={articleStyle}>
                      {this.props.isRothDistribution && (
                        <div
                          className="tax-penalties"
                          style={{ marginBottom: 10 }}
                        >
                          <p>
                            Generally, Roth IRA distributions are not taxable at
                            the federal or state level. Unless you elect to
                            withhold, we will not withhold taxes from your Roth
                            IRA distribution. You should confirm with a tax
                            advisor with specific knowledge of federal and state
                            withholding to determine what is best for your tax
                            situation.
                          </p>
                        </div>
                      )}
                      <div className="account-types">
                        {distributionTypes.map((account) => (
                          <SelectBox
                            label={account.label}
                            description={account.description}
                            key={account.key}
                            items={account.items}
                            ordered={account.ordered}
                            displayChevron={true}
                            onSelect={() =>
                              this._setFederalTaxWithholdingPercentage(
                                account.key,
                                setFieldValue
                              )
                            }
                            isSelected={
                              this.state.federalTaxWithholdingPercentage ===
                              account.key
                            }
                          >
                            <Form.Row as={Col} sm={12}>
                              <Form.Group controlId="formFederalTaxRate">
                                <Form.Control
                                  type="number"
                                  name="percentage"
                                  placeholder="Enter a percentage"
                                  value={values.percentage}
                                  onChange={(e) =>
                                    this._onPercentageChange(e, setFieldValue)
                                  }
                                  onBlur={handleBlur}
                                  isInvalid={
                                    touched.percentage && !!errors.percentage
                                  }
                                  isValid={
                                    touched.percentage && !errors.percentage
                                  }
                                />{" "}
                                <Form.Control.Feedback type="invalid">
                                  {errors.percentage}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Label style={{ padding: 8 }}>
                                % (must be 0 - 100%)
                              </Form.Label>
                            </Form.Row>
                          </SelectBox>
                        ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "#01a3b0",
                          marginTop: 15,
                        }}
                      >
                        <a
                          className="link"
                          href={`https://iconsavingsplan-public.s3-us-west-2.amazonaws.com/${
                            isProduction() ? "production" : "staging"
                          }/documents/2025_Marginal_Rate_Tables.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p
                            className="terms-text"
                            style={{
                              fontStyle: "italic",
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            View 2025 Marginal Rate Table
                          </p>
                        </a>
                      </div>
                    </article>
                  </section>
                  <section className="form-sec-2col">
                    <div className="submit-row btn-row">
                      <Button
                        onClick={this.props.onCancel}
                        btnLabel="Go Back"
                        color="cancel"
                      />
                      <Button
                        onClick={this.props.onNext}
                        btnLabel="Continue"
                        color="primary"
                        disabled={this.props.disabled}
                      />
                    </div>
                  </section>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default FederalTax;
