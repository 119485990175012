import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Modal } from "react-bootstrap";
import { isEmpty } from "lodash";
import { userConstants } from "actions/types";
import { scrollToTop } from "utils/dom";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getUserState, updateSelectedAccountType } from "actions/userActions";
import { getIraAccountIdSelector } from "store/selectors/user";
import { INVESTMENT_PROFILE } from "statics/onboardingSteps";
import {
  rolloverAccountTypesToEnglishMapping,
  accountTypesToEnglishMapping,
} from "statics/accountTypes";
import { FiAlertTriangle } from "react-icons/fi";

import ProgressBar from "components/ProgressBar";
import SelectBox from "./SelectBox";
import Button from "components/Button";
import Alert from "components/Alert";
import CircularProgressBar from "components/CircularProgressBar";
import DisclaimerBox from "components/DisclaimerBox";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import IconCircle from "components/IconCircle";

import "./Individual401kConfirmRolloverAccountType.scss";

const rolloverAccountTypes = [
  {
    label: "Traditional",
    key: "TRADITIONAL_IRA",
    description:
      "A Traditional IRA is pre-tax. Withdrawals are taxed as income.",
  },
  {
    label: "Roth ",
    key: "ROTH_IRA",
    description: "A Roth IRA is post-tax. Qualified withdrawals are tax-free.",
  },
];

class Individual401kPartialRollover extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    getUserState: PropTypes.func,
    updateSelectedAccountType: PropTypes.func,
    isSubmitting: PropTypes.bool,
    error: PropTypes.string,
    userState: PropTypes.string,
    accountId: PropTypes.string,
    accounts: PropTypes.arrayOf(PropTypes.object),
    onCancel: PropTypes.func,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      selectedAccountType: "",
      submitted: false,
      showPartialRolloverConfirmationModal: false,
    };
  }

  componentDidMount() {
    window.analytics.page("Individual Account Type Selection");
    scrollToTop();
  }

  _submitAccountType = () => {
    const accountType = this.state.selectedAccountType;

    this.props
      .updateSelectedAccountType(this.props.client, accountType)
      .then(() => {
        this.props.getUserState(this.props.client);
      });
    window.analytics.track("Submitted Individual Account Type", {
      userState: this.props.userState,
    });

    this.setState({
      submitted: true,
    });
  };

  _setSelectedAccountType = (selectedAccountType) => {
    this.setState({
      selectedAccountType,
    });
  };

  _disclaimerLink = () => {
    return (
      <p>Just a reminder, your 401(k) has both pre-tax and post-tax savings.</p>
    );
  };

  _partialRolloverConfirmationModal = () => {
    const rolloverFundType =
      this.state.selectedAccountType === "TRADITIONAL_IRA"
        ? "pre-tax"
        : "post-tax";

    const subtext = `Your Paychex 401(k) contains both pre-tax and Roth savings. Are you sure you only want to rollover your ${rolloverFundType} savings? `;

    const modalBtnLabel = `Rollover, ${
      rolloverAccountTypesToEnglishMapping[this.state.selectedAccountType]
    } savings only`;

    return (
      <Modal
        centered={true}
        show={this.state.showPartialRolloverConfirmationModal}
        onSuccess={() =>
          this.setState({
            showPartialRolloverConfirmationModal: false,
          })
        }
      >
        <Modal.Body
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconCircle
            type="primary"
            icon={<FiAlertTriangle color="white" stroke="#B12121" size={18} />}
          />
          <IconHeader
            variant="cardHeader"
            headerText="Confirm Partial Rollover"
          />
          <IconSubheader
            subheader={subtext}
            style={{
              textAlign: "center",
            }}
          />
          <div
            className="btn-row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 20,
            }}
          >
            <Button
              color="dismiss"
              btnLabel="Cancel"
              type="button"
              name="cancel"
              onClick={() => {
                this.setState({
                  showPartialRolloverConfirmationModal: false,
                });
              }}
            />
            <Button
              name="submit"
              withArrow={true}
              btnLabel={modalBtnLabel}
              onClick={this._submitAccountType}
              loading={this.props.isSubmitting}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    const btnLabel = this.state.selectedAccountType
      ? `Select ${
          accountTypesToEnglishMapping[this.state.selectedAccountType]
        } Account`
      : "Select Account";

    return (
      <div id="account-selection">
        <ProgressBar
          isEmployer={false}
          activeStepId={INVESTMENT_PROFILE.id}
          progressPercent={"90"}
        />

        {this.state.showPartialRolloverConfirmationModal && (
          <>{this._partialRolloverConfirmationModal()}</>
        )}

        <div className="mega-container">
          <div className="step-container is-active" data-circle-percent="100">
            <section className="page-title-wrap">
              <article className="text-cell">
                <p className="page-eyebrow">YOUR INVESTMENT PROFILE</p>
                <p className="page-title">Select an account to rollover.</p>
              </article>
              <article className="progress-cell">
                <ul className="circular-progress-wrap">
                  <CircularProgressBar
                    strokeWidth="8"
                    sqSize="75"
                    percentage="90"
                  />
                </ul>
              </article>
            </section>
            <div>
              <section className="form-sec-2col">
                <article className="col-form" style={articleStyle}>
                  <div className="account-types">
                    {rolloverAccountTypes.map((account) => (
                      <SelectBox
                        label={account.label}
                        description={account.description}
                        key={account.key}
                        items={account.items}
                        ordered={account.ordered}
                        onSelect={() =>
                          this._setSelectedAccountType(account.key)
                        }
                        isSelected={
                          this.state.selectedAccountType === account.key
                        }
                      />
                    ))}
                  </div>
                  <DisclaimerBox
                    disclaimerText={
                      "Just a reminder, your 401(k) has both pre-tax and post-tax savings."
                    }
                  />
                </article>
              </section>
              <section className="form-sec-2col">
                <article className="col-form">
                  <div className="submit-row">
                    {this.props.error && (
                      <Alert type="error" msg={this.props.error} />
                    )}
                  </div>
                  <div className="btn-row">
                    <Button
                      btnLabel="Go Back"
                      name="cancel"
                      color="cancel"
                      onClick={() => {
                        this.props.onCancel();
                      }}
                    />
                    <Button
                      name="submit"
                      btnLabel={btnLabel}
                      withArrow={true}
                      onClick={() => {
                        this.setState({
                          showPartialRolloverConfirmationModal: true,
                        });
                      }}
                      disabled={isEmpty(this.state.selectedAccountType)}
                    />
                  </div>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actions = [userConstants.UPDATE_SELECTED_ACCOUNT_TYPE];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  const accounts = state.accounts.iraAccounts;
  return {
    isSubmitting: loadingSelector(state),
    error: errorSelector(state),
    userState,
    accountId: getIraAccountIdSelector(state),
    accounts,
  };
};

const mapDispatchToProps = {
  getUserState,
  updateSelectedAccountType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Individual401kPartialRollover));
