import { get, includes, difference, map, defaultTo } from "lodash";

export const bankSelector = (state) => {
  return defaultTo(activeBankSelector(state), get(banksSelector(state), "[0]"));
};

export const banksSelector = (state) => {
  return get(state, "accounts.bankAccounts");
};

export const activeBankSelector = (state) => {
  return get(activeBanksSelector(state), "[0]");
};

export const activeBanksSelector = (state) => {
  return get(state, "accounts.bankAccounts").filter((account) =>
    isBankVerified(account)
  );
};

export const pendingBanksSelector = (state) => {
  return get(state, "accounts.bankAccounts").filter((account) => {
    const pendingStates = [
      "BankAccountPendingAutomaticVerification",
      "BankAccountPendingManualVerification",
    ];
    return includes(pendingStates, get(account, "state"));
  });
};

export const isBankVerifiedSelector = (state) => {
  return isBankVerified(bankSelector(state));
};

const isBankVerified = (bank) => {
  const verifiedStates = [
    "BankAccountActive",
    "BankAccountPendingAchLink",
    "BankAccountVerified",
  ];
  return includes(verifiedStates, get(bank, "state"));
};

export const isPendingManualBankVerification = (bank) => {
  return get(bank, "state") === "BankAccountPendingManualVerification";
};

export const isPendingAutoBankVerification = (bank) => {
  return get(bank, "state") === "BankAccountPendingAutomaticVerification";
};

export const isPendingManualBankVerificationSelector = (state) => {
  return (
    get(bankSelector(state), "state") === "BankAccountPendingManualVerification"
  );
};

export const isPendingAutoBankVerificationSelector = (state) => {
  return (
    get(bankSelector(state), "state") ===
    "BankAccountPendingAutomaticVerification"
  );
};

export const invalidBankIdsLinkedToGroupsSelector = (state) => {
  const associatedBankAccountIds = map(
    state.employer.groups,
    "associatedBankAccount.id"
  );
  const bankAccountIds = map(activeBanksSelector(state), "id");
  return difference(associatedBankAccountIds, bankAccountIds);
};

export const expiringEmployerBankSelector = (state) => {
  return get(state, "accounts.expiringBanks", []);
};

export const expiringIndividualBankSelector = (state) => {
  return get(state, "accounts.expiringIndividualBanks", []);
};
