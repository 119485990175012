import React from "react";
import PropTypes from "prop-types";
import ProgressBar from "components/ProgressBar";
import { Col, Form } from "react-bootstrap";
import { isEmpty } from "lodash";
import TermsModal from "../TermsModal";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  getUserProfile,
  getUserState,
  updateEmployerAccountTermsAndSubmit,
} from "actions/userActions";
import { getCompanyInfo, getSalesQuote } from "actions/employerActions";
import Button from "components/Button";
import Alert from "components/Alert";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { userConstants } from "actions/types";
import { withApollo } from "@apollo/client/react/hoc";
import { isIsolvedWholesale, planCodeSelector } from "store/selectors/employer";
import PlanCard from "./PlanCard";
import IconSpinner from "components/IconSpinner";
import {
  ENTERPRISE,
  salesQuoteCodeRegex,
  version1Keys,
} from "statics/planCodes";
import { AGREEMENTS } from "statics/onboardingSteps";
import { userEmail, userProfile } from "store/selectors/user";
import EmployerAdminNameForm from "components/EmployerAdminNameForm";

class EmployerTerms extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    updateEmployerAccountTermsAndSubmit: PropTypes.func,
    push: PropTypes.func,
    getUserState: PropTypes.func,
    getCompanyInfo: PropTypes.func,
    getSalesQuote: PropTypes.func,
    error: PropTypes.string,
    userId: PropTypes.string,
    planCode: PropTypes.string,
    isFetching: PropTypes.bool,
    isMultiAccount: PropTypes.bool,
    isIsolvedWholesale: PropTypes.bool,
    getUserProfile: PropTypes.func,
    userProfile: PropTypes.object,
    userEmail: PropTypes.string,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      termsOfService: "",
      termsOfOrder: "",
      finalAgreement: "",
      submitted: false,
      showTermsOfService: false,
      showTermsOfOrder: false,
      loading: false,
      fetchingProfile: true,
      evolveAgreement: false,
      showEvolveAgreement: false,
      salesQuoteData: null,
    };
  }

  async componentDidMount() {
    window.analytics.page("Employer Terms");
    let salesQuote = null;
    await Promise.all([
      this.props.getCompanyInfo(this.props.client),
      this.props.getUserProfile(this.props.client),
    ]);

    const isSalesQuote =
      this.props.planCode && salesQuoteCodeRegex.test(this.props.planCode);
    if (isSalesQuote) {
      const { data } = await this.props.getSalesQuote(
        this.props.client,
        this.props.planCode
      );
      salesQuote = data;
    }
    this.setState({ fetchingProfile: false, salesQuoteData: salesQuote });

    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _submitTerms = (e) => {
    e.preventDefault();

    let terms = {
      termsOfService: this.state.termsOfService,
      termsOfOrder: this.state.termsOfOrder,
    };

    this.props
      .updateEmployerAccountTermsAndSubmit(this.props.client, terms)
      .then(() => {
        this.props
          .getUserState(this.props.client)
          .then(() => this.props.push("/dashboard"));
      });

    window.analytics.track("Submitted Terms");

    if (this._isMounted) {
      this.setState({
        submitted: true,
      });
    }
  };

  _handleCheckBoxChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  _shouldShowTermsOfOrder = () => {
    const isSelfServe =
      this.props.planCode && this.props.planCode !== ENTERPRISE;
    const isIsolvedWholesale = this.props.isIsolvedWholesale;
    return isSelfServe || isIsolvedWholesale;
  };

  _calcButtonDisabled = () => {
    const { termsOfService, termsOfOrder, finalAgreement, evolveAgreement } =
      this.state;
    const notAcceptedBasicAgreements =
      !termsOfService || !finalAgreement || !evolveAgreement;

    // we only care about termsOfOrder if we are showing it
    if (this._shouldShowTermsOfOrder()) {
      return notAcceptedBasicAgreements || !termsOfOrder;
    }

    return notAcceptedBasicAgreements;
  };

  getOrderFormType = () => {
    if (this.props.isIsolvedWholesale) {
      return "isolvedOrder";
    }
    const isSalesQuote = salesQuoteCodeRegex.test(this.props.planCode);

    if (isSalesQuote) {
      return "employerSalesQuoteOrder";
    }

    const versionToUse = version1Keys.includes(this.props.planCode)
      ? "employerOrder"
      : "employerOrderV2";

    return versionToUse;
  };

  render() {
    let content;
    const { firstName, lastName } = this.props.userProfile;
    if (this.state.fetchingProfile) {
      content = <IconSpinner centered />;
    } else if (isEmpty(firstName) || isEmpty(lastName)) {
      content = <EmployerAdminNameForm />;
    } else {
      content = this.renderForm();
    }
    const showBillingRouteAndTermsOfOrder = this._shouldShowTermsOfOrder();

    return (
      <div id="employer-terms">
        <ProgressBar
          isEmployer={true}
          activeStepId={AGREEMENTS.id}
          progressPercent={"90"}
          isMultiAccount={this.props.isMultiAccount}
          showBillingRoute={showBillingRouteAndTermsOfOrder}
        />
        {content}
      </div>
    );
  }

  renderForm = () => {
    const showBillingRouteAndTermsOfOrder = this._shouldShowTermsOfOrder();

    const termsInfo = {
      ...this.props.userProfile,
      userEmail: this.props.userEmail,
      salesQuoteData: this.state.salesQuoteData,
    };

    return (
      <div id="employer-terms">
        <TermsModal
          termsInfo={termsInfo}
          type="employerAgreement"
          show={this.state.showTermsOfService}
          onClose={() => this.setState({ showTermsOfService: false })}
        />
        <TermsModal
          termsInfo={termsInfo}
          type={this.getOrderFormType()}
          show={this.state.showTermsOfOrder}
          onClose={() => this.setState({ showTermsOfOrder: false })}
        />
        <TermsModal
          type="evolveAgreement"
          show={this.state.showEvolveAgreement}
          onClose={() => this.setState({ showEvolveAgreement: false })}
        />
        <Form noValidate onSubmit={this._submitTerms}>
          <div className="mega-container">
            <div className="step-container is-active" data-circle-percent="100">
              <section className="form-sec-2col">
                <section className="page-title-wrap">
                  <article className="text-cell">
                    <h1 className="page-title">Agreements</h1>
                    <p className="page-subtext">
                      Please review and acknowledge our{" "}
                      {showBillingRouteAndTermsOfOrder && " Order Form and"}{" "}
                      Platform Services Agreement.
                    </p>

                    <div id="form-employer-company-profile">
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          sm={12}
                          controlId="formBasictermsOfService"
                        >
                          <Form.Check
                            type="checkbox"
                            id="termsOfService"
                            name="termsOfService"
                            label="Platform Services Agreement"
                            value={this.state.termsOfService}
                            onChange={this._handleCheckBoxChange}
                          />
                          <div
                            className="modal-link"
                            onClick={() => {
                              this.setState({
                                showTermsOfService: true,
                              });
                              window.analytics.track(
                                "Viewed Platform Services Agreement"
                              );
                            }}
                          >
                            (View Agreement)
                          </div>
                        </Form.Group>
                      </Form.Row>
                      {showBillingRouteAndTermsOfOrder && (
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={12}
                            controlId="formBasictermsOfOrder"
                          >
                            <Form.Check
                              type="checkbox"
                              id="termsOfOrder"
                              name="termsOfOrder"
                              label="Order Form"
                              value={this.state.termsOfOrder}
                              onChange={this._handleCheckBoxChange}
                            />
                            <div
                              className="modal-link"
                              onClick={() => {
                                this.setState({
                                  showTermsOfOrder: true,
                                });
                                window.analytics.track("Viewed Order Form");
                              }}
                            >
                              (View Order Form)
                            </div>
                          </Form.Group>
                        </Form.Row>
                      )}
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          sm={12}
                          controlId="formBasicEvolveTermsOfService"
                        >
                          <Form.Check
                            type="checkbox"
                            id="evolveAgreement"
                            name="evolveAgreement"
                            label="Evolve Bank & Trust Customer Account Agreement"
                            value={this.state.evolveAgreement}
                            onChange={this._handleCheckBoxChange}
                          />
                          <div
                            className="modal-link"
                            onClick={() => {
                              this.setState({
                                showEvolveAgreement: true,
                              });
                              window.analytics.track("Viewed Terms Of Service");
                            }}
                          >
                            (View Agreement)
                          </div>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          sm={12}
                          controlId="formBasicFinalAgreementSpacing"
                        >
                          {" "}
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          sm={12}
                          controlId="formBasicFinalAgreement"
                        >
                          <Form.Check
                            type="checkbox"
                            id="finalAgreement"
                            name="finalAgreement"
                            label="I Agree"
                            value={this.state.finalAgreement}
                            onChange={this._handleCheckBoxChange}
                          />
                          <p
                            style={{
                              fontStyle: "italic",
                              fontSize: 14,
                              paddingTop: 10,
                              paddingLeft: 4,
                              color: "#0a2540",
                            }}
                          >
                            By checking {'"I Agree"'} you are certifying that
                            you have read and agree to our
                            <a
                              href="https://iconsavingsplan.com/terms"
                              className="modal-link"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms of Service
                            </a>
                            {","}
                            <a
                              href="https://iconsavingsplan.com/privacy"
                              className="modal-link"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>
                            {", "}
                            {showBillingRouteAndTermsOfOrder && " Order Form, "}
                            and Platform Services Agreement.
                          </p>
                        </Form.Group>
                      </Form.Row>

                      <div className="submit-row">
                        {this.props.error && (
                          <Alert type="error" msg={this.props.error} />
                        )}
                        <Button
                          disabled={this._calcButtonDisabled()}
                          withArrow={true}
                          name="submit"
                          btnLabel="Finish"
                          loading={this.props.isFetching}
                        />
                      </div>
                    </div>
                  </article>
                </section>

                <section>
                  <article className="plan-card-bottom">
                    <PlanCard planCode={this.props.planCode} />
                  </article>
                </section>
              </section>
            </div>
          </div>
        </Form>
      </div>
    );
  };
}

const actions = [
  userConstants.USER_EMPLOYER_TERMS_UPDATE,
  userConstants.USER_STATE,
];

const loadingSelector = createLoadingSelector(actions);

const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  isFetching: loadingSelector(state),
  isMultiAccount: state.employer.isMultiAccount,
  planCode: planCodeSelector(state),
  userProfile: userProfile(state),
  userEmail: userEmail(state),
  isIsolvedWholesale: isIsolvedWholesale(state),
});

const mapDispatchToProps = {
  getUserState,
  getCompanyInfo,
  updateEmployerAccountTermsAndSubmit,
  getUserProfile,
  push,
  getSalesQuote,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerTerms));
