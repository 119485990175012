import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Form } from "react-bootstrap";
import { scrollToTop } from "utils/dom";
import { withApollo } from "@apollo/client/react/hoc";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { userConstants, accountConstants } from "actions/types";
import { AGREEMENTS } from "statics/onboardingSteps";
import { userService } from "services/userService";
import { ROTH_IRA } from "statics/accountTypes";
import { toLower } from "lodash";
import {
  newActiveRolloverAccount,
  activeRolloverAccountSameAsCurrent,
  activeRolloverIsMixed,
} from "store/selectors/user";
import {
  getUserState,
  updateIraAccountTermsAndSubmit,
  updateRolloverAccountTermsAndSubmit,
} from "actions/userActions";

import CancelRolloverModal from "../../dashboards/individualDashboard/dashboard/CancelRolloverModal";
import ProgressBar from "components/ProgressBar";
import CircularProgressBar from "components/CircularProgressBar";
import IconSpinner from "components/IconSpinner";
import TermsModal from "pages/signUp/TermsModal";
import Button from "components/Button";
import Alert from "components/Alert";

import "./IndividualEnroll.scss";

class IndividualTerms extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    updateIraAccountTermsAndSubmit: PropTypes.func,
    updateRolloverAccountTermsAndSubmit: PropTypes.func,
    getUserState: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    userState: PropTypes.string,
    isRolloverAccount: PropTypes.bool,
    administrators: PropTypes.array,
    isRolloverSameAsCurrent: PropTypes.bool,
    isRolloverIsMixed: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      showCancelRolloverModal: false,
      accountAgreement: false,
      termsOfService: false,
      evolveAgreement: false,
      iraAgreement: false,
      finalAgreement: false,
      submitted: false,
      showAcctAgreement: false,
      showIraAgreement: false,
      showTermsOfService: false,
      rolloverTerms: false,
      showRolloverTerms: false,
      showEvolveAgreement: false,
      loading: true,
    };
  }

  componentDidMount() {
    userService.getIraAccountForm(this.props.client).then(
      (data) => {
        this.setState({
          accountType: data.account.type,
          loading: false,
        });
      },
      () => {
        this.setState({
          loading: false,
        });
      }
    );
    scrollToTop();
    window.analytics.page("Individual Terms & Agreements");
  }

  submitTerms = () => {
    let terms;

    if (this.props.isRolloverAccount || this.props.isRolloverIsMixed) {
      terms = {
        accountAgreement: this.state.accountAgreement,
        termsOfService: this.state.termsOfService,
        rolloverTerms: this.state.rolloverTerms,
      };

      this.props.updateRolloverAccountTermsAndSubmit(this.props.client, terms);
      window.analytics.track(
        "Submitted Individual Rollover Terms & Agreements"
      );

      this.setState({
        submitted: true,
      });
    } else if (this.props.isRolloverSameAsCurrent) {
      terms = {
        accountAgreement: null,
        termsOfService: null,
        rolloverTerms: this.state.rolloverTerms,
      };

      this.props.updateRolloverAccountTermsAndSubmit(this.props.client, terms);
      window.analytics.track(
        "Submitted Individual Rollover Terms & Agreements"
      );

      this.setState({
        submitted: true,
      });
    } else {
      terms = {
        accountAgreement: this.state.accountAgreement,
        termsOfService: this.state.termsOfService,
      };
      this.props
        .updateIraAccountTermsAndSubmit(this.props.client, terms)
        .then(() => {
          this.props.getUserState(this.props.client);
        });
      window.analytics.track("Submitted Individual Terms & Agreements", {
        userState: this.props.userState,
      });

      this.setState({
        submitted: true,
      });
    }
  };

  calcButtonDisabled = () => {
    const {
      accountAgreement,
      iraAgreement,
      termsOfService,
      finalAgreement,
      rolloverTerms,
      evolveAgreement,
    } = this.state;

    if (this.props.isRolloverSameAsCurrent) {
      return !finalAgreement || !rolloverTerms;
    } else if (this.props.isRolloverAccount) {
      return (
        !accountAgreement ||
        !iraAgreement ||
        !termsOfService ||
        !finalAgreement ||
        !rolloverTerms
      );
    } else {
      return (
        !accountAgreement ||
        !iraAgreement ||
        !termsOfService ||
        !finalAgreement ||
        !evolveAgreement
      );
    }
  };

  _handleCheckBoxChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  _onCancel = () => {
    this.setState({
      showCancelRolloverModal: true,
    });
  };

  _iAgreeTerms = () => {
    let terms;

    if (this.props.isRolloverSameAsCurrent) {
      terms = (
        <>
          <p
            style={{
              fontStyle: "italic",
              fontSize: 14,
              paddingTop: 10,
              paddingLeft: 4,
              color: "#0a2540",
            }}
            className="terms-text"
          >
            By selecting {"I Agree"} you confirm that you have read and
            understood the rules and conditions related to your rollover.
          </p>
        </>
      );
    } else if (this.props.isRolloverAccount) {
      terms = (
        <>
          <p
            style={{
              fontStyle: "italic",
              fontSize: 14,
              paddingTop: 10,
              paddingLeft: 4,
              color: "#0a2540",
            }}
            className="terms-text"
          >
            By checking {"I Agree"} you are certifying that you have read and
            understood the rollover rules and conditions, Apex Customer Account
            Agreement, Apex New IRA Account Application Agreement, Icon Account
            Agreement, as well as the Icon
            <a
              href="https://iconsavingsplan.com/terms"
              className="modal-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            .
          </p>
        </>
      );
    } else {
      terms = (
        <>
          <p
            style={{
              fontStyle: "italic",
              fontSize: 14,
              paddingTop: 10,
              paddingLeft: 4,
              color: "#0a2540",
            }}
            className="terms-text"
          >
            By checking {"I Agree"} you are certifying that you have read and
            understood the Apex Customer Account Agreement, Apex New IRA Account
            Application Agreement, Icon Account Agreement, as well as the Icon
            <a
              href="https://iconsavingsplan.com/terms"
              className="modal-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            .
          </p>
        </>
      );
    }

    return terms;
  };

  render() {
    if (this.state.loading) {
      return <IconSpinner centered />;
    }
    return this.renderForm();
  }

  renderForm = () => {
    var articleStyle = {
      paddingBottom: 0,
    };

    const rolloverTerms =
      this.props.isRolloverAccount ||
      this.props.isRolloverSameAsCurrent ||
      this.props.isRolloverIsMixed;

    const showCancelRollover =
      this.props.isRolloverAccount || this.props.isRolloverSameAsCurrent;

    const accountAgreement =
      toLower(this.state.accountType) === toLower(ROTH_IRA)
        ? "accountAgreementRoth"
        : "accountAgreement";

    const title = this.props.isRolloverSameAsCurrent
      ? "Rules and Conditions"
      : "Terms and Agreements";

    const subtext = this.props.isRolloverSameAsCurrent
      ? "Please review the following IRA contribution rules and conditions, acknowledge them with your e-signature, and then submit your rollover."
      : "Please read the following IRA and partner agreements and e-sign by acknowledging each one then submitting your application";

    const terms = this._iAgreeTerms();

    let progPercent;
    if (this.props.isRolloverAccount) {
      progPercent = "80";
    } else if (this.props.isRolloverSameAsCurrent) {
      progPercent = "60";
    } else {
      progPercent = "95";
    }

    return (
      <div id="individual-terms">
        <ProgressBar
          isEmployer={false}
          activeStepId={AGREEMENTS.id}
          progressPercent={progPercent}
        />

        {this.state.showCancelRolloverModal && (
          <CancelRolloverModal
            show={this.state.showCancelRolloverModal}
            onClose={() =>
              this.setState({
                showCancelRolloverModal: false,
              })
            }
            onSuccess={() =>
              this.setState({
                showCancelRolloverModal: false,
              })
            }
          />
        )}
        <Form noValidate>
          <TermsModal
            type={accountAgreement}
            show={this.state.showAcctAgreement}
            onClose={() => this.setState({ showAcctAgreement: false })}
          />
          <TermsModal
            type="iraAgreement"
            show={this.state.showIraAgreement}
            onClose={() => this.setState({ showIraAgreement: false })}
          />
          <TermsModal
            type="termsOfService"
            show={this.state.showTermsOfService}
            onClose={() => this.setState({ showTermsOfService: false })}
          />
          <TermsModal
            type="rolloverTerms"
            show={this.state.showRolloverTerms}
            onClose={() => this.setState({ showRolloverTerms: false })}
          />
          <TermsModal
            type="evolveAgreement"
            show={this.state.showEvolveAgreement}
            onClose={() => this.setState({ showEvolveAgreement: false })}
          />
          <div className="mega-container">
            <div className="step-container is-active" data-circle-percent="100">
              <section className="page-title-wrap">
                <article className="text-cell">
                  <p className="page-eyebrow">AGREEMENTS</p>
                  <p className="page-title">{title}</p>
                  <p className="page-subtext">{subtext}</p>
                </article>
                <article className="progress-cell">
                  <ul className="circular-progress-wrap">
                    <CircularProgressBar
                      strokeWidth="8"
                      sqSize="75"
                      percentage="90"
                    />
                  </ul>
                </article>
              </section>
              <div id="form-employer-company-profile">
                <section className="form-sec-2col">
                  <article className="col-form" style={articleStyle}>
                    {!this.props.isRolloverSameAsCurrent && (
                      <>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={12}
                            controlId="formBasicAccountAgreement"
                          >
                            <Form.Check
                              type="checkbox"
                              id="accountAgreement"
                              name="accountAgreement"
                              label="Customer Account Agreement"
                              value={this.state.accountAgreement}
                              onChange={this._handleCheckBoxChange}
                            />
                            <div
                              className="modal-link"
                              onClick={() => {
                                this.setState({
                                  showAcctAgreement: true,
                                });
                                window.analytics.track(
                                  "Viewed Account Agreement"
                                );
                              }}
                            >
                              (View Agreement)
                            </div>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={12}
                            controlId="formBasicIraAgreement"
                          >
                            <Form.Check
                              type="checkbox"
                              id="iraAgreement"
                              name="iraAgreement"
                              label="New IRA Account Application Agreement"
                              value={this.state.iraAgreement}
                              onChange={this._handleCheckBoxChange}
                            />
                            <div
                              className="modal-link"
                              onClick={() => {
                                this.setState({
                                  showIraAgreement: true,
                                });
                                window.analytics.track("Viewed IRA Agreement");
                              }}
                            >
                              (View Agreement)
                            </div>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={12}
                            controlId="formBasictermsOfService"
                          >
                            <Form.Check
                              type="checkbox"
                              id="termsOfService"
                              name="termsOfService"
                              label="Icon Account Agreement"
                              value={this.state.termsOfService}
                              onChange={this._handleCheckBoxChange}
                            />
                            <div
                              className="modal-link"
                              onClick={() => {
                                this.setState({
                                  showTermsOfService: true,
                                });
                                window.analytics.track(
                                  "Viewed Terms Of Service"
                                );
                              }}
                            >
                              (View Agreement)
                            </div>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={12}
                            controlId="formBasicEvolveTermsOfService"
                          >
                            <Form.Check
                              type="checkbox"
                              id="evolveAgreement"
                              name="evolveAgreement"
                              label="Evolve Bank & Trust Customer Account Agreement"
                              value={this.state.evolveAgreement}
                              onChange={this._handleCheckBoxChange}
                            />
                            <div
                              className="modal-link"
                              onClick={() => {
                                this.setState({
                                  showEvolveAgreement: true,
                                });
                                window.analytics.track(
                                  "Viewed Terms Of Service"
                                );
                              }}
                            >
                              (View Agreement)
                            </div>
                          </Form.Group>
                        </Form.Row>
                      </>
                    )}

                    {rolloverTerms && (
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          sm={12}
                          controlId="formBasicRolloverTerms"
                        >
                          <Form.Check
                            type="checkbox"
                            id="rolloverTerms"
                            name="rolloverTerms"
                            label="Rollover Account Agreement"
                            value={this.state.rolloverTerms}
                            onChange={this._handleCheckBoxChange}
                          />
                          <div
                            className="modal-link"
                            onClick={() => {
                              this.setState({
                                showRolloverTerms: true,
                              });
                              window.analytics.track(
                                "Viewed Rollover Agreement"
                              );
                            }}
                          >
                            (View Agreement)
                          </div>
                        </Form.Group>
                      </Form.Row>
                    )}
                  </article>
                </section>
                <section className="form-sec-2col">
                  <article className="col-form">
                    <div
                      className="submit-row"
                      style={{
                        borderTop: "1px solid #c7c7c7",
                      }}
                    >
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          sm={12}
                          controlId="formBasicFinalAgreement"
                        >
                          <Form.Check
                            type="checkbox"
                            id="finalAgreement"
                            name="finalAgreement"
                            label="I Agree"
                            value={this.state.finalAgreement}
                            onChange={this._handleCheckBoxChange}
                          />
                          {terms}
                        </Form.Group>
                      </Form.Row>
                      {this.props.error && (
                        <Alert type="error" msg={this.props.error} />
                      )}
                    </div>

                    <div className="submit-row btn-row">
                      {showCancelRollover && (
                        <Button
                          type="button"
                          btnLabel={"Cancel"}
                          color={"cancel"}
                          name="cancel"
                          onClick={this._onCancel}
                        />
                      )}
                      <Button
                        disabled={this.calcButtonDisabled()}
                        withArrow={true}
                        onClick={this.submitTerms}
                        name="submit"
                        btnLabel="Submit"
                        type="button"
                        loading={this.props.isFetching}
                      />
                    </div>
                  </article>
                </section>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  };
}

const actions = [
  userConstants.USER_ON_BOARDING_INVESTMENT_TERMS_UPDATE,
  userConstants.UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT,
  userConstants.USER_STATE,
  accountConstants.GET_ACCOUNTS,
];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    userState: state.user.userState.state,
    error: errorSelector(state),
    isFetching: loadingSelector(state),
    isRolloverAccount: newActiveRolloverAccount(state),
    administrators: state.employer.administrators,
    isRolloverSameAsCurrent: activeRolloverAccountSameAsCurrent(state),
    isRolloverIsMixed: activeRolloverIsMixed(state),
  };
};
const mapDispatchToProps = {
  updateIraAccountTermsAndSubmit,
  updateRolloverAccountTermsAndSubmit,
  getUserState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualTerms));
