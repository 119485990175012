import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUserState, registerIndividualUser } from "actions/userActions";
import { useAuth0 } from "utils/react-auth0-wrapper";
import { useApolloClient } from "@apollo/client";
import { isEmpty } from "lodash";
import { iconIdSelector } from "../store/selectors/auth0";
import queryString from "query-string";
import Alert from "../components/Alert";
import IconSpinner from "../components/IconSpinner";
import PropTypes from "prop-types";

const IndividualLogin = ({ dispatch, userRegistered }) => {
  const { user, isAuthenticated, loading } = useAuth0();
  const client = useApolloClient();
  const [registeringUser, setRegisteringUser] = useState(false);
  const [error, setError] = useState(null);
  const userHasIconAccount = !isEmpty(iconIdSelector(user));

  /*
   * Users from individual portal are actually either registered through Auth0 directly or through
   * an SSO provider which is also stored in Auth0. If the user has come into the application and
   * is authenticated but has no icon account id we can assume they are a new user and need to push
   * them through the registration flow and then get their newly created user state
   *  */
  useEffect(() => {
    const registerUser = async () => {
      if (isAuthenticated) {
        if (!userHasIconAccount) {
          const queryParams = queryString.parse(window.location.search);
          const { inviteId = "", invitationCode = "" } = queryParams;

          setRegisteringUser(true);
          setError(null);

          try {
            return await dispatch(
              registerIndividualUser(client, { inviteId, invitationCode })
            );
          } catch (err) {
            setError(err.message);
          } finally {
            setRegisteringUser(false);
          }
        }
      }
    };

    registerUser();
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (isAuthenticated && !loading && userRegistered) {
      dispatch(getUserState(client, user));
    }
  }, [isAuthenticated, loading, user, userRegistered]);

  if (loading || registeringUser) {
    return <IconSpinner centered />;
  }

  if (error) {
    return <Alert type="error" msg={error} />;
  }

  return <div></div>;
};

IndividualLogin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userState: PropTypes.object,
  userRegistered: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userRegistered: state.user.userRegistered,
});

export default connect(mapStateToProps)(IndividualLogin);
