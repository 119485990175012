import React from "react";
import PropTypes from "prop-types";

const DisclaimerBox = ({ disclaimerText, linkText, href }) => (
  <div className="disclaimer-box">
    <p>{disclaimerText}</p>
    <a
      className="terms-link"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        cursor: "pointer",
        paddingLeft: 3,
      }}
    >
      <p
        className="terms-text"
        style={{
          color: "#1D99A9",
          fontStyle: "italic",
          fontSize: 14,
        }}
      >
        {linkText}
      </p>
    </a>
  </div>
);

DisclaimerBox.propTypes = {
  disclaimerText: PropTypes.string,
  linkText: PropTypes.string,
  href: PropTypes.string,
};

export default DisclaimerBox;
