import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { wrapError } from "utils/errorHelper";
import { Modal, ListGroup } from "react-bootstrap";
import { simulateLinkClickNewTab } from "utils/dom";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getUrlForDocument } from "services/documentService";
import { auth0AccountSessionSelector } from "store/selectors/auth0";
import { FiDownload } from "react-icons/fi";
import { getRolloverInfo } from "actions/userActions";
import { userConstants } from "actions/types";
import { some, isEmpty, find, get } from "lodash";
import { activeRolloverIsMixed } from "store/selectors/user";
import { formatCurrency } from "utils/number";
import { accountTypesToEnglishMapping } from "statics/accountTypes";

import Button from "components/Button";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import PromisePoller from "components/PromisePoller";

import "./RolloverInstructionsModal.scss";
import "./IndividualOverview.scss";

class RolloverInstructionsModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    show: PropTypes.bool,
    client: PropTypes.object.isRequired,
    getRolloverInfo: PropTypes.func,
    error: PropTypes.string,
    rolloverInfo: PropTypes.object,
    accountId: PropTypes.string,
    isProcessingRollover: PropTypes.bool,
    isRolloverIsMixed: PropTypes.bool,
    traditionalId: PropTypes.string,
    rothId: PropTypes.string,
    rothAccountNumber: PropTypes.string,
    traditionalAccountNumber: PropTypes.string,
    accountNumber: PropTypes.string,
    accountType: PropTypes.string,
  };

  _promises = [];

  constructor() {
    super();

    this.state = {
      rolloverInfo: null,
      loading: true,
      error: null,
      documentId: "",
      accountId: "",
      amount: "",
      rothDocId: "",
      rothAccountId: "",
      rothAmount: "",
      traditionalDocumentId: "",
      traditionalAccountId: "",
      traditionalAmount: "",
    };
  }

  componentDidMount() {
    this.verify();
  }

  verify = () => {
    const {
      isRolloverIsMixed,
      client,
      traditionalId,
      rothId,
      accountId,
      getRolloverInfo,
    } = this.props;

    if (isRolloverIsMixed) {
      return Promise.all([
        getRolloverInfo(client, traditionalId)
          .then((response) => {
            const data = response.data;
            const {
              docId: traditionalDocumentId,
              accountId: traditionalAccountId,
              amount: traditionalAmount,
            } = data;
            this.setState({
              traditionalDocumentId,
              traditionalAccountId,
              traditionalAmount,
            });
          })
          .catch((error) => {
            console.error("Error fetching rollover info:", error);
          }),
        getRolloverInfo(client, rothId)
          .then((response) => {
            const data = response.data;
            const {
              docId: rothDocId,
              accountId: rothAccountId,
              amount: rothAmount,
            } = data;
            this.setState({ rothDocId, rothAccountId, rothAmount });
          })
          .catch((error) => {
            console.error("Error fetching rollover info:", error);
          }),
      ]);
    } else {
      return getRolloverInfo(client, accountId)
        .then((response) => {
          const data = response.data;
          const {
            docId: documentId,
            accountId: accountId,
            amount: amount,
          } = data;
          this.setState({ documentId, accountId, amount });
        })
        .catch((error) => {
          console.error("Error fetching rollover info:", error);
        });
    }
  };

  _downloadDoc() {
    const {
      traditionalDocumentId,
      traditionalAccountId,
      traditionalAmount,
      rothDocId,
      rothAccountId,
      rothAmount,
      documentId,
      accountId,
      amount,
    } = this.state;

    const {
      traditionalAccountNumber,
      rothAccountNumber,
      accountNumber,
      accountType,
    } = this.props;

    const mixedRolloverAccounts = [
      {
        index: 1,
        accountId: traditionalAccountId,
        accountType: "Traditional Rollover Form ",
        accountNumber: traditionalAccountNumber,
        amount: traditionalAmount,
        documentId: traditionalDocumentId,
      },
      {
        index: 2,
        accountId: rothAccountId,
        accountType: "Roth Rollover Form",
        accountNumber: rothAccountNumber,
        amount: rothAmount,
        documentId: rothDocId,
      },
    ];

    const rolloverAccount = [
      {
        index: 1,
        accountId,
        accountType,
        accountNumber,
        amount,
        documentId,
      },
    ];

    const activeRolloverAccounts = this.props.isRolloverIsMixed
      ? mixedRolloverAccounts
      : rolloverAccount;

    return (
      <>
        {activeRolloverAccounts.map((account) => (
          <div
            key={account.key}
            className="rollover-link"
            onClick={() =>
              getUrlForDocument(
                this.props.client,
                account.accountId,
                account.documentId
              )
                .then((response) => {
                  simulateLinkClickNewTab(response.url);
                })
                .catch((error) => {
                  this.setState({ error: wrapError(error) });
                })
            }
          >
            <div className="rollover-item">
              <div className="rollover-details">
                <div className="detail-col">
                  <p className="detail">
                    <span style={{ paddingRight: 10 }}>
                      <FiDownload color="white" stroke="#60A4BF" size={22} />
                    </span>{" "}
                    {this.props.isRolloverIsMixed
                      ? account.accountType
                      : accountTypesToEnglishMapping[account.accountType]}
                  </p>
                  <div className="detail">{formatCurrency(account.amount)}</div>
                </div>

                <p className="account-number">
                  Account No: {account.accountNumber}
                </p>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  _buildRolloverSteps = () => {
    const { isRolloverIsMixed } = this.props;

    const isMixedRolloverDocID =
      !isEmpty(this.state.rothDocId) &&
      !isEmpty(this.state.traditionalDocumentId);

    const showDocument = isRolloverIsMixed
      ? isMixedRolloverDocID
      : !isEmpty(this.state.documentId);

    return (
      <ListGroup as="ol" numbered className="custom-list-group">
        <ListGroup.Item as="li">
          1. Make check payable to: “Apex Clearing Corporation, FBO [Your Name]”
        </ListGroup.Item>
        <ListGroup.Item as="li">
          2. Please download and sign your rollover form in black ink at the
          bottom. Write your IRA account number on your check, then mail both
          the signed form and check together to complete your rollover. <br />
          {showDocument && this.props.isProcessingRollover ? (
            <>{this._downloadDoc()}</>
          ) : (
            <>
              <PromisePoller
                interval={4000}
                minutesToPollFor={10}
                promiseToPoll={this.verify}
              />
              <p className="doc-text">
                Your document is being generated. You will receive an email
                notification when it is ready!
              </p>
            </>
          )}
          <div
            style={{
              padding: 20,
              marginBottom: 20,
              background: "#F7FEFF",
              borderRadius: 5,
            }}
          >
            Some plan administrators will only mail the check to your address.
            If your account information is not on the check, please hand-write
            it on the check and forward it to the Apex Clearing Corporation, FBO
            Your Name address.
          </div>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          3. Send the check to the address below:
          <div style={{ marginLeft: 15, paddingTop: 5 }}>
            <address>
              Apex Clearing Corporation
              <br />
              Attn. Banking Department
              <br />
              350 North St. Paul Street, Suite 1300
              <br />
              Dallas, Texas 75201
            </address>
          </div>
        </ListGroup.Item>
      </ListGroup>
    );
  };

  render() {
    const steps = this._buildRolloverSteps();

    if (this.props.show) {
      return (
        <Modal
          size="lg"
          centered
          scrollable
          show={this.props.show}
          className="rollover-modal"
        >
          <Modal.Header className="modal-header">
            <IconHeader
              variant="cardHeader"
              headerText="Complete your rollover! "
            />
            <IconSubheader subheader="To finish rolling over your account, contact your plan provider and request a rollover. Try calling the phone number on your statement and provide them the instructions below." />
          </Modal.Header>
          <Modal.Body>{steps}</Modal.Body>
          <Modal.Footer>
            <Button
              name="cancel"
              btnLabel="Done"
              onClick={() => this.props.onClose()}
            />
          </Modal.Footer>
        </Modal>
      );
    }
    return null;
  }
}

const actions = [userConstants.GET_ROLLOVER_INFO];

const errorSelector = createErrorSelector(actions);
const loadingSelector = createLoadingSelector(actions);

const mapStateToProps = (state) => {
  const accounts = state.accounts.iraAccounts;
  const activeAccountId = auth0AccountSessionSelector(state);
  const account = find(accounts, { isProcessingRollover: true });

  const id = account ? account.id : null;
  const accountId = isEmpty(activeAccountId) ? id : activeAccountId;

  //Getting values for a single account
  const rolloverAccountById = find(accounts, {
    id: accountId,
  });
  const accountNumber = get(rolloverAccountById, "accountNumber", null);
  const accountType = get(rolloverAccountById, "accountType", null);

  // Getting Mixed accounts and values
  const traditionalRollover = find(accounts, {
    isProcessingRollover: true,
    accountType: "TRADITIONAL_IRA",
    state: "AccountActive",
  });
  const rothRollover = find(accounts, {
    isProcessingRollover: true,
    accountType: "ROTH_IRA",
    state: "AccountActive",
  });
  const rothId = get(rothRollover, "id", null);
  const rothAccountNumber = get(rothRollover, "accountNumber", null);
  const traditionalId = get(traditionalRollover, "id", null);
  const traditionalAccountNumber = get(
    traditionalRollover,
    "accountNumber",
    null
  );

  //Check to make sure if is mixed rollover has both accounts active || if single account display it
  const isRolloverIsMixed = activeRolloverIsMixed(state);
  const mixedRolloverIsProcessing =
    !isEmpty(rothRollover) && !isEmpty(traditionalRollover);

  const accountByIdIsProcessing = some(accounts, {
    isProcessingRollover: true,
    state: "AccountActive",
  });
  const isProcessingRollover = isRolloverIsMixed
    ? mixedRolloverIsProcessing
    : accountByIdIsProcessing;

  return {
    accountId,
    accountNumber,
    accountType,
    traditionalId,
    rothId,
    rothAccountNumber,
    traditionalAccountNumber,
    isProcessingRollover,
    error: errorSelector(state),
    loading: loadingSelector(state),
    isRolloverIsMixed,
  };
};

const mapDispatchToProps = {
  getRolloverInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(RolloverInstructionsModal));
