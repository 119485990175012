import { portfolioConstants } from "./types";
import {
  getPortfolios,
  updateUserPortfolio,
  getActivePortfolio,
  getPositions,
  getPerformance,
  addRolloverAccountPortfolio,
} from "services/portfolioService";
import ActionCreator from "utils/actionHandler";

export const getAllPortfolios = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(portfolioConstants.GET_PORTFOLIOS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getPortfolios(client, accountId)
    );
  };
};

export const getUserPortfolio = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      portfolioConstants.GET_USER_PORTFOLIO
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getActivePortfolio(client, accountId)
    );
  };
};

export const selectUserPortfolio = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      portfolioConstants.UPDATE_USER_PORTFOLIO
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      updateUserPortfolio(client, params)
    );
  };
};

export const getUserPositions = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(portfolioConstants.GET_POSITIONS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getPositions(client, accountId)
    );
  };
};

export const getUserPerformance = (client, accountId, startTime, endTime) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(portfolioConstants.GET_PERFORMANCE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getPerformance(client, accountId, startTime, endTime)
    );
  };
};

export const rolloverAccountPortfolio = (client, portfolioId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      portfolioConstants.SELECT_USER_ROLLOVER_PORTFOLIO
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      addRolloverAccountPortfolio(client, portfolioId)
    );
  };
};

export default {
  getAllPortfolios,
  getUserPositions,
  getUserPerformance,
  rolloverAccountPortfolio,
};
