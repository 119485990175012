import React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { PropTypes } from "prop-types";
import { toNumber, round } from "lodash";

import StateTax from "components/StateTax";

export class DistributionStateTax extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    push: PropTypes.func,
    userInfo: PropTypes.object,
    goToNamedStep: PropTypes.func,
    onNext: PropTypes.func,
    withdrawalAmount: PropTypes.number,
    userState: PropTypes.string,
    stateTaxWithholdingChange: PropTypes.func,
    onStateTaxSelection: PropTypes.func,
    isRothDistribution: PropTypes.bool,
    accountValue: PropTypes.number,
    isTotalDisbursement: PropTypes.bool,
    federalTaxSelection: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      stateTaxWithholding: null,
      stateTaxWithholdingPercentage: null,
      stateTaxWithholdingAmount: null,
    };
  }

  handleStateTaxChange = (stateTaxData) => {
    this.setState(stateTaxData);
    if (this.props.stateTaxWithholdingChange) {
      this.props.stateTaxWithholdingChange(stateTaxData);
    }

    this.props.onStateTaxSelection(stateTaxData);
  };

  handleCancel = () => {
    this.props.goToNamedStep("distributionFederalTax");
  };

  goToNextStep = () => {
    this.props.goToNamedStep("distributionSummary");
  };

  calculateFederalTax = () => {
    const { withdrawalAmount, federalTaxSelection } = this.props;
    const { percentage } = federalTaxSelection;

    const percentageNumber = toNumber(percentage);

    if (!(percentageNumber > 0)) {
      return 0;
    }

    return round((percentageNumber / 100) * withdrawalAmount, 2);
  };

  render() {
    return (
      <StateTax
        accountValue={this.props.accountValue}
        federalWithholdingAmount={this.calculateFederalTax()}
        isTotalDisbursement={this.props.isTotalDisbursement}
        isRothDistribution={this.props.isRothDistribution}
        federalTaxSelection={this.props.federalTaxSelection}
        stateTaxWithholding={this.state.stateTaxWithholding}
        stateTaxWithholdingPercentage={this.state.stateTaxWithholdingPercentage}
        stateTaxWithholdingAmount={this.state.stateTaxWithholdingAmount}
        stateTaxWithholdingChange={this.handleStateTaxChange}
        withdrawalAmount={this.props.withdrawalAmount}
        userState={this.props.userInfo.state}
        goToNamedStep={this.props.goToNamedStep}
        onCancel={this.handleCancel}
        onNext={this.goToNextStep}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id, profile: userInfo } = state.user;

  return {
    id,
    userInfo,
    ownProps,
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(DistributionStateTax));
