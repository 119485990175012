import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import "./PlanCard.scss";
import IconSpinner from "components/IconSpinner";
import { getSalesQuote } from "actions/employerActions";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { formatCurrency } from "utils/number";

const discountLengthToEnglish = {
  FOREVER: "forever",
  ONE_YEAR: "1 year",
  TWO_YEARS: "2 years",
};

const frequencyLengthToEnglish = {
  MONTHLY: "month",
  YEARLY: "year",
  YEARLY_BILLED_MONTHLY: "month",
};

class SalesQuoteCard extends React.PureComponent {
  static propTypes = {
    planCode: PropTypes.string,
    client: PropTypes.object,
    getSalesQuote: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      isFetching: true,
      salesQuote: null,
    };
  }

  async componentDidMount() {
    const { data } = await this.props.getSalesQuote(
      this.props.client,
      this.props.planCode
    );

    this.setState({
      isFetching: false,
      salesQuote: data,
    });
  }

  buildLabels() {
    const {
      setupFee,
      subscriptionFee,
      discountPercent,
      discountLength,
      billingFrequency,
    } = this.state.salesQuote;

    // discountPercent is a whole percentage vs decimal so divide by 100
    const actualSubFee = discountPercent
      ? subscriptionFee - subscriptionFee * (discountPercent / 100)
      : subscriptionFee;

    const subscriptionFeePrice = formatCurrency(actualSubFee, 2);
    const setupFeePrice = setupFee ? formatCurrency(setupFee, 0) : null;

    const discountLengthLabel =
      discountLength && discountLength !== "FOREVER"
        ? `for ${discountLengthToEnglish[discountLength]}`
        : null;

    const discountLabel = discountPercent
      ? `${discountPercent}% Discount applied ${
          discountLengthLabel ? discountLengthLabel : ""
        }`
      : null;
    return {
      pricingLabel: `${subscriptionFeePrice}/${frequencyLengthToEnglish[billingFrequency]}`,
      setupFeeLabel: setupFeePrice
        ? `${setupFeePrice} one-time setup fee`
        : null,
      discountLabel,
    };
  }

  render() {
    if (this.state.isFetching) {
      return (
        <Card className="plan-card">
          <IconSpinner centered />
        </Card>
      );
    }

    if (!this.state.salesQuote) {
      return null;
    }

    const { setupFeeLabel, pricingLabel, discountLabel } = this.buildLabels();

    return (
      <Card className="plan-card">
        <div className="size-heading">
          <p className="size"></p>
          <p className="employee-label">Subscription Quote</p>
        </div>
        <div className="pricing-section">
          <p className="pricing">{pricingLabel}</p>
          {discountLabel && <p className="discount-label">{discountLabel}</p>}
          {setupFeeLabel && <p className="setup-fee">{setupFeeLabel}</p>}
        </div>
      </Card>
    );
  }
}

const mapDispatchToProps = {
  getSalesQuote,
};

export default connect(null, mapDispatchToProps)(withApollo(SalesQuoteCard));
