import React from "react";
import { get } from "lodash";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { PropTypes } from "prop-types";
import { stateRequirementsTable } from "statics/stateTaxRequirements";

import FederalTax from "components/FederalTax";

export class DistributionFederalTax extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    push: PropTypes.func,
    userInfo: PropTypes.object,
    goToNamedStep: PropTypes.func,
    onNext: PropTypes.func,
    userState: PropTypes.string,
    federalTaxSelection: PropTypes.object,
    onFederalTaxSelection: PropTypes.func,
    isRothDistribution: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      federalTaxSelection: {
        federalTaxWithholdingPercentage: "",
        percentage: "",
      },
    };
  }

  handleFederalTaxWithholdingPercentage = ({
    federalTaxWithholdingPercentage,
    percentage,
  }) => {
    this.props.onFederalTaxSelection({
      federalTaxWithholdingPercentage,
      percentage,
    });
  };

  handleCancel = () => {
    if (this.props.isRothDistribution) {
      this.props.goToNamedStep("distributionType");
    } else {
      this.props.goToNamedStep("distributionReason");
    }
  };

  goToNextStep = () => {
    const { userState } = this.props;
    const stateData = get(stateRequirementsTable, userState);

    const isMandatoryOptOut =
      stateData &&
      stateData.option === "Mandatory Opt Out" &&
      stateData.canOptOut;

    if (isMandatoryOptOut) {
      this.props.goToNamedStep("distributionSummary");
    } else {
      this.props.goToNamedStep("distributionStateTax");
    }
  };

  render() {
    const { federalTaxSelection } = this.props;
    const { federalTaxWithholdingPercentage, percentage } = federalTaxSelection;

    const isButtonDisabled = !federalTaxWithholdingPercentage || !percentage;

    return (
      <FederalTax
        isRothDistribution={this.props.isRothDistribution}
        federalTaxSelection={this.state.federalTaxSelection}
        federalTaxWithholdingPercentage={
          this.handleFederalTaxWithholdingPercentage
        }
        onCancel={this.handleCancel}
        onNext={this.goToNextStep}
        disabled={isButtonDisabled}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id, profile: userInfo } = state.user;

  return {
    id,
    userInfo,
    ownProps,
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(DistributionFederalTax));
