import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import Alert from "components/Alert";
import Button from "components/Button";
import "./IndividualEnroll.scss";

import {
  noWhitespaceRegex,
  testUsername,
  whiteSpaceError,
} from "utils/fieldValidators";
import * as yup from "yup";
import { ScrollToFieldError } from "utils/form";
import { invitationCodeParser } from "../../../utils/fieldValidators";

const schema = yup.object({
  username: yup
    .string()
    .label("Username")
    .required()
    .min(3, "Must be at least three characters")
    .max(25)
    .matches(noWhitespaceRegex, whiteSpaceError)
    .test("username", "Invalid username.", testUsername),
  email: yup
    .string()
    .label("Email")
    .email("You must enter a valid email address")
    .matches(noWhitespaceRegex, whiteSpaceError)
    .max(40)
    .required(),
  password: yup
    .string()
    .label("Password")
    .required()
    .min(8, "Seems a bit short...")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Must be at least 8 characters long with one uppercase, one lowercase, one number and one special character(e.g. #?!@$%^&*- )"
    ),
  confirmPassword: yup
    .string()
    .required()
    .label("Confirm password")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  invitationCode: yup.string().label("Invitation Code").notRequired(),
});

class IndividualSignupForm extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    onSubmit: PropTypes.func,
    invitationCode: PropTypes.string,
    inviteId: PropTypes.string,
    showInvitationCodeForm: PropTypes.bool,
    showTerms: PropTypes.bool,
    initialValues: PropTypes.object,
  };

  static defaultProps = {
    showInvitationCodeForm: true,
    showTerms: true,
  };

  submitUser = (values) => {
    let user = {
      username: values.username,
      email: values.email,
      password: values.password,
      role: "Individual",
      invitationCode: values.invitationCode,
      inviteId: this.props.inviteId,
    };

    this.props.onSubmit(user);
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    const disablePlanID = !!this.props.invitationCode;

    return (
      <div>
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={(values) => {
            this.submitUser(values);
          }}
          initialValues={{
            email: "",
            password: "",
            confirmPassword: "",
            username: "",
            invitationCode: this.props.invitationCode,
            ...this.props.initialValues,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div className="mega-container">
                <div
                  className="step-container is-active"
                  data-circle-percent="10"
                >
                  <div id="form-employer-profile">
                    <section className="form-sec-2col">
                      <article className="col-form" style={articleStyle}>
                        <Form.Group controlId="formBasicUsername">
                          <Form.Control
                            name="username"
                            placeholder="Username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.username && !!errors.username}
                            isValid={touched.username && !errors.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Personal Email Address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.email && !!errors.email}
                            isValid={touched.email && !errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.password && !!errors.password}
                            isValid={touched.password && !errors.password}
                          />
                          <Form.Control.Feedback className="valid-account-creation">
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicPasswordConfirm">
                          <Form.Control
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.confirmPassword &&
                              !!errors.confirmPassword
                            }
                            isValid={
                              touched.confirmPassword && !errors.confirmPassword
                            }
                          />
                          <Form.Control.Feedback className="valid-account-creation">
                            Passwords match!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword}
                          </Form.Control.Feedback>
                        </Form.Group>

                        {this.props.showInvitationCodeForm && (
                          <Form.Group
                            sm={4}
                            controlId="formBasicInvitationCode"
                          >
                            <Form.Control
                              disabled={disablePlanID}
                              name="invitationCode"
                              value={values.invitationCode}
                              placeholder="Plan ID"
                              onBlur={handleBlur}
                              onChange={(event) => {
                                const value = invitationCodeParser(
                                  event,
                                  values.invitationCode
                                );
                                setFieldValue("invitationCode", value);
                              }}
                              isInvalid={
                                touched.invitationCode &&
                                !!errors.invitationCode
                              }
                              // isValid={!errors.invitationCode}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.invitationCode}
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                      </article>
                    </section>

                    <section className="form-sec-2col">
                      <article className="col-form">
                        {this.props.showTerms && (
                          <p
                            style={{
                              fontStyle: "italic",
                              fontSize: 14,
                              paddingTop: 10,
                              paddingLeft: 4,
                              color: "#0a2540",
                            }}
                          >
                            By pressing the Continue button to create your login
                            you are certifying that you have read and agree to
                            our{" "}
                            <a
                              href="https://iconsavingsplan.com/terms"
                              className="modal-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#01a3b0",
                              }}
                            >
                              Terms of Service
                            </a>
                            .
                          </p>
                        )}
                        {this.props.error && (
                          <Alert type="error" msg={this.props.error} />
                        )}
                        <div className="submit-row">
                          <Button
                            name="submit"
                            withArrow={true}
                            loading={this.props.isFetching}
                          />
                        </div>
                      </article>
                    </section>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default IndividualSignupForm;
