import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Col, Row, Card, Container } from "react-bootstrap";
import { get, isEmpty, size, each, some } from "lodash";
import { transactionType } from "statics/propTypes";
import { createLoadingSelector, createErrorSelector } from "store/selectors";
import {
  getIraAccountIdSelector,
  isProcessingRolloverSelector,
  activeIdIsProcessingRollover,
  activeRolloverIsMixed,
  userIsLockedSelector,
  isIndividualAccountOverview,
} from "store/selectors/user";
import { getAllDocuments } from "actions/documentActions";
import { getAllAccounts } from "actions/accountActions";
import { getTransactions } from "actions/transactionActions";
import {
  isBankVerifiedSelector,
  bankSelector,
  expiringIndividualBankSelector,
} from "store/selectors/bank";
import { contributionAbilitySelector } from "store/selectors/contribution";
import {
  getEmployerLinkRequests,
  getLinkedEmployers,
} from "actions/userActions";
import {
  accountConstants,
  userConstants,
  transactionConstants,
  contributionConstants,
  bankConstants,
} from "actions/types";
import {
  getUserRecurringContributions,
  getContributionAbility,
} from "actions/contributionActions";
import { rolloverStates } from "statics/states";
import { shouldReAuthBank } from "utils/reAuthDateValidation";
import { auth0AccountSessionSelector } from "store/selectors/auth0";
import { userIs401kRollIn, activeAccountType } from "store/selectors/user";
import {
  hasBothPreTaxAndRothFundsSelector,
  hasPreTaxFundsSelector,
  hasRothFundsSelector,
} from "store/selectors/auth0";

import Alert from "components/Alert";
import moment from "moment";
import PerformanceTable from "./PerformanceTable";
import BalanceKpi from "./BalanceKpi";
import PriorYearContributionCard from "./PriorYearContributionCard";
import PendingClosure from "pages/PendingClosure";
import SupportEmailLink from "components/SupportEmailLink";
import IconSpinner from "components/IconSpinner";
import IndividualOverview from "./IndividualOverview";
import IndividualRolloverCard from "./IndividualRolloverCard";
import IndividualRolloverFormCard from "./IndividualRolloverFormCard";
import ReAuthPlaidLink from "components/ReAuthPlaidLink";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import IconBanner from "components/IconBanner";

import "./IndividualDashboard.scss";

class IndividualDashboard extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    getAllAccounts: PropTypes.func,
    getAllDocuments: PropTypes.func,
    getUserRecurringContributions: PropTypes.func,
    getEmployerLinkRequests: PropTypes.func,
    getTransactions: PropTypes.func,
    client: PropTypes.object,
    iraAccountId: PropTypes.string,
    isFetching: PropTypes.bool,
    hasBankAccount: PropTypes.bool,
    userHasSetupPaycheckContribution: PropTypes.bool,
    accountClosed: PropTypes.bool,
    hasLinkRequest: PropTypes.bool,
    accountPendingClose: PropTypes.bool,
    userInfo: PropTypes.object,
    transactions: PropTypes.arrayOf(transactionType),
    getLinkedEmployers: PropTypes.func,
    nextPayrollDate: PropTypes.string,
    userIsLocked: PropTypes.bool,
    getContributionAbility: PropTypes.func,
    contributionAbility: PropTypes.object,
    bankAccount: PropTypes.object,
    userHasRecurringContributions: PropTypes.bool,
    isAccountOverview: PropTypes.bool,
    error: PropTypes.string,
    activeAccountId: PropTypes.string,
    accounts: PropTypes.array,
    activeRolloverAccount: PropTypes.bool,
    isProcessingRollover: PropTypes.bool,
    activeIdIsProcessingRollover: PropTypes.bool,
    isRolloverIsMixed: PropTypes.bool,
    rolloverAccountIsActive: PropTypes.bool,
    expiringIndividualBanks: PropTypes.array,
    onSuccess: PropTypes.func,
    individualReAuthRequired: PropTypes.bool,
    auth0AccountSessionId: PropTypes.string,
    isPaychex: PropTypes.bool,
    hasMixedFunds: PropTypes.bool,
    activeAccountType: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      initialFetching: true,
    };
  }

  _promises = [];

  componentDidMount() {
    window.analytics.page("Dashboard");
    if (this.props.userIsLocked) {
      this.setState({ initialFetching: false });
      return;
    }
    const getLinkedEmployers = this.props.getLinkedEmployers(this.props.client);
    const linkRequestsPromise = this.props.getEmployerLinkRequests(
      this.props.client
    );
    this._promises.push(
      this.props.getUserRecurringContributions(this.props.client)
    );
    const accountPromise = this.props
      .getAllAccounts(this.props.client)
      .then(() => {
        if (this.props.iraAccountId) {
          this.props.getAllDocuments(
            this.props.client,
            this.props.iraAccountId
          );
          const transactionPromise = this.props
            .getTransactions(this.props.client, this.props.iraAccountId)
            .then(() => {
              this.setState({ initialFetching: false });
            });
          this._promises.push(transactionPromise);
        } else {
          this.setState({ initialFetching: false });
        }
      });

    const getContributionAbility = this.props.getContributionAbility(
      this.props.client
    );

    this._promises.push(
      accountPromise,
      linkRequestsPromise,
      getLinkedEmployers,
      getContributionAbility
    );
  }

  componentWillUnmount() {
    each(this._promises, (promise) => {
      promise.cancel();
    });
  }

  _handleOnSuccess = (token, metadata, userLegalName) => {
    if (this.props.onSuccess) {
      this.props.onSuccess(token, metadata, userLegalName);
      toast.success("Bank account successfully re-authenticated.");
    }
  };

  _getPlaidLinkButton = (isExpiredBank) => {
    return (
      <ReAuthPlaidLink bank={isExpiredBank} onSuccess={this._handleOnSuccess} />
    );
  };

  _individualReAuthCard() {
    const { bankAccount } = this.props;

    const bankAccountName = get(bankAccount, "bankName");
    const lastFourDigits = get(bankAccount, "accountId");

    const title = "Bank Authentication Required!";
    const subheader = `Re-authenticate your ${bankAccountName} bank account ending in ${lastFourDigits} to resume contributions from your linked bank account.`;

    return (
      <IconBanner
        title={title}
        subheader={subheader}
        onClick={this._getPlaidLinkButton(bankAccount)}
      />
    );
  }

  _renderSuccessfulOnboardingDashboard(firstTransactionPending) {
    const employeePayrollContributionProcessing =
      this.props.userHasSetupPaycheckContribution && firstTransactionPending;
    // contribution might not be processed yet by internal service due to async contribution processing
    const selfEmployedContributionProcessing =
      this.props.userHasRecurringContributions && firstTransactionPending;
    const shouldShowContributionProcessingText =
      employeePayrollContributionProcessing ||
      selfEmployedContributionProcessing;

    const userHasAProcessingRollover = this.props.activeRolloverAccount;

    const userHasProcessingContributionsAndProcessingRollover =
      shouldShowContributionProcessingText && userHasAProcessingRollover;

    const userHasSetupContributionsAndHasProcessingRollover =
      this.props.activeRolloverAccount &&
      this.props.userHasRecurringContributions;

    const userHasABlankSlate =
      !shouldShowContributionProcessingText && !userHasAProcessingRollover;

    const title = !userHasAProcessingRollover
      ? ` Great News, ${this.props.userInfo.firstName}!`
      : `Almost there, ${this.props.userInfo.firstName}!`;

    let messageContent;

    if (userHasABlankSlate) {
      messageContent = (
        <p className="email-details">
          Your IRA account is now open. To see your active account, please set
          up your recurring contributions or make an initial deposit if you
          haven&apos;t already. If you are planning a rollover, please navigate
          to the support page and complete it as soon as possible. Thanks for
          taking care of this quickly. We can&apos;t wait for you to get
          started!
        </p>
      );
    } else if (userHasProcessingContributionsAndProcessingRollover) {
      messageContent = (
        <p className="email-details">
          Your IRA account is now open, and we have received your initial
          deposit. Processing may take up to 5 days. Once the funds have fully
          posted, you will be able to view your account balance and the latest
          transactions. Thanks for taking care of this quickly. We can&apos;t
          wait for you to get started!
        </p>
      );
    } else if (userHasAProcessingRollover) {
      messageContent = (
        <p className="email-details">
          Great news, your account is now open! Once you complete the rollover
          you will see your funds in your account. Thanks for taking care of
          this quickly, we cannot wait for you to get started.
        </p>
      );
    } else if (shouldShowContributionProcessingText) {
      messageContent = (
        <p className="email-details">
          Your account is now open and your recurring contribution has been set
          up! Once your contribution processes and is fully posted, you&apos;ll
          be able to see your account balance and latest transactions. Thank you
          for taking care of this quickly; we can&apos;t wait for you to get
          started. Once you complete the rollover, you&apos;ll see your funds in
          your account.
        </p>
      );
    } else if (userHasSetupContributionsAndHasProcessingRollover) {
      messageContent = (
        <p className="email-details">
          We have received your initial deposit into your IRA account. This can
          take up to 5 days and once the funds have fully posted you will then
          be able to see your account balance and latest transactions.
        </p>
      );
    } else if (this.props.userHasRecurringContributions) {
      messageContent = (
        <p className="email-details">
          We have setup your recurring contribution. Once your contribution
          processes and has fully posted you will be able to see your account
          balance and latest transactions.
        </p>
      );
    } else if (this.props.userHasSetupPaycheckContribution) {
      messageContent = (
        <p className="email-details">
          We have setup your payroll contribution amount. Once your employer
          runs payroll and your contribution has fully posted you will then be
          able to see your account balance and latest transactions.
        </p>
      );
    }

    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <div className="main-content">
              {this.props.individualReAuthRequired &&
                this._individualReAuthCard()}
              {this._shouldShowPriorYearContributionCard() && (
                <Row>
                  <Col md={12}>
                    <PriorYearContributionCard
                      priorYearContributionAbility={get(
                        this.props,
                        "contributionAbility.priorYear"
                      )}
                      hasBankAccount={this.props.hasBankAccount}
                      bankAccount={this.props.bankAccount}
                    />
                  </Col>
                </Row>
              )}
              {this.props.activeIdIsProcessingRollover && (
                <IndividualRolloverCard
                  title="You have an active rollover in progress"
                  subtext="For instructions, please refer to the information provided here."
                />
              )}
              {!this.props.activeIdIsProcessingRollover &&
                this.props.rolloverAccountIsActive && (
                  <IndividualRolloverFormCard
                    auth0AccountSessionId={this.props.auth0AccountSessionId}
                  />
                )}

              <Card>
                <Container fluid="md">
                  <Row>
                    <Col>
                      <IconHeader variant="pageHeader" headerText={title} />
                      <IconSubheader subheader={messageContent} />
                    </Col>
                  </Row>
                </Container>
              </Card>
            </div>
          </article>
        </section>
      </div>
    );
  }

  // _renderMultiAccountOverviewDashboard() {
  //   const title = this.props.isRolloverIsMixed
  //     ? "You have active rollovers in progress."
  //     : "You have an active rollover in progress.";

  //   const subtext =
  //     "For instructions, please refer to the information provided here.";

  //   // We need to make sure we get the actual state of the account.
  //   const paychexTransferPending = this.props.isPaychex;

  //   let content;

  //   if (this.props.individualReAuthRequired) {
  //     content = this._individualReAuthCard();
  //   } else if (this.props.isProcessingRollover) {
  //     content = <IndividualRolloverCard title={title} subtext={subtext} />;
  //   } else if (
  //     !this.props.activeIdIsProcessingRollover &&
  //     this.props.rolloverAccountIsActive
  //   ) {
  //     content = (
  //       <IndividualRolloverFormCard
  //         auth0AccountSessionId={this.props.auth0AccountSessionId}
  //       />
  //     );
  //   } else if (paychexTransferPending) {
  //     content = (
  //       <Rollover401kProgressiveOnboarding
  //         headerText="Let's complete your rollovers."
  //         subtext="Follow these steps to transfer your Paychex Pre-tax and Post-tax funds into Icon:"
  //         accordionTitle="Your Icon IRA accounts are active"
  //         accordionSubtext="Your Traditional and Roth IRA’s have been successfully opened and are ready to receive your Paychex transfers."
  //       />
  //     );
  //   } else if (!this.props.isPaychex) {
  //     content = <IndividualOverview />;
  //   }

  //   return <div>{content}</div>;
  // }

  _renderMultiAccountOverviewDashboard() {
    const title = this.props.isRolloverIsMixed
      ? "You have active rollovers in progress."
      : "You have an active rollover in progress.";

    const subtext =
      "For instructions, please refer to the information provided here.";

    return (
      <>
        {this.props.individualReAuthRequired && this._individualReAuthCard()}
        {this.props.isProcessingRollover && (
          <IndividualRolloverCard title={title} subtext={subtext} />
        )}
        {!this.props.activeIdIsProcessingRollover &&
          this.props.rolloverAccountIsActive && (
            <IndividualRolloverFormCard
              auth0AccountSessionId={this.props.auth0AccountSessionId}
            />
          )}

        <IndividualOverview />
      </>
    );
  }

  _renderAccountClosedDashboard() {
    return (
      <Card className="account-closed-card">
        <Card.Body>
          <Card.Title>Account Closed</Card.Title>
          <Card.Text>
            Your account is closed. This process can take up to 15 business days
            for all funds to settle and be disbursed to you. We will contact you
            directly for any additional steps you may need to complete. If you
            have any questions, let us know at <SupportEmailLink />.
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  _renderFullDashboard() {
    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <div className="main-content">
              {this.props.individualReAuthRequired &&
                this._individualReAuthCard()}

              {this._shouldShowPriorYearContributionCard() && (
                <Row>
                  <Col md={12}>
                    <PriorYearContributionCard
                      priorYearContributionAbility={get(
                        this.props,
                        "contributionAbility.priorYear"
                      )}
                      hasBankAccount={this.props.hasBankAccount}
                      bankAccount={this.props.bankAccount}
                    />
                  </Col>
                </Row>
              )}

              {this.props.activeIdIsProcessingRollover && (
                <IndividualRolloverCard
                  title="You have an active rollover in progress"
                  subtext="For instructions, please refer to the information provided here."
                />
              )}
              {!this.props.activeIdIsProcessingRollover &&
                this.props.rolloverAccountIsActive && (
                  <IndividualRolloverFormCard
                    auth0AccountSessionId={this.props.auth0AccountSessionId}
                  />
                )}

              <Row>
                <Col md={12}>
                  <BalanceKpi hasBankAccount={this.props.hasBankAccount} />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <PerformanceTable />
                </Col>
              </Row>
            </div>
          </article>
        </section>
      </div>
    );
  }

  _renderAccountLockedDashboard() {
    return (
      <Card className="account-closed-card">
        <Card.Body>
          <Card.Title>Account Locked</Card.Title>
          <Card.Text>
            Your account is locked. If you have any questions, let us know at{" "}
            <SupportEmailLink />.
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  _shouldShowPriorYearContributionCard = () => {
    const isWithinPriorYearContributionWindow = get(
      this.props,
      "contributionAbility.isWithinPriorYearContributionWindow",
      false
    );
    const priorYearContributionAbility = get(
      this.props,
      "contributionAbility.priorYear"
    );
    const canContributeToPriorYear = get(
      priorYearContributionAbility,
      "canContribute"
    );
    return isWithinPriorYearContributionWindow && canContributeToPriorYear;
  };

  render() {
    let content;

    const noTransactions = isEmpty(this.props.transactions);
    let firstTransactionPending = false;
    if (!noTransactions) {
      const firstTransaction = this.props.transactions[0];
      firstTransactionPending =
        size(this.props.transactions) === 1 &&
        firstTransaction.status !== "COMPLETE";
    }

    if (this.state.initialFetching) {
      content = null;
    } else if (this.props.userIsLocked) {
      content = this._renderAccountLockedDashboard();
    } else if (this.props.accountPendingClose) {
      content = <PendingClosure />;
    } else if (this.props.accountClosed) {
      content = this._renderAccountClosedDashboard();
    } else if (this.props.isAccountOverview) {
      content = this._renderMultiAccountOverviewDashboard();
    } else if (noTransactions || firstTransactionPending) {
      content = this._renderSuccessfulOnboardingDashboard(
        firstTransactionPending
      );
    } else {
      content = this._renderFullDashboard();
    }

    const isFetching = this.props.isFetching || this.state.initialFetching;
    return (
      <div className="dashboard-content">
        {isFetching && !this.props.userIsLocked && <IconSpinner centered />}
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        {content}{" "}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  accountConstants.GET_ACCOUNTS,
  userConstants.USER_EMPLOYER_LINK_REQUESTS,
  transactionConstants.GET_TRANSACTIONS,
  contributionConstants.GET_RECURRING_CONTRIBUTIONS,
]);

const errorSelector = createErrorSelector([
  bankConstants.OAUTH_PLAID_LINK_TOKEN,
]);

const mapStateToProps = (state) => {
  const { profile: userInfo } = state.user;
  const accounts = state.accounts.iraAccounts;
  const iraAccountId = getIraAccountIdSelector(state);

  const isRolloverIsMixed = activeRolloverIsMixed(state);

  const activeRolloverAccount = some(accounts, {
    id: iraAccountId,
    isRollover: true,
    isProcessingRollover: false,
    state: "AccountActive",
  });

  const rolloverAccountIsActive = accounts.some(
    (account) =>
      account.isRollover &&
      !account.isProcessingRollover &&
      account.state === rolloverStates.AccountActive
  );

  const expiringIndividualBanks = expiringIndividualBankSelector(state);

  const bankId = get(bankSelector(state), "id");

  const bankAccountId = bankId;
  const expiringBanks = expiringIndividualBankSelector(state);
  const needsReAuth = shouldReAuthBank(expiringBanks, bankAccountId);

  const individualReAuthRequired =
    !isEmpty(expiringIndividualBankSelector(state)) &&
    bankSelector(state) &&
    needsReAuth;

  return {
    rolloverAccountIsActive,
    isRolloverIsMixed,
    activeIdIsProcessingRollover: activeIdIsProcessingRollover(state),
    isProcessingRollover: isProcessingRolloverSelector(state),
    hasBankAccount: isBankVerifiedSelector(state),
    iraAccountId,
    isFetching: loadingSelector(state),
    accountClosed:
      get(state.user, "userState.state", "") === "IndividualClosed",
    accountPendingClose:
      get(state.user, "userState.state", "") === "IndividualClosePending",
    transactions: state.transactions.transactions,
    userHasSetupPaycheckContribution: some(
      state.user.employerLinkRequests,
      (link) => link.status !== "PENDING" && link.status !== "REJECTED"
    ),
    hasLinkRequest: !isEmpty(state.user.employerLinkRequests),
    userInfo,
    nextPayrollDate: moment(
      get(state.user, "employerLinkRequests[0].schedule.end", null)
    ).format("MMMM DD, YYYY"),
    userIsLocked: userIsLockedSelector(state),
    contributionAbility: contributionAbilitySelector(state),
    bankAccount: bankSelector(state),
    isAccountOverview: isIndividualAccountOverview(state),
    userHasRecurringContributions: !isEmpty(
      state.contribution.recurringContributions
    ),
    error: errorSelector(state),
    activeRolloverAccount,
    accounts,
    expiringIndividualBanks,
    individualReAuthRequired,
    auth0AccountSessionId: auth0AccountSessionSelector(state),
    isPaychex: userIs401kRollIn(state),
    hasMixedFunds: hasBothPreTaxAndRothFundsSelector(state),
    hasPreTaxFunds: hasPreTaxFundsSelector(state),
    hasRothFunds: hasRothFundsSelector(state),
    activeAccountType: activeAccountType(state),
  };
};
const mapDispatchToProps = {
  push,
  getAllAccounts,
  getTransactions,
  getAllDocuments,
  getLinkedEmployers,
  getEmployerLinkRequests,
  getContributionAbility,
  getUserRecurringContributions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualDashboard));
