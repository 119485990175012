import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Col, Row } from "react-bootstrap";
import { withApollo } from "@apollo/client/react/hoc";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { userConstants } from "actions/types";
import { updateUserProfile } from "actions/userActions";

import UserProfileWorkflow from "pages/dashboards/individualDashboard/profile/UserProfileWorkflow";
import IconHeader from "components/IconHeader";
import Button from "components/Button";
import IconSubheader from "components/IconSubheader";

import "../../profile/ProfileInfoCard.scss";

class AddressConfirmation extends React.Component {
  static propTypes = {
    userInfo: PropTypes.object,
    fetchingProfile: PropTypes.bool,
    client: PropTypes.object,
    updateUserProfile: PropTypes.func,
    error: PropTypes.string,
    isUpdatingProfile: PropTypes.bool,
    goToNamedStep: PropTypes.func,
    push: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
    };
  }

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };
    return (
      <div id="account-selection">
        {!this.state.isEditMode && (
          <div className="mega-container">
            <section className="page-title-wrap" style={{ paddingTop: 20 }}>
              <article className="text-cell">
                <IconHeader
                  variant="pageHeader"
                  headerText="Please confirm that this information is current before
                  proceeding."
                />
                <IconSubheader
                  variant="subheading"
                  subheader=" We keep this on file for regulatory purposes and if
                  you ever request a withdrawal, so please confirm it, as we
                  will be mailing you a check once your withdrawal is complete."
                />
              </article>
            </section>
            <section className="form-sec-2col" style={{ paddingTop: 35 }}>
              <article className="col-form" style={articleStyle}>
                <div id="form-individual-info" style={{ paddingTop: 20 }}>
                  <Row style={{ paddingBottom: 20 }}>
                    <Col md={4}>
                      <IconHeader
                        variant="labelHeader"
                        headerText="Legal Name"
                      />
                    </Col>
                    <Col md={6}>
                      <p className="address-confirmation">
                        {this.props.userInfo.firstName}{" "}
                        {this.props.userInfo.lastName}
                      </p>
                    </Col>
                  </Row>

                  <Row style={{ paddingBottom: 20 }}>
                    <Col md={4}>
                      <IconHeader
                        variant="labelHeader"
                        headerText="Residential Address"
                      />
                    </Col>
                    <Col md={6}>
                      <p className="address-confirmation">
                        {this.props.userInfo.address1}{" "}
                        {this.props.userInfo.address2}
                      </p>
                      <p
                        className="address-confirmation"
                        style={{ display: "flex" }}
                      >
                        {this.props.userInfo.city} {this.props.userInfo.state}{" "}
                        {this.props.userInfo.postalCode}
                      </p>
                    </Col>
                  </Row>

                  <Row style={{ paddingBottom: 20 }}>
                    <Col md={4}>
                      <IconHeader
                        variant="labelHeader"
                        headerText="Phone Number"
                      />
                    </Col>
                    <Col md={6}>
                      <p className="address-confirmation">
                        {this.props.userInfo.phoneNumber}
                      </p>
                    </Col>
                  </Row>
                </div>
              </article>
            </section>
            <div
              className="submit-row btn-row"
              style={{ justifyContent: "space-between" }}
            >
              <span>
                <Button
                  type="button"
                  color="cancel"
                  name="cancel"
                  btnLabel="Cancel"
                  onClick={() => this.props.push("/dashboard/support")}
                />
              </span>
              <span style={{ display: "flex" }}>
                <Button
                  style={{ marginLeft: 10 }}
                  className="edit"
                  type="button"
                  color="action"
                  name="action"
                  btnLabel="Update Info"
                  onClick={() => {
                    this.setState({ isEditMode: true });
                  }}
                />
                <Button
                  name="action"
                  onClick={() =>
                    this.props.goToNamedStep("distributionAccountType")
                  }
                />
              </span>
            </div>
          </div>
        )}
        {!this.props.fetchingProfile && this.state.isEditMode && (
          <UserProfileWorkflow
            onSuccess={() => {
              this.setState({ isEditMode: false });
              this.props.goToNamedStep("distributionAccountType");
            }}
            onCancel={() => this.setState({ isEditMode: false })}
          />
        )}
      </div>
    );
  }
}

const profileUpdateSelector = createLoadingSelector(
  userConstants.USER_PROFILE_UPDATE
);
const profileUpdateErrorSelector = createErrorSelector(
  userConstants.USER_PROFILE_UPDATE
);
const mapStateToProps = (state, ownProps) => {
  const { id, profile: userInfo } = state.user;

  return {
    id,
    userInfo,
    ownProps,
    isUpdatingProfile: profileUpdateSelector(state),
    error: profileUpdateErrorSelector(state),
  };
};

const mapDispatchToProps = { push, updateUserProfile };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddressConfirmation));
