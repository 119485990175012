import React from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Form, Col, Row } from "react-bootstrap";
import { find } from "lodash";
import { userConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getUserState } from "actions/userActions";
import { getIraAccountIdSelector } from "store/selectors/user";
import { formatCurrency, formatAmount } from "utils/number";

import Button from "components/Button";
import Alert from "components/Alert";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import SelectBox from "../../../../signUp/individual/SelectBox";

const distributionAmountOptions = [
  {
    label: "Transfer a specific amount",
    key: "SPECIFIC_AMOUNT",
  },
  {
    label: "Transfer entire account balance",
    key: "ENTIRE_ACCOUNT_BALANCE",
  },
];

class DistributionAmount extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    isSubmitting: PropTypes.bool,
    error: PropTypes.string,
    goToNamedStep: PropTypes.func,
    selectedAccountType: PropTypes.string,
    accounts: PropTypes.array,
    distributionAmount: PropTypes.func,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      distributionAmount: "",
      submitted: false,
      amount: "",
      showBalanceMessage: false,
    };
  }

  _setDistributionAmount = (distributionAmount, setFieldValue) => {
    const { accounts, selectedAccountType } = this.props;
    const distributionAccount = find(accounts, { id: selectedAccountType });
    const totalAccountBalance =
      distributionAccount.balances.totalAccountBalance;

    const showInput = distributionAmount === "SPECIFIC_AMOUNT";
    const showBalanceMessage = distributionAmount === "ENTIRE_ACCOUNT_BALANCE";

    this.setState({
      distributionAmount,
      showInput,
      showBalanceMessage,
    });

    const amountValue =
      distributionAmount === "ENTIRE_ACCOUNT_BALANCE"
        ? totalAccountBalance
        : this.state.amount;

    const isTotalDisbursement = distributionAmount === "ENTIRE_ACCOUNT_BALANCE";

    setFieldValue("amount", amountValue);

    this.props.distributionAmount({
      distributionAmount,
      amount: amountValue,
      isTotalDisbursement,
    });
  };

  _onAmountChange = (event, setFieldValue, setFieldTouched) => {
    const amount = event.target.value;

    const formattedAmount = amount ? formatAmount(amount) : "";

    setFieldValue("amount", formattedAmount);
    setFieldTouched("amount", true);

    this.setState(
      (prevState) => ({
        distributionAmount: prevState.distributionAmount || "SPECIFIC_AMOUNT",
        amount: formattedAmount,
      }),
      () => {
        this.props.distributionAmount({
          distributionAmount: this.state.distributionAmount,
          amount: this.state.amount,
        });
      }
    );
  };

  isButtonDisabled = (distributionAmount, amount, errors) => {
    const isAmountValid = amount && !errors.amount;
    return !(
      distributionAmount === "ENTIRE_ACCOUNT_BALANCE" ||
      (distributionAmount === "SPECIFIC_AMOUNT" && isAmountValid)
    );
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    const { accounts, selectedAccountType } = this.props;
    const distributionAccount = find(accounts, { id: selectedAccountType });

    const totalAccountBalance =
      distributionAccount.balances.totalAccountBalance;

    const schema = yup.object({
      amount: yup
        .number()
        .nullable()
        .required("Amount is required")
        .label("Specific Amount")
        .test("amount", "Amount must be greater than 0", (value) => value > 0)
        .test(
          "max-amount",
          `Amount cannot exceed available balance of ${formatCurrency(
            totalAccountBalance
          )}`,
          (value) => value <= totalAccountBalance
        ),
    });

    return (
      <div>
        <div className="mega-container">
          <section className="page-title-wrap">
            <article className="text-cell">
              <IconHeader
                variant="pageHeader"
                headerText="How much do you want to withdrawal from your Icon account?"
              />
              <IconSubheader
                variant="subheading"
                subheader="Please choose the amount you'd like to transfer."
              />
            </article>
          </section>

          <Formik
            validateOnChange={true}
            validationSchema={schema}
            onSubmit={(values) => {
              this._onSubmit(values);
            }}
            enableReinitialize={true}
            initialValues={{
              amount: this.state.amount || "",
            }}
            context={{ totalAccountBalance }}
          >
            {({
              setFieldTouched,

              handleSubmit,
              handleBlur,
              setFieldValue,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div>
                  <section
                    className="form-sec-2col"
                    style={{ paddingTop: 20, paddingBottom: 20 }}
                  >
                    <article className="col-form" style={articleStyle}>
                      <div className="account-types">
                        {distributionAmountOptions.map((account) => (
                          <SelectBox
                            label={account.label}
                            description={account.description}
                            key={account.key}
                            items={account.items}
                            ordered={account.ordered}
                            displayChevron={true}
                            onSelect={() => {
                              this._setDistributionAmount(
                                account.key,
                                setFieldValue
                              );
                            }}
                            isSelected={
                              this.state.distributionAmount === account.key
                            }
                          >
                            {this.state.distributionAmount ===
                              "SPECIFIC_AMOUNT" &&
                              account.key === "SPECIFIC_AMOUNT" && (
                                <Form.Row as={Col} sm={12}>
                                  <Form.Group controlId="formDistributionAmount">
                                    <Form.Control
                                      type="number"
                                      name="amount"
                                      placeholder="Enter a dollar amount"
                                      value={values.amount || ""}
                                      onChange={(e) =>
                                        this._onAmountChange(
                                          e,
                                          setFieldValue,
                                          setFieldTouched
                                        )
                                      }
                                      onBlur={handleBlur}
                                      isInvalid={
                                        touched.amount && !!errors.amount
                                      }
                                      isValid={touched.amount && !errors.amount}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      {errors.amount}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Form.Row>
                              )}

                            {account.key === "ENTIRE_ACCOUNT_BALANCE" &&
                              this.state.showBalanceMessage && (
                                <Row>
                                  <Col>
                                    <p
                                      style={{
                                        fontSize: 14,
                                        color: "#5e6b75",
                                        margin: "0px 10px 10px",
                                      }}
                                    >
                                      Please note that your final transfer
                                      amount may differ from your current
                                      account balance of{" "}
                                      {formatCurrency(totalAccountBalance)}, as
                                      it depends on market fluctuations and the
                                      price at which your securities are sold.
                                    </p>
                                  </Col>
                                </Row>
                              )}
                          </SelectBox>
                        ))}
                      </div>
                    </article>
                  </section>
                  <section className="form-sec-2col">
                    {this.props.error && (
                      <Alert type="error" msg={this.props.error} />
                    )}
                    <div className="submit-row btn-row">
                      <Button
                        onClick={() =>
                          this.props.goToNamedStep("distributionAccountType")
                        }
                        name="cancel"
                        btnLabel="Go Back"
                        color="cancel"
                      />
                      <Button
                        style={{ marginLeft: 10 }}
                        name="action"
                        disabled={this.isButtonDisabled(
                          this.state.distributionAmount,
                          values.amount,
                          errors
                        )}
                        onClick={() =>
                          this.props.goToNamedStep("distributionType")
                        }
                      />
                    </div>
                  </section>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const actions = [userConstants.UPDATE_SELECTED_ACCOUNT_TYPE];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector([actions]);

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  const accounts = state.accounts.iraAccounts;

  return {
    isSubmitting: loadingSelector(state),
    error: errorSelector(state),
    userState,
    accountId: getIraAccountIdSelector(state),
    accounts,
  };
};

const mapDispatchToProps = {
  getUserState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(DistributionAmount));
