import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import "./Typography.scss";

const IconHeader = ({ variant, children, headerText, ...props }) => {
  const className = cn({
    [`typography--variant-${variant}`]: variant,
    "default-variant": !variant,
  });

  return (
    <div className={className} {...props}>
      {children || headerText}
    </div>
  );
};

IconHeader.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
  headerText: PropTypes.string,
};

export default IconHeader;
