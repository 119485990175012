import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { userConstants } from "actions/types";
import { scrollToTop } from "utils/dom";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getUserState, updateSelectedAccountType } from "actions/userActions";
import { getIraAccountIdSelector } from "store/selectors/user";
import { INVESTMENT_PROFILE } from "statics/onboardingSteps";
import { TbLetterP } from "react-icons/tb";
import { FiAnchor, FiUmbrella } from "react-icons/fi";
import { Row, Col } from "react-bootstrap";
import { accountTypesToEnglishMapping } from "statics/accountTypes";
import { FiCheckCircle } from "react-icons/fi";
import { userIs401kRollIn } from "../../../store/selectors/user";
import {
  hasBothPreTaxAndRothFundsSelector,
  hasPreTaxFundsSelector,
  hasRothFundsSelector,
} from "store/selectors/auth0";

import ProgressBar from "components/ProgressBar";
import Button from "components/Button";
import Alert from "components/Alert";
import CircularProgressBar from "components/CircularProgressBar";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import IconInfoCard from "components/IconInfoCard";
import Individual401kPartialRollover from "./Individual401kPartialRollover";

import "./Individual401kConfirmRolloverAccountType.scss";

const Circle = ({ backgroundColor, iconColor, icon: Icon, size = 16 }) => {
  return (
    <div className="account-circle" style={{ backgroundColor }}>
      <span>
        <Icon color={iconColor} stroke={iconColor} size={size} />
      </span>
    </div>
  );
};

Circle.propTypes = {
  backgroundColor: PropTypes.string,
  iconColor: PropTypes.string,
  icon: PropTypes.func,
  size: PropTypes.number,
};

class Individual401kConfirmRolloverAccountType extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    getUserState: PropTypes.func,
    updateSelectedAccountType: PropTypes.func,
    isSubmitting: PropTypes.bool,
    error: PropTypes.string,
    userState: PropTypes.string,
    accountId: PropTypes.string,
    accounts: PropTypes.arrayOf(PropTypes.object),
    hasMixedFunds: PropTypes.bool,
    hasPreTaxFunds: PropTypes.bool,
    hasRothFunds: PropTypes.bool,
    isPaychex: PropTypes.bool,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    const paychex401kAccountType = this.props.hasPreTaxFunds
      ? "TRADITIONAL_IRA"
      : "ROTH_IRA";

    this.state = {
      isRothIRASelected: false,
      selectedAccountType: paychex401kAccountType,
      submitted: false,
      showSelectAccountType: false,
    };
  }

  componentDidMount() {
    window.analytics.page("Individual Account Type Selection");
    scrollToTop();
  }

  //This needs to be updated
  _submitAccountType = () => {
    const accountType = this.state.selectedAccountType;

    this.props
      .updateSelectedAccountType(this.props.client, accountType)
      .then(() => {
        this.props.getUserState(this.props.client);
      });
    window.analytics.track("Submitted Individual Account Type", {
      userState: this.props.userState,
    });

    this.setState({
      submitted: true,
    });
  };

  _setSelectedAccountType = (selectedAccountType) => {
    this.setState({
      selectedAccountType,
    });
  };

  _render401kRolloverConfirmation = () => {
    var articleStyle = {
      paddingBottom: 0,
    };

    let iraHeader;
    let iraSubtext;
    let paychexSubtext;

    const formatTaxTerms = (text) => {
      return (
        <span>
          {text
            .replace(/pre-tax/g, "<strong>pre-tax</strong>")
            .replace(/post-tax/g, "<strong>post-tax</strong>")
            .split(/<strong>|<\/strong>/)
            .map((part, index) => {
              return index % 2 === 1 ? (
                <strong style={{ color: "#0a2540" }} key={index}>
                  {part}
                </strong>
              ) : (
                part
              );
            })}
        </span>
      );
    };

    if (this.props.hasMixedFunds) {
      iraHeader = "Open Both Traditional and Roth IRA's";
      iraSubtext = formatTaxTerms(
        "Open both accounts to maintain pre-tax and post-tax status"
      );
      paychexSubtext = formatTaxTerms(
        "Your Paychex 401(k) has pre-tax and post-tax savings"
      );
    } else if (this.props.hasPreTaxFunds) {
      iraHeader = "Icon Traditional IRA";
      iraSubtext = formatTaxTerms(
        "A Traditional IRA is pre-tax. Rollovers not taxed, withdrawals are taxed"
      );
      paychexSubtext = formatTaxTerms(
        "Your Paychex 401(k) has pre-tax savings"
      );
    } else if (this.props.hasRothFunds) {
      iraHeader = "Icon Roth IRA";
      iraSubtext = formatTaxTerms(
        "A Roth IRA is post-tax. Withdrawals are tax-free."
      );
      paychexSubtext = formatTaxTerms(
        "Your Paychex 401(k) has post-tax savings"
      );
    }

    const cardData = {
      title: "BENEFITS OF A ROTH IRA",
      sections: [
        {
          key: 1,
          title: "Access your money",
          content: (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: "8px",
              }}
            >
              <div
                style={{
                  flexShrink: 0,
                  marginRight: "8px",
                  marginTop: "3px",
                }}
              >
                <FiCheckCircle color="white" stroke="#1b9aa9" size={14} />
              </div>
              <p style={{ fontSize: 14 }}>
                Withdraw penalty-free for certain life events, like a first-time
                home purchase, a birth, or college expenses.
              </p>
            </div>
          ),
        },
        {
          key: 2,
          title: "Tax Status",
          content: (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: "8px",
              }}
            >
              <div
                style={{
                  flexShrink: 0,
                  marginRight: "8px",
                  marginTop: "3px",
                }}
              >
                <FiCheckCircle color="white" stroke="#1b9aa9" size={14} />
              </div>
              <p style={{ fontSize: 14 }}>
                You wont pay taxes on potential growth until you make
                withdrawals and can still make contributions to the account.
              </p>
            </div>
          ),
        },
      ],
    };

    const btnLabel = this.props.hasMixedFunds
      ? "Confirm Both"
      : `Confirm, ${
          accountTypesToEnglishMapping[this.state.selectedAccountType]
        } `;

    return (
      <div id="account-selection">
        <ProgressBar
          isEmployer={false}
          activeStepId={INVESTMENT_PROFILE.id}
          progressPercent={"90"}
        />

        <div className="mega-container">
          <div className="step-container is-active" data-circle-percent="100">
            <section className="page-title-wrap">
              <article className="text-cell">
                <p className="page-eyebrow">YOUR INVESTMENT PROFILE</p>
                <p className="page-title">
                  Let’s confirm the right type of IRA for your Paychex 401(k)
                  rollover.
                </p>
              </article>
              <article className="progress-cell">
                <ul className="circular-progress-wrap">
                  <CircularProgressBar
                    strokeWidth="8"
                    sqSize="75"
                    percentage="90"
                  />
                </ul>
              </article>
            </section>
            <div>
              <Row>
                <Col md={7} style={articleStyle}>
                  <div className="paychex-rollover">
                    <div className="paychex-rollover-account-type">
                      <Circle
                        backgroundColor="#085ea6"
                        iconColor="#ffffff"
                        icon={TbLetterP}
                      />

                      <div className="paychex-account-info">
                        <IconHeader
                          variant="paychexHeader"
                          headerText={"Paychex"}
                        />
                        <IconSubheader subheader={paychexSubtext} />
                      </div>
                    </div>

                    <div className="paychex-divider">
                      <div className="vertical-line"></div>
                    </div>

                    <div className="paychex-rollover-account-type">
                      {this.props.hasMixedFunds ? (
                        <div className="overlapping-circles">
                          <div className="circle primary-circle">
                            <FiUmbrella
                              color="#60A4BF"
                              stroke="#60A4BF"
                              size={16}
                            />
                          </div>
                          <div className="circle secondary-circle">
                            <FiAnchor
                              color="#60A4BF"
                              stroke="#60A4BF"
                              size={16}
                            />
                          </div>
                        </div>
                      ) : (
                        <Circle
                          backgroundColor="#f9fbfe"
                          iconColor="#60A4BF"
                          icon={this.props.hasRothFunds ? FiAnchor : FiUmbrella}
                        />
                      )}

                      <div className="paychex-account-info">
                        <IconHeader
                          variant="selectBoxHeader"
                          headerText={iraHeader}
                        />
                        <IconSubheader subheader={iraSubtext} />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <IconInfoCard
                    title={cardData.title}
                    sections={cardData.sections}
                  />
                </Col>
              </Row>
              <section className="form-sec-2col">
                <article className="col-form">
                  <div className="submit-row">
                    {this.props.error && (
                      <Alert type="error" msg={this.props.error} />
                    )}
                  </div>
                  <div className="btn-row">
                    {this.props.hasMixedFunds && (
                      <Button
                        btnLabel="Open single IRA"
                        color="action"
                        onClick={() =>
                          this.setState({ showSelectAccountType: true })
                        }
                      />
                    )}

                    <Button
                      name="submit"
                      btnLabel={btnLabel}
                      withArrow={true}
                      onClick={this._submitAccountType}
                      loading={this.props.isSubmitting}
                    />
                  </div>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let content;

    if (this.state.showSelectAccountType) {
      content = (
        <Individual401kPartialRollover
          onCancel={() =>
            this.setState({
              showSelectAccountType: false,
            })
          }
        />
      );
    } else {
      content = this._render401kRolloverConfirmation();
    }
    return <div>{content}</div>;
  }
}

const actions = [userConstants.UPDATE_SELECTED_ACCOUNT_TYPE];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  const accounts = state.accounts.iraAccounts;
  return {
    isSubmitting: loadingSelector(state),
    error: errorSelector(state),
    userState,
    accountId: getIraAccountIdSelector(state),
    accounts,
    hasMixedFunds: hasBothPreTaxAndRothFundsSelector(state),
    hasPreTaxFunds: hasPreTaxFundsSelector(state),
    hasRothFunds: hasRothFundsSelector(state),
    isPaychex: userIs401kRollIn(state),
  };
};

const mapDispatchToProps = {
  getUserState,
  updateSelectedAccountType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Individual401kConfirmRolloverAccountType));
