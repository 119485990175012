import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import ProfileInfoCard from "./ProfileInfoCard";
import { withApollo } from "@apollo/client/react/hoc";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { accountConstants, userConstants } from "actions/types";
import { updateUserProfile } from "actions/userActions";

import Button from "components/Button";
import UserProfileWorkflow from "pages/dashboards/individualDashboard/profile/UserProfileWorkflow";

import "./ProfileInfo.scss";

class ProfileInfo extends React.Component {
  static propTypes = {
    userInfo: PropTypes.object,
    fetchingProfile: PropTypes.bool,
    client: PropTypes.object,
    updateUserProfile: PropTypes.func,
    error: PropTypes.string,
    isUpdatingProfile: PropTypes.bool,
    iraAccounts: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
    };
  }

  render() {
    return (
      <div
        className="tab-pane"
        id="account"
        role="tabpanel"
        aria-labelledby="account_tab"
      >
        {!this.state.isEditMode && (
          <>
            <ProfileInfoCard
              userInfo={this.props.userInfo}
              iraAccounts={this.props.iraAccounts}
            />
            <Button
              style={{ marginLeft: 10 }}
              className="edit"
              type="button"
              name="action"
              size="sm"
              withArrow={true}
              btnLabel="Update Info"
              onClick={() => {
                this.setState({ isEditMode: true });
              }}
            />
          </>
        )}
        {!this.props.fetchingProfile && this.state.isEditMode && (
          <UserProfileWorkflow
            onSuccess={() => this.setState({ isEditMode: false })}
            onCancel={() => this.setState({ isEditMode: false })}
          />
        )}
      </div>
    );
  }
}

const profileUpdateSelector = createLoadingSelector(
  userConstants.USER_PROFILE_UPDATE,
  accountConstants.GET_ACCOUNTS
);
const profileUpdateErrorSelector = createErrorSelector(
  userConstants.USER_PROFILE_UPDATE,
  accountConstants.GET_ACCOUNTS
);
const mapStateToProps = (state, ownProps) => {
  const { id, profile: userInfo } = state.user;
  const iraAccounts = state.accounts.iraAccounts;

  return {
    id,
    userInfo,
    ownProps,
    isUpdatingProfile: profileUpdateSelector(state),
    error: profileUpdateErrorSelector(state),
    iraAccounts,
  };
};

const mapDispatchToProps = { push, updateUserProfile };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ProfileInfo));
