// State Requirements Table data based on distribution form
export const stateRequirementsTable = {
  AL: { option: "Voluntary", minRate: 0, canOptOut: true },
  AK: { option: "No State Income Tax", minRate: null, canOptOut: true },
  AZ: { option: "Voluntary", minRate: 0.5, canOptOut: true },
  AR: {
    option: "Mandatory",
    minRate: 3,
    waiverForm: "AR-4P",
    canOptOut: false,
  },
  CA: { option: "Voluntary", minRate: 10, canOptOut: false },
  CO: { option: "Voluntary", minRate: 4.4, canOptOut: false },
  CT: { option: "Mandatory", minRate: 6.99, canOptOut: false },
  DE: { option: "Voluntary", minRate: 0, canOptOut: false },
  FL: { option: "No State Income Tax", minRate: null, canOptOut: true },
  GA: { option: "Voluntary", minRate: 5.39, canOptOut: false },
  HI: { option: "No State Income Tax", minRate: null, canOptOut: true },
  ID: { option: "Voluntary", minRate: 5.7, canOptOut: false },
  IL: { option: "Voluntary", minRate: 4.95, canOptOut: false },
  IN: { option: "Voluntary", minRate: 0, canOptOut: false },
  IA: { option: "Mandatory", minRate: 3.8, canOptOut: false },
  KS: { option: "Voluntary", minRate: 0, canOptOut: false },
  KY: { option: "Voluntary", minRate: 4, canOptOut: false },
  LA: { option: "Voluntary", minRate: 0, canOptOut: false },
  ME: { option: "Mandatory", minRate: 5, canOptOut: false },
  MD: { option: "Voluntary", minRate: 0, canOptOut: false },
  MA: { option: "Mandatory", minRate: 5, canOptOut: false },
  MI: { option: "Voluntary", minRate: 4.25, canOptOut: false },
  MN: { option: "Mandatory", minRate: 6.25, canOptOut: false },
  MS: { option: "Voluntary", minRate: 0, canOptOut: false },
  MO: { option: "Voluntary", minRate: 4.7, canOptOut: false },
  MT: { option: "Voluntary", minRate: 0, canOptOut: false },
  NE: { option: "Mandatory", minRate: 5, canOptOut: false },
  NV: { option: "No State Income Tax", minRate: null, canOptOut: true },
  NH: { option: "No State Income Tax", minRate: null, canOptOut: true },
  NJ: { option: "Mandatory", minRate: 0, canOptOut: false },
  NM: { option: "Voluntary", minRate: 0, canOptOut: false },
  NY: { option: "No State Income Tax", minRate: null, canOptOut: true },
  NC: { option: "Mandatory", minRate: 4, canOptOut: false },
  ND: { option: "Voluntary", minRate: 0, canOptOut: false },
  OH: { option: "Voluntary", minRate: 0, canOptOut: false },
  OK: { option: "Mandatory", minRate: 4.75, canOptOut: false },
  OR: { option: "Mandatory", minRate: 8, canOptOut: false },
  PA: { option: "No State Income Tax", minRate: null, canOptOut: true },
  RI: { option: "Voluntary", minRate: 0, canOptOut: false },
  SC: { option: "Voluntary", minRate: 0, canOptOut: false },
  SD: { option: "No State Income Tax", minRate: null, canOptOut: true },
  TN: { option: "No State Income Tax", minRate: null, canOptOut: true },
  TX: { option: "No State Income Tax", minRate: null, canOptOut: true },
  UT: { option: "Voluntary", minRate: 4.55, canOptOut: false },
  VT: { option: "Mandatory", minRate: 30, canOptOut: false },
  VA: { option: "Voluntary", minRate: 0, canOptOut: false },
  WA: { option: "No State Income Tax", minRate: null, canOptOut: true },
  DC: { option: "Voluntary", minRate: 0, canOptOut: false },
  WV: { option: "Voluntary", minRate: 0, canOptOut: false },
  WI: { option: "Voluntary", minRate: 0, canOptOut: false },
  WY: { option: "No State Income Tax", minRate: null, canOptOut: true },
};
