import React from "react";
import PropTypes from "prop-types";
import { isEmpty, some, transform } from "lodash";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { userService } from "services/userService";
import { adminService } from "services/adminService";
import { updateCompanyInfo, updateCompanyKybInfo } from "actions/adminActions";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import Button from "components/Button";
import AddressValidator from "components/AddressValidator";
import {
  address2Validator,
  postalCodeValidator,
  testEIN,
  testPhoneNumber,
  urlValidator,
  validateUsersAddress,
} from "utils/fieldValidators";
import {
  businessTypes,
  companySizeValues,
  naicsValues,
} from "statics/businessCodes";
import InputMask from "react-input-mask";
import StateSelect from "components/StateSelect";
import { toast } from "react-toastify";
import { wrapError } from "utils/errorHelper";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import {
  companionPlanId,
  primaryPlanId,
  statePlanId,
} from "statics/featureIds";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";
import { EmployerTermsComplete } from "statics/states";
import { ScrollToFieldError } from "utils/form";
/* eslint-disable react/no-children-prop */

let yup = require("yup");

const schema = yup.object({
  businessName: yup
    .string()
    .label("Company Legal Name")
    .required()
    .min(3, "Must be at least three characters.")
    .max(200, "Must be less than 200 characters."),
  businessDBA: yup
    .string()
    .label("Company DBA")
    .min(1, "Must be at least one character.")
    .max(200),
  ein: yup
    .string()
    .label("EIN")
    .required()
    .test("ein", "${path} is not a valid format. Ex: 12-3456789", testEIN)
    .max(10),
  businessType: yup.string().label("Incorporation Type").required(),
  naicsCode: yup.string().label("Industry Type").required(),
  businessSize: yup.string().label("Company Size").required(),
  businessURL: yup
    .string()
    .label("Website URL")
    .max(200)
    .test("businessURL", "Enter a valid website URL", urlValidator),

  address1: yup
    .string()
    .label("Address 1")
    .required()
    .min(3, "Must be at least three characters.")
    .max(40),
  address2: address2Validator,
  city: yup.string().label("City").required().max(40),
  state: yup.string().label("State").required().max(2),
  postalCode: postalCodeValidator,
  phone: yup
    .string()
    .label("Phone")
    .required()
    .test(
      "us-phone",
      "${path} is not a valid phone number. Ex: 123-456-7890",
      testPhoneNumber
    )
    .max(30),
  phoneExt: yup.string().label("Phone Ext").max(10),
});

function getEnabledFeaturesObj(enabledProductFeatures) {
  return transform(
    enabledProductFeatures,
    (acc, featureId) => {
      acc[featureId] = true;
      return acc;
    },
    {}
  );
}

class Company extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    companyId: PropTypes.string,
    productFeatures: PropTypes.object,
    push: PropTypes.func,
    onUpdate: PropTypes.func,
    updateCompanyInfo: PropTypes.func,
    updateCompanyKybInfo: PropTypes.func,
    onBack: PropTypes.func,
    isFetching: PropTypes.bool,
    client: PropTypes.shape({}),
    hasPassedKyb: PropTypes.bool,
  };

  static defaultProps = {
    hasPassedKyb: false,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      company: {
        businessName: "",
        businessDBA: "",
        ein: "",
        businessType: "",
        naicsCode: "",
        businessSize: "",
        businessURL: "",
        addressId: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        phone: "",
        phoneExt: "",
      },
      submitted: false,
      loading: true,
      showAddressModal: false,
      fetchingAddress: false,
      validatedAddress: {},
      planTypeError: "",
      planTypes: [
        { id: primaryPlanId, label: "Primary" },
        { id: companionPlanId, label: "Companion" },
        { id: statePlanId, label: "State" },
      ],
      enabledProductFeatures: {},
    };

    adminService
      .getCompany(this.props.client, this.props.companyId)
      .then((data) => {
        let company = {
          businessName: data.name ?? "",
          businessDBA: data.dba ?? "",
          ein: data.ein ?? "",
          businessType: data.type ?? "",
          naicsCode: data.naicsCode ?? "",
          businessSize: data.size ?? "",
          businessURL: data.url ?? "",
          addressId: data.addressId ?? "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          postalCode: "",
          phone: "",
          phoneExt: "",
        };

        if (Array.isArray(data.address) && data.address.length >= 1) {
          company.address1 = data.address[0].address1 ?? "";
          company.address2 = data.address[0].address2 ?? "";
          company.city = data.address[0].city ?? "";
          company.state = data.address[0].state ?? "";
          company.postalCode = data.address[0].postalCode ?? "";
          company.phone = data.address[0].phone ?? "";
          company.phoneExt = data.address[0].phoneExt ?? "";
          company.addressId = data.address[0].id ?? "";
        }

        const enabledProductFeatures = getEnabledFeaturesObj(
          data.enabledProductFeatures
        );

        this.setState({
          submitted: true,
          company,
          companyState: data.currentState,
          loading: false,
          enabledProductFeatures,
        });
      });
  }

  submitCompany = async (values, skipAddressValidation = false) => {
    const hasASelectedPlanType = some(
      this.state.enabledProductFeatures,
      (isChecked, key) => {
        if (
          key == primaryPlanId ||
          key == companionPlanId ||
          key == statePlanId
        ) {
          return isChecked;
        }
        return false;
      }
    );

    if (!hasASelectedPlanType) {
      this.setState({
        planTypeError: "You must select at least one plan type.",
      });
      return;
    } else if (this.state.planTypeError) {
      this.setState({
        planTypeError: "",
      });
    }

    // verify user address, if it matches USPS just continue on
    // if USPS endpoint fails continue on
    // if there is a mismatch then open modal to allow customer to decide which to use

    if (!skipAddressValidation) {
      try {
        this.setState({ fetchingAddress: true });

        const companyAddress = {
          address1: values.address1,
          address2: values.address2,
          state: values.state,
          postalCode: values.postalCode,
          city: values.city,
        };

        const validatedAddress = await userService.getValidatedAddress(
          this.props.client,
          companyAddress
        );

        this.setState({ fetchingAddress: false });

        const userAddressIsValid = validateUsersAddress(
          companyAddress,
          validatedAddress
        );

        if (!userAddressIsValid) {
          this.setState({
            showAddressModal: true,
            validatedAddress,
          });

          return;
        }
      } catch (error) {
        // if the validation endpoint fails we dont want to prevent user from saving so continue on and log error
        console.log(error);
      }
    }

    const addressId = this.state.company.addressId || "";

    let company = {
      id: this.props.companyId,
      name: values.businessName,
      dba: values.businessDBA,
      ein: values.ein,
      type: values.businessType,
      naicsCode: values.naicsCode,
      size: values.businessSize,
      url: values.businessURL,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      phone: values.phone,
      phoneExt: values.phoneExt,
      addressId,
    };

    const enabledProductFeatures = transform(
      this.state.enabledProductFeatures,
      (acc, isEnabled, featureId) => {
        if (isEnabled) {
          acc.push(+featureId);
        }
        return acc;
      },
      []
    );

    if (!isEmpty(enabledProductFeatures)) {
      adminService
        .enableFeaturesForCompany(this.props.client, {
          companyId: company.id,
          featureIds: enabledProductFeatures,
        })
        .then(() => {
          toast.success("Successfully enabled features.");
        })
        .catch((err) => {
          toast.error(wrapError(err));
        });
    }
    if (!this.props.hasPassedKyb) {
      this.props
        .updateCompanyKybInfo(this.props.client, company)
        .then(() => {
          if (!this.props.error) {
            toast.success("Successfully registered company.");
            const path =
              this.state.companyState === EmployerTermsComplete
                ? "owners"
                : "summary";

            this.props.push(`/employers/${this.props.companyId}/kyb/${path}`);
          }
        })
        .catch((err) => {
          toast.error(wrapError(err));
        });
    } else {
      this.props
        .updateCompanyInfo(this.props.client, company)
        .then(() => {
          if (!this.props.error) {
            toast.success("Successfully updated company.");

            this.props.onUpdate();
          }
        })
        .catch((err) => {
          toast.error(wrapError(err));
        });
    }
  };

  _closeModal = () => {
    this.setState({ showAddressModal: false });
  };

  _handleProductFeatureChange = (e) => {
    this.setState({
      enabledProductFeatures: {
        ...this.state.enabledProductFeatures,
        [e.target.name]: e.target.checked,
      },
    });
  };

  render() {
    const { company } = this.state;
    var articleStyle = {
      paddingBottom: 0,
    };

    if (this.state.loading) {
      return <IconSpinner centered />;
    }

    return (
      <div
        className="tab-pane"
        id="company"
        role="tabpanel"
        aria-labelledby="company_tab"
      >
        <div>
          <Formik
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={(values) => {
              this.submitCompany(values);
            }}
            enableReinitialize={true}
            initialValues={company}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              setFieldValue,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <ScrollToFieldError />
                {this.state.showAddressModal && (
                  <AddressValidator
                    show={this.state.showAddressModal}
                    onSubmit={this.submitCompany}
                    setFieldValue={setFieldValue}
                    validatedAddress={this.state.validatedAddress}
                    onClose={this._closeModal}
                    values={values}
                    allowPoBox={true}
                  />
                )}
                <div className="mega-container">
                  <div id="form-employer-company-profile">
                    <section>
                      <article className="col-form" style={articleStyle}>
                        <Form.Group controlId="formBasicBusinessName">
                          <h4 className="page-title">Company Registration</h4>
                          <Form.Control
                            name="businessName"
                            placeholder="Company Legal Name"
                            disabled={this.props.hasPassedKyb}
                            value={values.businessName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.businessName && !!errors.businessName
                            }
                            isValid={
                              touched.businessName && !errors.businessName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.businessName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicBusinessDBA">
                          <Form.Control
                            name="businessDBA"
                            placeholder="Company DBA"
                            value={values.businessDBA}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.businessDBA && !!errors.businessDBA
                            }
                            isValid={touched.businessDBA && !errors.businessDBA}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.businessDBA}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Row style={{ marginBottom: 12 }}>
                          <Form.Group
                            as={Col}
                            sm={4}
                            controlId="formBasicBusinessEIN"
                          >
                            <InputMask
                              mask="99-9999999"
                              name="ein"
                              placeholder="EIN"
                              disabled={this.props.hasPassedKyb}
                              readOnly={this.props.hasPassedKyb}
                              value={values.ein}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.ein && !!errors.ein}
                              isValid={touched.ein && !errors.ein}
                              children={(props) => <Form.Control {...props} />}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.ein}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            sm={6}
                            controlId="formBasicBusinessType"
                          >
                            <Form.Control
                              sm={4}
                              as="select"
                              name="businessType"
                              value={values.businessType}
                              disabled={this.props.hasPassedKyb}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.businessType && !!errors.businessType
                              }
                              isValid={
                                touched.businessType && !errors.businessType
                              }
                            >
                              <option value="" disabled>
                                Incorporation Type
                              </option>
                              {businessTypes.map((item) => (
                                <option value={item.value} key={item.value}>
                                  {item.description}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.businessType}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Group controlId="formBasicBusinessNAICS">
                          <h4 className="page-title">Company Info</h4>
                          <Form.Control
                            sm={6}
                            as="select"
                            disabled={this.props.hasPassedKyb}
                            name="naicsCode"
                            value={values.naicsCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.naicsCode && !!errors.naicsCode}
                            isValid={touched.naicsCode && !errors.naicsCode}
                          >
                            <option value="" disabled>
                              Industry Type
                            </option>
                            {naicsValues.map((item) => (
                              <option value={item.value} key={item.value}>
                                {item.description}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.naicsCode}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicBusinessURL">
                          <Form.Control
                            name="businessURL"
                            placeholder="Website"
                            value={values.businessURL}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.businessURL && !!errors.businessURL
                            }
                            isValid={touched.businessURL && !errors.businessURL}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.businessURL}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={6}
                            controlId="formBasicBusinessSize"
                          >
                            <Form.Control
                              as="select"
                              name="businessSize"
                              disabled={this.props.hasPassedKyb}
                              value={values.businessSize}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.businessSize && !!errors.businessSize
                              }
                              isValid={
                                touched.businessSize && !errors.businessSize
                              }
                            >
                              <option value="" disabled>
                                Company Size
                              </option>
                              {companySizeValues.map((item) => (
                                <option value={item.value} key={item.value}>
                                  {item.description}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.businessSize}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Group controlId="formBasicAddress1">
                          <h4 className="page-title">Company Address</h4>
                          <Form.Control
                            name="address1"
                            placeholder="Address 1"
                            value={values.address1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.address1 && !!errors.address1}
                            isValid={touched.address1 && !errors.address1}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address1}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicAddress2">
                          <Form.Control
                            name="address2"
                            placeholder="Address 2"
                            value={values.address2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.address2 && !!errors.address2}
                            isValid={touched.address2 && !errors.address2}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address2}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formBasicCity">
                            <Form.Control
                              name="city"
                              placeholder="City"
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.city && !!errors.city}
                              isValid={touched.city && !errors.city}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.city}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            sm={4}
                            controlId="formBasicState"
                          >
                            <StateSelect
                              value={values.state}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.state}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            sm={2}
                            controlId="formBasicPostalCode"
                          >
                            <Form.Control
                              name="postalCode"
                              placeholder="Zip"
                              value={values.postalCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.postalCode && !!errors.postalCode
                              }
                              isValid={touched.postalCode && !errors.postalCode}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.postalCode}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={4}
                            controlId="formBasicPhone"
                          >
                            <InputMask
                              mask="999-999-9999"
                              name="phone"
                              placeholder="Phone"
                              value={values.phone}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              isInvalid={touched.phone && !!errors.phone}
                              isValid={touched.phone && !errors.phone}
                            >
                              {(props) => <Form.Control {...props} />}
                            </InputMask>
                            <Form.Control.Feedback type="invalid">
                              {errors.phone}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            sm={2}
                            controlId="formBasicPhoneExt"
                          >
                            <Form.Control
                              name="phoneExt"
                              placeholder="Ext"
                              value={values.phoneExt}
                              onChange={handleChange}
                              isInvalid={touched.phoneExt && !!errors.phoneExt}
                              onBlur={handleBlur}
                              isValid={touched.phoneExt && !errors.phoneExt}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.phoneExt}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Group controlId="formPlanType">
                          <h4 className="page-title">Plan Types</h4>
                          {this.state.planTypes.map((planType, index) => (
                            <>
                              <Form.Check
                                key={index}
                                type="checkbox"
                                id={planType.id}
                                name={planType.id}
                                checked={
                                  this.state.enabledProductFeatures[planType.id]
                                }
                                label={planType.label}
                                isInvalid={this.state.planTypeError}
                                feedback={
                                  index === 2 && this.state.planTypeError
                                }
                                onChange={this._handleProductFeatureChange}
                              />
                            </>
                          ))}
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          sm={6}
                          controlId="formEinValidation"
                        ></Form.Group>
                      </article>
                    </section>
                    <div className="submit-row">
                      {this.props.error && (
                        <Alert type="error" msg={this.props.error} />
                      )}

                      <Button
                        withArrow={true}
                        name="submit"
                        btnLabel="Update Company"
                        loading={
                          this.state.fetchingAddress || this.props.isFetching
                        }
                      />
                      <Button
                        name="cancel"
                        btnLabel={"Back"}
                        type={"button"}
                        color="cancel"
                        onClick={this.props.onBack}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const actions = [
  adminConstants.REGISTER_KYB_COMPANY,
  adminConstants.UPDATE_COMPANY_INFO,
];
const errorSelector = createErrorSelector(actions);
const loadingSelector = createLoadingSelector(actions);

const mapStateToProps = (state) => {
  return {
    isFetching: loadingSelector(state),
    error: errorSelector(state),
  };
};
const mapDispatchToProps = {
  updateCompanyInfo,
  updateCompanyKybInfo,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Company));
