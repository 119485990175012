import React from "react";
import * as Sentry from "@sentry/react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { get, includes, isEmpty, isNil } from "lodash";
import { Navbar, NavDropdown } from "react-bootstrap";
import { individualSite } from "utils/determineSite";
import { useAuth0 } from "utils/react-auth0-wrapper";
import { intercomHashSelector } from "store/selectors/auth0";
import { setAccountSession } from "actions/accountActions";
import {
  activeAccountState,
  isPreKycSubmissionState,
} from "store/selectors/user";
import {
  IndividualContributionPending,
  IndividualInvestmentProfileUpdate,
  IndividualLoginCreated,
  IndividualPortfolioPending,
  IndividualPortfolioUpdate,
  IndividualRequiredUpdatesPending,
  lifecycleStates,
  onboardingStates,
} from "statics/states";
import { FiFileText, FiUser } from "react-icons/fi";

import queryString from "query-string";
// import EmployerReferralLink from "components/EmployerReferralLink";
import "components/navbar/NavBar.scss";
import NavBrand from "components/navbar/NavBrand";
import IndividualNavLeft from "components/navbar/IndividualNavLeft";
import SideBarToggleButton from "components/navbar/SideBarToggleButton";
import IconNavDropdown from "components/navbar/IconNavDropdown";
import NavLoginHeaderText from "components/navbar/NavLoginHeaderText";

const IndividualNavBar = ({
  push,
  intercomHash,
  userState,
  accountState,
  userId,
  client,
  shouldHideSideBar,
  // canReferCompany,
  showChangeEmail,
  isIndividualDashboard,
  setAccountSession,
  userInFinalOnboarding,
  userUpdatingPortfolio,
  userInRequiredUpdates,
}) => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  if (!isNil(userId)) {
    window.analytics.identify(
      userId,
      {
        email: user.email,
        invitationCode: queryString.parse(window.location.search)
          .invitationCode,
      },
      {
        integrations: {
          Intercom: {
            user_hash: intercomHash,
          },
        },
      }
    );
    Sentry.setUser({ id: userId });
  }

  const logoutWithRedirect = async () => {
    await setAccountSession(client, "");

    window.analytics.track("Logged Out");
    window.analytics.reset();

    logout({
      logoutParams: {
        returnTo: window.location.origin + "/login",
      },
    });
  };

  const individualLifecycleStates =
    lifecycleStates.includes(userState) ||
    lifecycleStates.includes(accountState);

  return (
    <div>
      <header id="header" className={"icon-header"}>
        <Navbar>
          <div className="nav-left">
            <NavBrand />
            <Navbar.Text>
              {isIndividualDashboard ? (
                <IndividualNavLeft />
              ) : (
                <p className="portal">Individual</p>
              )}
            </Navbar.Text>
          </div>

          <div className="nav-right">
            {!isAuthenticated && (
              <NavLoginHeaderText onClick={loginWithRedirect} />
            )}

            {isAuthenticated && !isEmpty(userState) && (
              <>
                <IconNavDropdown
                  userName={user.nickname}
                  onLogout={logoutWithRedirect}
                  shouldHideSideBar={shouldHideSideBar}
                  showNavAvatar={false}
                >
                  {showChangeEmail && (
                    <NavDropdown.Item
                      eventKey="4.1"
                      onClick={() => push("/individual/change-email")}
                    >
                      <FiUser
                        className="dropdown-icons"
                        size={14}
                        color={"#32325d"}
                        fill={"#FFFFFF"}
                        stroke={"#32325d"}
                      />
                      Change Email
                    </NavDropdown.Item>
                  )}

                  {individualLifecycleStates && (
                    <>
                      {!userInRequiredUpdates && (
                        <NavDropdown.Item
                          eventKey="4.1"
                          disabled={
                            userInFinalOnboarding || userUpdatingPortfolio
                          }
                          onClick={() => push("/dashboard/profile/info")}
                        >
                          <FiUser
                            className="dropdown-icons"
                            size={14}
                            color={"#32325d"}
                            fill={"#FFFFFF"}
                            stroke={"#32325d"}
                          />
                          Profile
                        </NavDropdown.Item>
                      )}
                      <NavDropdown.Item
                        eventKey="4.2"
                        onClick={() => push("/dashboard/documents")}
                      >
                        <FiFileText
                          color={"#FFFFFF"}
                          fill={"#FFFFFF"}
                          stroke={"#32325d"}
                          className="dropdown-icons"
                          size={14}
                        />
                        Documents
                      </NavDropdown.Item>
                    </>
                  )}
                </IconNavDropdown>

                {!shouldHideSideBar && <SideBarToggleButton />}
              </>
            )}
          </div>
        </Navbar>
      </header>
    </div>
  );
};

IndividualNavBar.propTypes = {
  userId: PropTypes.string,
  intercomHash: PropTypes.string,
  userState: PropTypes.string,
  accountState: PropTypes.string,
  pathname: PropTypes.string,
  push: PropTypes.func,
  client: PropTypes.object,
  shouldHideSideBar: PropTypes.bool,
  showChangeEmail: PropTypes.bool,
  isIndividualDashboard: PropTypes.bool,
  setAccountSession: PropTypes.func,
  userInFinalOnboarding: PropTypes.bool,
  userUpdatingPortfolio: PropTypes.bool,
  userInRequiredUpdates: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const userState = get(state.user, "userState.state", "");
  const statesToHideSideBar = [
    ...onboardingStates,
    IndividualLoginCreated,
    IndividualPortfolioPending,
    IndividualContributionPending,
    IndividualInvestmentProfileUpdate,
    IndividualPortfolioUpdate,
    IndividualRequiredUpdatesPending,
  ];

  const pathname = get(state.router, "location.pathname");
  const onVerifyEmailRoute = includes(pathname, "verify-email");

  const userInFinalOnboarding = [
    IndividualPortfolioPending,
    IndividualContributionPending,
  ].includes(userState);
  const userUpdatingPortfolio = [
    IndividualInvestmentProfileUpdate,
    IndividualPortfolioUpdate,
  ].includes(userState);
  const userInRequiredUpdates = [IndividualRequiredUpdatesPending].includes(
    userState
  );

  return {
    shouldHideSideBar: statesToHideSideBar.includes(userState),
    pathname,
    userState: get(state.user, "userState.state", ""),
    accountState: activeAccountState(state),
    userId: state.user.id,
    intercomHash: intercomHashSelector(state),
    showSidebar: state.sidebar.showSidebar,
    showChangeEmail: isPreKycSubmissionState(state) && !onVerifyEmailRoute,
    // canReferCompany: canReferCompany(state),
    isIndividualDashboard:
      lifecycleStates.includes(userState) && individualSite(),
    userInFinalOnboarding,
    userUpdatingPortfolio,
    userInRequiredUpdates,
  };
};

const mapDispatchToProps = {
  push,
  setAccountSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualNavBar));
