import React from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { get, min, round } from "lodash";
import { Formik } from "formik";
import { Col, Form } from "react-bootstrap";
import { formatCurrency } from "utils/number";
import { stateRequirementsTable } from "statics/stateTaxRequirements";
import { states } from "statics/countriesAndStates";
import Button from "components/Button";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import SelectBox from "../pages/signUp/individual/SelectBox";

const stateTaxOptions = [
  {
    key: "PERCENTAGE",
    label: "Percentage",
    description: "Withhold a % from the amount of the Gross Distribution",
  },
  {
    key: "DOLLAR_AMOUNT",
    label: "Dollar Amount",
    description: "Withhold a specific dollar amount of State Income Tax.",
  },
];

class StateTax extends React.Component {
  static propTypes = {
    goToNamedStep: PropTypes.func,
    stateTaxWithholdingChange: PropTypes.func,
    onCancel: PropTypes.func,
    onNext: PropTypes.func,
    disabled: PropTypes.bool,
    withdrawalAmount: PropTypes.number,
    userState: PropTypes.string,
    isRothDistribution: PropTypes.bool,
    isTotalDisbursement: PropTypes.bool,
    federalTaxSelection: PropTypes.object,
    federalWithholdingAmount: PropTypes.number,
    accountValue: PropTypes.number,
  };

  static defaultProps = {
    stateTaxWithholdingChange: () => {},
  };

  constructor(props) {
    super(props);

    const californiaHasFederalTax = this.calculateCaliforniaHasFederalTax();

    this.state = {
      stateTaxWithholding: californiaHasFederalTax ? "DOLLAR_AMOUNT" : "",
      submitted: false,
      stateTaxWithholdingPercentage: "",
      stateTaxWithholdingAmount: "",
    };
  }

  _setStateTaxDeductionType = (
    stateTaxWithholding,
    setFieldValue,
    values = {}
  ) => {
    this.setState({ stateTaxWithholding });
    setFieldValue("stateTaxWithholding", stateTaxWithholding);

    if (stateTaxWithholding === "PERCENTAGE") {
      setFieldValue(
        "stateTaxWithholdingPercentage",
        values.stateTaxWithholdingPercentage || ""
      );
      if (!values.stateTaxWithholdingAmount) {
        setFieldValue("stateTaxWithholdingAmount", "");
      }
    } else if (stateTaxWithholding === "DOLLAR_AMOUNT") {
      setFieldValue(
        "stateTaxWithholdingAmount",
        values.stateTaxWithholdingAmount || ""
      );

      if (!values.stateTaxWithholdingPercentage) {
        setFieldValue("stateTaxWithholdingPercentage", "");
      }
    }

    this.props.stateTaxWithholdingChange({
      stateTaxWithholding,
      stateTaxWithholdingPercentage: values.stateTaxWithholdingPercentage || "",
      stateTaxWithholdingAmount: values.stateTaxWithholdingAmount || "",
    });
  };

  _onAmountChange = (event, setFieldValue) => {
    const value = event.target.value;

    const formattedValue = value
      .toString()
      .replace(/[^0-9.]/g, "") // Remove all non-numeric characters except the decimal point
      .replace(/(\.\d{2})\d+$/, "$1"); // Ensure only two decimal places are retained

    setFieldValue("stateTaxWithholdingAmount", formattedValue);
    this.props.stateTaxWithholdingChange({
      stateTaxWithholding: "DOLLAR_AMOUNT",
      stateTaxWithholdingAmount: formattedValue,
      stateTaxWithholdingPercentage: this.state.stateTaxWithholdingPercentage,
    });
  };

  _onPercentageChange = (event, setFieldValue) => {
    const value = event.target.value;

    const formattedValue = value
      .toString()
      .replace(/[^0-9.]/g, "") // Remove any characters that are not digits or a decimal point.
      .replace(/(\.\d{2})\d+$/, "$1"); // Truncate the value to two decimal places if it has more.

    setFieldValue("stateTaxWithholdingPercentage", formattedValue);
    this.props.stateTaxWithholdingChange({
      stateTaxWithholding: "PERCENTAGE",
      stateTaxWithholdingPercentage: formattedValue,
      stateTaxWithholdingAmount: this.state.stateTaxWithholdingAmount,
    });
  };

  isLumpSumDistribution = () => {
    const { withdrawalAmount, accountValue } = this.props;

    // `lumpSumThreshold` is the smaller of 5000 or 50% of the `accountValue`
    const lumpSumThreshold = min([5000, 0.5 * accountValue]);

    // If the `withdrawalAmount` is greater than or equal to `lumpSumThreshold`,
    return withdrawalAmount >= lumpSumThreshold;
  };

  calculateCaliforniaHasFederalTax = () => {
    const { userState, federalTaxSelection } = this.props;
    return userState === "CA" && parseFloat(federalTaxSelection.percentage) > 0;
  };

  calculateSpecialTaxes = (rate) => {
    const { federalWithholdingAmount } = this.props;
    const stateTax = federalWithholdingAmount * rate;
    return round(stateTax, 2);
  };

  getMinRate = () => {
    const { isTotalDisbursement, userState } = this.props;

    if (userState === "DC") {
      if (isTotalDisbursement) {
        return 10.75;
      }
      return 0;
    }

    if (userState === "CT" && !this.isLumpSumDistribution()) {
      // No state withholding is required below the lump sum threshold
      return 0;
    }

    const stateRequirement = stateRequirementsTable[userState];
    return get(stateRequirement, "minRate", 0);
  };

  getMinDollarAmount = (minRate, withdrawalAmount) => {
    const { userState, federalWithholdingAmount } = this.props;

    const californiaHasFederalTax = this.calculateCaliforniaHasFederalTax();

    if (userState === "CA") {
      if (californiaHasFederalTax) {
        return this.calculateSpecialTaxes(0.1);
      } else {
        return round((minRate / 100) * withdrawalAmount, 2);
      }
    }

    if (userState === "VT" && federalWithholdingAmount > 0) {
      return this.calculateSpecialTaxes(0.3);
    }

    minRate = this.getMinRate();
    return round((minRate / 100) * withdrawalAmount, 2);
  };

  calcButtonDisabled(
    stateTaxWithholding,
    stateTaxWithholdingPercentage,
    stateTaxWithholdingAmount,
    errors
  ) {
    if (!stateTaxWithholding) return true;

    if (stateTaxWithholding === "PERCENTAGE") {
      const hasPercentageError =
        !stateTaxWithholdingPercentage || errors.stateTaxWithholdingPercentage;
      return hasPercentageError;
    }

    if (stateTaxWithholding === "DOLLAR_AMOUNT") {
      const hasAmountError =
        !stateTaxWithholdingAmount || errors.stateTaxWithholdingAmount;
      return hasAmountError;
    }

    return false;
  }

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    const { withdrawalAmount, userState } = this.props;
    const minRate = this.getMinRate();
    const californiaHasFederalTax = this.calculateCaliforniaHasFederalTax();

    const formatMinDollarAmount = formatCurrency(
      this.getMinDollarAmount(minRate, withdrawalAmount)
    );

    let minRateText = "";
    let minDollarAmountText = "";

    if (userState === "VT") {
      minRateText = `min rate for ${userState} must be 0% or ${minRate}%`;
      minDollarAmountText = `min amount must be $0 or ${formatMinDollarAmount}`;
    } else if (userState === "CA" && californiaHasFederalTax === false) {
      minRateText = `min rate for ${userState} must be 0% or ${minRate}%`;
      minDollarAmountText = `min amount must be $0 or ${formatMinDollarAmount}`;
    } else {
      minRateText = `min rate for ${userState} is ${minRate}%`;
      minDollarAmountText = `min amount is ${formatMinDollarAmount}`;
    }

    const filteredStateTaxOptions = californiaHasFederalTax
      ? stateTaxOptions.filter((option) => option.key !== "PERCENTAGE")
      : stateTaxOptions;

    const getFullStateName = (abbreviation) => {
      return get(states, abbreviation, "Unknown State");
    };
    const stateName = getFullStateName(userState);
    const Title = `${stateName} State Income Tax Withholding`;

    const schema = yup.object({
      stateTaxWithholding: yup
        .string()
        .required("State tax selection is required"),

      stateTaxWithholdingPercentage: yup
        .number()
        .nullable()
        .when("stateTaxWithholding", {
          is: "PERCENTAGE",
          then: yup
            .number()
            .required("Percentage is required")
            .max(100, "Percentage cannot exceed 100%")
            .test("state-specific-validation", (value) => {
              const formattedMinRate = `${minRate}%`;

              if (value === null) return true;

              // For CA and VT states, use custom dynamic error message
              if (userState === "CA") {
                if (value === 0 || value === 10) {
                  return true;
                }
                return new yup.ValidationError(
                  `Percentage must be 0% or 10%`,
                  value,
                  "stateTaxWithholdingPercentage"
                );
              }

              if (userState === "VT") {
                if (value === 0 || value === 30) {
                  return true;
                }
                return new yup.ValidationError(
                  `Percentage must be 0% or 30%`,
                  value,
                  "stateTaxWithholdingPercentage"
                );
              }

              // For all other cases, keep the original dynamic message
              if (this.props.isRothDistribution) {
                if (value === 0 || value >= minRate) {
                  return true;
                }
                return new yup.ValidationError(
                  `Percentage must be 0% or ${formattedMinRate}`,
                  value,
                  "stateTaxWithholdingPercentage"
                );
              }

              if (value >= minRate) {
                return true;
              }
              return new yup.ValidationError(
                `Percentage must be at least ${formattedMinRate}`,
                value,
                "stateTaxWithholdingPercentage"
              );
            })
            .test(
              "decimal-precision",
              "Percentage must have at most two decimal places",
              (value) => {
                return /^(\d+(\.\d{1,2})?)?$/.test(value);
              }
            ),
          otherwise: yup.number().nullable(),
        }),

      stateTaxWithholdingAmount: yup
        .number()
        .nullable()
        .when("stateTaxWithholding", {
          is: "DOLLAR_AMOUNT",
          then: yup
            .number()
            .required("Amount is required")
            .typeError("Amount must be a valid number")
            .test("state-specific-dollar-amount", (value) => {
              const minDollarAmount = this.getMinDollarAmount(
                minRate,
                withdrawalAmount
              );
              const formattedMinAmount = formatCurrency(minDollarAmount);

              // Custom error message with dynamic value for CA and VT only
              if (value === null) return true;

              if (userState === "CA" || userState === "VT") {
                if (value === 0 || value === minDollarAmount) {
                  return true;
                }
                return new yup.ValidationError(
                  `Amount must be $0 or ${formattedMinAmount}`,
                  value,
                  "stateTaxWithholdingAmount"
                );
              }

              // Keep the original error message for all other cases
              if (this.props.isRothDistribution) {
                if (value === 0 || value >= minDollarAmount) {
                  return true;
                }
                return new yup.ValidationError(
                  `Amount must be $0 or ${formattedMinAmount}`,
                  value,
                  "stateTaxWithholdingAmount"
                );
              }

              if (value >= minDollarAmount) {
                return true;
              }
              return new yup.ValidationError(
                `Amount must be at least ${formatCurrency(minDollarAmount)}`,
                value,
                "stateTaxWithholdingAmount"
              );
            })
            .test(
              "max-dollarAmount",
              `Amount cannot exceed available balance of ${formatCurrency(
                withdrawalAmount
              )}`,
              (value) => value <= withdrawalAmount
            )
            .test(
              "decimal-precision",
              "Amount must have at most two decimal places",
              (value) => {
                return /^(\d+(\.\d{1,2})?)?$/.test(value);
              }
            ),
          otherwise: yup.number().nullable(),
        }),
    });

    return (
      <div>
        <div className="mega-container">
          <section className="page-title-wrap">
            <article className="text-cell">
              <IconHeader variant="pageHeader" headerText={Title} />
              <IconSubheader
                variant="subheading"
                subheader="Select how you would like state income tax to be withheld from your distribution. Your options may vary depending on state regulations. Ensure that your choice meets your state’s minimum requirements to avoid rejection of your request."
              />
            </article>
          </section>

          <Formik
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={schema}
            enableReinitialize={true}
            initialValues={{
              stateTaxWithholdingPercentage: "",
              stateTaxWithholdingAmount: "",
              stateTaxWithholding: "",
            }}
          >
            {({
              handleBlur,
              setFieldValue,

              values,
              touched,
              errors,
            }) => (
              <Form noValidate>
                <div>
                  <section
                    className="form-sec-2col"
                    style={{ paddingTop: 20, paddingBottom: 20 }}
                  >
                    <article className="col-form" style={articleStyle}>
                      {this.props.isRothDistribution && (
                        <div
                          className="tax-penalties"
                          style={{ marginBottom: 10 }}
                        >
                          <p>
                            Generally, Roth IRA distributions are not taxable at
                            the federal or state level. Unless you elect to
                            withhold, we will not withhold taxes from your Roth
                            IRA distribution. You should confirm with a tax
                            advisor with specific knowledge of federal and state
                            withholding to determine what is best for your tax
                            situation.
                          </p>
                        </div>
                      )}
                      <div className="account-types">
                        {filteredStateTaxOptions.map((account) => (
                          <SelectBox
                            label={account.label}
                            description={account.description}
                            key={account.key}
                            items={account.items}
                            ordered={account.ordered}
                            displayChevron={true}
                            onSelect={() =>
                              this._setStateTaxDeductionType(
                                account.key,
                                setFieldValue
                              )
                            }
                            isSelected={
                              this.state.stateTaxWithholding === account.key
                            }
                          >
                            {this.state.stateTaxWithholding === "PERCENTAGE" &&
                              account.key === "PERCENTAGE" && (
                                <Form.Row
                                  as={Col}
                                  sm={16}
                                  style={{ paddingLeft: 15 }}
                                >
                                  <Form.Group controlId="formStateTaxPercentage">
                                    <Form.Control
                                      type="number"
                                      name="stateTaxWithholdingPercentage"
                                      placeholder="Enter a percentage"
                                      value={
                                        values.stateTaxWithholdingPercentage ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        this._onPercentageChange(
                                          e,
                                          setFieldValue
                                        )
                                      }
                                      onBlur={handleBlur}
                                      isInvalid={
                                        touched.stateTaxWithholdingPercentage &&
                                        !!errors.stateTaxWithholdingPercentage
                                      }
                                      isValid={
                                        touched.stateTaxWithholdingPercentage &&
                                        !errors.stateTaxWithholdingPercentage
                                      }
                                    />{" "}
                                    <Form.Control.Feedback type="invalid">
                                      {errors.stateTaxWithholdingPercentage}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Col>
                                    <Form.Label style={{ padding: 8 }}>
                                      ({minRateText})
                                    </Form.Label>
                                  </Col>
                                </Form.Row>
                              )}
                            {this.state.stateTaxWithholding ===
                              "DOLLAR_AMOUNT" &&
                              account.key === "DOLLAR_AMOUNT" && (
                                <Form.Row
                                  as={Col}
                                  sm={16}
                                  style={{ paddingLeft: 15 }}
                                >
                                  <Form.Group controlId="formStateTaxDollarAmount">
                                    <Form.Control
                                      type="number"
                                      name="stateTaxWithholdingAmount"
                                      placeholder="Enter a dollar amount"
                                      value={
                                        values.stateTaxWithholdingAmount || ""
                                      }
                                      onChange={(e) =>
                                        this._onAmountChange(e, setFieldValue)
                                      }
                                      onBlur={handleBlur}
                                      isInvalid={
                                        touched.stateTaxWithholdingAmount &&
                                        !!errors.stateTaxWithholdingAmount
                                      }
                                      isValid={
                                        touched.stateTaxWithholdingAmount &&
                                        !errors.stateTaxWithholdingAmount
                                      }
                                    />{" "}
                                    <Form.Control.Feedback type="invalid">
                                      {errors.stateTaxWithholdingAmount}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Col>
                                    <Form.Label style={{ padding: 8 }}>
                                      ({minDollarAmountText})
                                    </Form.Label>
                                  </Col>
                                </Form.Row>
                              )}
                          </SelectBox>
                        ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "#01a3b0",
                          marginTop: 15,
                        }}
                      >
                        <a
                          onClick={() =>
                            this.props.goToNamedStep("addressConfirmation")
                          }
                        >
                          <p
                            className="terms-text"
                            style={{
                              fontStyle: "italic",
                              cursor: "pointer",
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            Wrong State? You can update your address!
                          </p>
                        </a>
                      </div>
                    </article>
                  </section>
                  <section className="form-sec-2col">
                    <div className="submit-row btn-row">
                      <Button
                        onClick={this.props.onCancel}
                        btnLabel="Go Back"
                        color="cancel"
                      />
                      <Button
                        onClick={this.props.onNext}
                        btnLabel="Continue"
                        color="primary"
                        disabled={this.calcButtonDisabled(
                          values.stateTaxWithholding,
                          values.stateTaxWithholdingPercentage,
                          values.stateTaxWithholdingAmount,
                          errors
                        )}
                      />
                    </div>
                  </section>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default StateTax;
