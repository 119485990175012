import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { isEmpty, get, capitalize, some } from "lodash";
import { Card, OverlayTrigger, Popover, Row, Col } from "react-bootstrap";
import { FiAlertTriangle, FiTrash2, FiUsers } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { push } from "connected-react-router";

import IconCircle from "components/IconCircle";
import IconHeader from "components/IconHeader";
import Button from "components/Button";
import Alert from "components/Alert";

import { bankAccountType } from "statics/propTypes";
import {
  contributionConstants,
  bankConstants,
  payrollConstants,
} from "actions/types";
import { deleteBank, deleteEmployerBank } from "actions/bankActions";
import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { addContributionSource } from "actions/contributionActions";
import { toast } from "react-toastify";
import { isEmployerSelector } from "store/selectors/user";
import { wrapError } from "utils/errorHelper";
import { getIraAccountIdSelector } from "store/selectors/user";
import {
  getPayrollHistory,
  cancelPayrollsByBankId,
} from "actions/payrollActions";
import SimpleModal from "components/SimpleModal";

import "./BankCard.scss";

class BankCard extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    bankAccount: bankAccountType,
    error: PropTypes.string,
    iraAccountId: PropTypes.string,
    isUpdating: PropTypes.bool,
    deleteBank: PropTypes.func,
    addContributionSource: PropTypes.func,
    client: PropTypes.object,
    contributionAbility: PropTypes.object,
    allowContribution: PropTypes.bool,
    isEmployer: PropTypes.bool,
    deleteEmployerBank: PropTypes.func,
    getPayrollHistory: PropTypes.func,
    cancelPayrollsByBankId: PropTypes.func,
  };

  static defaultProps = {
    allowContribution: true,
  };

  constructor() {
    super();

    this.state = {
      isDeleting: false,
      isContributing: false,
      contributionAmount: 1,
      applyToPriorYear: false,
      error: "",
      isLoading: false,
      showApprovedModal: false,
      showProcessingModal: false,
    };
  }

  _removeBankLink = () => {
    this.setState({
      isLoading: true,
    });
    const bankId = this.props.bankAccount.id;
    if (this.props.isEmployer) {
      this.props
        .getPayrollHistory(this.props.client)
        .then((data) => {
          if (data && data.error) {
            this.setState({ error: wrapError(data.error), isLoading: false });
            return Promise.resolve();
          }
          const payrollHistory = get(data, "data.payrollHistory", []);
          const approved = payrollHistory.filter(
            (payroll) => payroll.status === "APPROVED"
          );
          const processing = payrollHistory.filter(
            (payroll) => payroll.status === "PROCESSING"
          );
          if (this._isBankInList(approved, bankId)) {
            return this.setState({
              showApprovedModal: true,
            });
          } else if (this._isBankInList(processing, bankId)) {
            return this.setState({
              showProcessingModal: true,
            });
          } else {
            return this._deleteEmployerBank();
          }
        })
        .then(() => this.setState({ isLoading: false }));

      return;
    }
    this.props.deleteBank(this.props.client, bankId).then(() => {
      if (!this.props.error) {
        toast.success("Successfully removed your bank account.");
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  _isBankInList = (payrolls, bankId) => {
    return some(payrolls, (payroll) => payroll.bankId === bankId);
  };

  _deleteEmployerBank = (cancelPayrolls = false) => {
    this.setState({
      isLoading: true,
      showApprovedModal: false,
    });
    const bankId = get(this.props, "bankAccount.id");
    if (!cancelPayrolls) {
      return this.props
        .deleteEmployerBank(this.props.client, bankId)
        .then((data) => {
          if (data && data.error) {
            this.setState({ error: wrapError(data.error), isLoading: false });
          }
        });
    }
    return this.props
      .cancelPayrollsByBankId(this.props.client, bankId)
      .then((data) => {
        if (data && data.error) {
          this.setState({ error: wrapError(data.error), isLoading: false });
          return Promise.resolve();
        }
        return this.props.deleteEmployerBank(this.props.client, bankId);
      })
      .then((data) => {
        if (data && data.error) {
          this.setState({ error: wrapError(data.error), isLoading: false });
        }
      });
  };

  _getErrorMessage = () => {
    if (!isEmpty(this.state.error)) {
      return this.state.error;
    }
    return this.props.error;
  };

  _getDisplayView() {
    const accountType = get(this.props.bankAccount, "accountSubtype");
    const accountId = get(this.props.bankAccount, "accountId");
    const bankAlias = get(this.props.bankAccount, "bankAlias");
    const bankName = get(this.props.bankAccount, "bankName");

    const popover = (
      <Popover className="action-popover">
        <Popover.Content>
          <ul className="bank-actions">
            <li
              className="bank-action-item"
              onClick={() => {
                this.props.push("/dashboard/company/group");
              }}
            >
              <FiUsers
                className="svg"
                color="white"
                stroke="#0a2540"
                size={14}
              />
              <p className="action-label">View Linked Groups</p>
            </li>
            <li
              className="remove-action-item"
              onClick={() => this.setState({ isDeleting: true })}
            >
              <FiTrash2 size={14} color="#FFFFFF" fill={"#FFFFFF"} />
              <p className="action-label"> Remove account</p>
            </li>
          </ul>
        </Popover.Content>
      </Popover>
    );

    return (
      <>
        <Card>
          <Card.Body>
            <div className="bank-info">
              <div>
                <p className="linked-bank">Linked Bank</p>

                <p className="description">
                  <span className="bank-details">
                    <p className="checking"> {capitalize(accountType)}</p>
                    <span>
                      <BsDot
                        color="#0a2540"
                        size={28}
                        style={{ position: "relative", bottom: 1 }}
                      />
                      <BsDot
                        color="#0a2540"
                        size={28}
                        style={{ position: "relative", left: -15, bottom: 1 }}
                      />
                    </span>
                    <p
                      className="checking"
                      style={{ position: "relative", left: -22 }}
                    >
                      {accountId}
                    </p>
                  </span>
                </p>

                <Row>
                  <Col xs={4} className="bank-box-1">
                    <p className="bank-name">Account Number</p>
                  </Col>
                  <Col xs={6} className="bank-box">
                    <p className="bank-name">{accountId} </p>
                  </Col>
                </Row>

                <Row>
                  <Col xs={4} className="bank-box-1">
                    <p className="bank-name">Account Type</p>
                  </Col>
                  <Col xs={6} className="bank-box">
                    <p className="bank-name">{bankAlias} </p>
                  </Col>
                </Row>

                <Row>
                  <Col xs={4} className="bank-box-1">
                    <p className="bank-name">Bank</p>
                  </Col>
                  <Col xs={6} className="bank-box">
                    <p className="bank-name">{bankName}</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="bottom"
              overlay={popover}
            >
              <Button
                name="action"
                size="sm"
                color="action"
                btnLabel=" Actions"
              />
            </OverlayTrigger>
          </Card.Footer>
        </Card>
      </>
    );
  }

  _confirmDeleteDisplay() {
    const header = "Are you sure you want to remove your bank account?";
    return (
      <>
        <Card>
          <Card.Body>
            <IconCircle
              type="error"
              icon={
                <FiAlertTriangle color="white" stroke="#B12121" size={16} />
              }
            />
            <IconHeader variant="centeredText" headerText={header} />

            {!isEmpty(this.state.error) && (
              <Alert type="error" msg={this._getErrorMessage()} />
            )}

            <div className="remove-bank-action">
              <div className="bank-cancel">
                <Button
                  size="sm"
                  color="cancel"
                  name="cancel"
                  btnLabel="Cancel"
                  onClick={() => this.setState({ isDeleting: false })}
                />
              </div>
              <Button
                size="sm"
                color="red"
                name="submit"
                btnLabel="Remove"
                onClick={this._removeBankLink}
                withArrow={true}
                loading={this.state.isLoading}
              />
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }

  render() {
    let content;

    if (this.state.isDeleting) {
      content = this._confirmDeleteDisplay();
    } else {
      content = this._getDisplayView();
    }
    return (
      <>
        <SimpleModal
          show={this.state.showApprovedModal}
          onClose={() =>
            this.setState({ showApprovedModal: false, isDeleting: false })
          }
          onSubmit={() => this._deleteEmployerBank(true)}
          title="Payroll Contributions are Approved"
          buttonText="Continue"
        >
          <p>
            {"There are currently payroll contributions approved for processing. By continuing and removing this bank," +
              " the payroll contributions will be un-approved. You will need to re-approve them after adding a new bank" +
              " and associating it to the Icon Plan. Would you like to continue?"}
          </p>
        </SimpleModal>
        <SimpleModal
          show={this.state.showProcessingModal}
          onClose={() =>
            this.setState({ showProcessingModal: false, isDeleting: false })
          }
          title="Payroll Contributions are Processing"
          buttonText="Continue"
        >
          <p>
            {"There are currently payroll contributions processing and you are unable to remove the bank account." +
              " Come back in 1 business day, after contributions have processed, to remove the bank."}
          </p>
        </SimpleModal>
        {content}
      </>
    );
  }
}

const isUpdatingSelector = createLoadingSelector([
  contributionConstants.CONTRIBUTION,
  payrollConstants.GET_PAYROLL_HISTORY,
  payrollConstants.CANCEL_PAYROLLS,
]);

const errorSelector = createErrorSelector([
  contributionConstants.CONTRIBUTION,
  bankConstants.DELETE_BANK,
]);

const mapStateToProps = (state) => {
  return {
    isUpdating: isUpdatingSelector(state),
    error: errorSelector(state),
    iraAccountId: getIraAccountIdSelector(state),
    isEmployer: isEmployerSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  deleteBank,
  addContributionSource,
  deleteEmployerBank,
  getPayrollHistory,
  cancelPayrollsByBankId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(BankCard));
