import React from "react";
import PropTypes from "prop-types";
import { scrollToTop } from "utils/dom";
import Button from "components/Button";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import SelectBox from "../../../../signUp/individual/SelectBox";

const distributionReasons = [
  {
    label: "Disability",
    key: "DISABILITY",
    description:
      "If you're unable to work due to a long-term or terminal disability, you may avoid the 10% early withdrawal penalty on your IRA.",
  },
  {
    label: "None of the above apply to me",
    key: "NONE",
    description:
      "If this does not apply to you, you can proceed to the next step.",
  },
];

class DistributionReason extends React.Component {
  static propTypes = {
    goToNamedStep: PropTypes.func,
    onDistributionReasonSelect: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      distributionSpecialReason: "",
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  _setDistributionReason = (distributionSpecialReason) => {
    this.setState({ distributionSpecialReason }, () => {
      if (this.props.onDistributionReasonSelect) {
        this.props.onDistributionReasonSelect(distributionSpecialReason);
      }
    });
  };

  render() {
    const articleStyle = { paddingBottom: 0 };

    return (
      <div id="account-selection">
        <div className="mega-container">
          <section className="page-title-wrap">
            <article className="text-cell">
              <IconHeader
                variant="pageHeader"
                headerText="Is this distribution due to the following?"
              />
              <IconSubheader
                variant="subheading"
                subheader="Select one that may apply to you."
              />
            </article>
          </section>
          <div>
            <section
              className="form-sec-2col"
              style={{ paddingBottom: 10, paddingTop: 30 }}
            >
              <article className="col-form" style={articleStyle}>
                <div className="account-types">
                  {distributionReasons.map((type) => (
                    <SelectBox
                      label={type.label}
                      description={type.description}
                      key={type.key}
                      items={type.items}
                      ordered={type.ordered}
                      displayChevron={true}
                      onSelect={() => this._setDistributionReason(type.key)}
                      isSelected={
                        this.state.distributionSpecialReason === type.key
                      }
                    />
                  ))}
                </div>
              </article>
            </section>
            <section className="form-sec-2col">
              <article className="col-form">
                <div className="submit-row btn-row">
                  <Button
                    onClick={() => this.props.goToNamedStep("distributionType")}
                    name="cancel"
                    btnLabel="Go Back"
                    color="cancel"
                  />
                  <Button
                    style={{ marginLeft: 10 }}
                    name="action"
                    disabled={!this.state.distributionSpecialReason}
                    onClick={() =>
                      this.props.goToNamedStep("distributionFederalTax")
                    }
                  />
                </div>
              </article>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default DistributionReason;
