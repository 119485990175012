import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import { createErrorSelector } from "store/selectors";
import { linkBank, verifyBankAccount } from "actions/bankActions";
import { bankConstants } from "actions/types";
import { progressiveOnboardingEvents } from "statics/states";
import { updateProgressiveOnboarding } from "actions/employerActions";
import { getLimitByType, hasAddedBank } from "store/selectors/employer";
import { FiAlertTriangle } from "react-icons/fi";
import { isEmployerSelector } from "store/selectors/user";
import { shouldReAuthBank } from "utils/reAuthDateValidation";
import {
  activeBanksSelector,
  banksSelector,
  pendingBanksSelector,
  expiringEmployerBankSelector,
} from "store/selectors/bank";

import Button from "components/Button";
import Alert from "components/Alert";
import ReAuthPlaidLink from "components/ReAuthPlaidLink";
import PlaidLinkUpdateCard from "components/PlaidLinkUpdateCard";
import AddBankCard from "pages/dashboards/AddBankCard";
import BankCard from "pages/dashboards/individualDashboard/contributions/overview/BankCard";
import RemoveBank from "./RemoveBank";

class BankAccounts extends React.Component {
  static propTypes = {
    linkBank: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    bankAccounts: PropTypes.arrayOf(PropTypes.object),
    pendingBanks: PropTypes.arrayOf(PropTypes.object),
    activeBanks: PropTypes.arrayOf(PropTypes.object),
    verifyBankAccount: PropTypes.func,
    updateProgressiveOnboarding: PropTypes.func,
    hasAddedBank: PropTypes.bool,
    bankAccountLimit: PropTypes.string,
    updateExpiringLink: PropTypes.bool,
    expiringBanks: PropTypes.array,
    onSuccess: PropTypes.func,
    isEmployer: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = { isDeleting: false };
  }

  _handleOnSuccess = (token, metadata, userLegalName) => {
    if (this.props.onSuccess) {
      this.props.onSuccess(token, metadata, userLegalName);
      toast.success("Bank account successfully re-authenticated.");
    }
  };

  _getPlaidLinkUpdateCard = (isExpiredBank) => {
    const bank = this.props.activeBanks.find(
      (bank) => bank.id === isExpiredBank
    );

    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ marginRight: "10px" }}>
          <Button
            size="sm"
            color="red"
            name="action"
            btnLabel="Remove Bank"
            onClick={() => this.setState({ isDeleting: true })}
          />
        </div>
        <div>
          <ReAuthPlaidLink
            bank={bank}
            isEmployer={this.props.isEmployer}
            onSuccess={this._handleOnSuccess}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="download-employee-template">
        <div className="main-content">
          <Card>
            <Card.Title>
              <p className="page-header">Linked Accounts</p>
              <p className="subtext">
                Securely connect your business bank account to Icon in order to
                facilitate your employee{"'"}s payroll deductions.
              </p>
            </Card.Title>
            <Card.Body>
              {this.props.error && (
                <Alert type="error" msg={this.props.error} />
              )}

              <Row id="add-banks">
                {this.props.activeBanks.map((b) => {
                  const bankAccountId = b.id;
                  const expiringBanks = this.props.expiringBanks;
                  const needsReAuth = shouldReAuthBank(
                    expiringBanks,
                    bankAccountId
                  );

                  const isExpiredBank = needsReAuth ? b.id : null;
                  const bankAccountName = b.bankName;
                  const lastFourDigits = b.accountId;
                  const description = `Re-authenticate your ${bankAccountName} bank account ending in ${lastFourDigits} to resume payroll deductions.`;
                  return (
                    <Col key={b.id} md={6}>
                      {needsReAuth ? (
                        <>
                          {this.state.isDeleting ? (
                            <RemoveBank bankId={isExpiredBank} />
                          ) : (
                            <PlaidLinkUpdateCard
                              key={b.id}
                              icon={
                                <FiAlertTriangle
                                  color="white"
                                  stroke="#B12121"
                                  size={16}
                                />
                              }
                              title="Bank Re-Authentication Required!"
                              description={description}
                              color="red"
                              withArrow={true}
                              onClick={this._getPlaidLinkUpdateCard(
                                isExpiredBank
                              )}
                            />
                          )}
                        </>
                      ) : (
                        <BankCard
                          allowContribution={false}
                          key={b.id}
                          bankAccount={b}
                        />
                      )}
                    </Col>
                  );
                })}
                {this.props.pendingBanks.map((b) => {
                  return (
                    <Col key={b.id} md={6}>
                      <AddBankCard key={b.id} bankAccount={b} />
                    </Col>
                  );
                })}
                {this.props.bankAccounts.length <
                  this.props.bankAccountLimit && (
                  <Col md={6}>
                    <AddBankCard
                      insertOnlyMode
                      onSuccess={() => {
                        if (!this.props.hasAddedBank) {
                          this.props.updateProgressiveOnboarding(
                            this.props.client,
                            progressiveOnboardingEvents.AddBank
                          );
                        }
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}

const actions = [
  bankConstants.LINK_BANK_WITH_ICON,
  bankConstants.OAUTH_PLAID_LINK_TOKEN,
];

const errorSelector = createErrorSelector([actions]);

const mapStateToProps = (state) => {
  return {
    bankAccounts: banksSelector(state),
    activeBanks: activeBanksSelector(state),
    pendingBanks: pendingBanksSelector(state),
    error: errorSelector(state),
    hasAddedBank: hasAddedBank(state),
    bankAccountLimit: getLimitByType(state, "EmployerBankSizeLimit"),
    expiringBanks: expiringEmployerBankSelector(state),
    isEmployer: isEmployerSelector(state),
  };
};

const mapDispatchToProps = {
  linkBank,
  verifyBankAccount,
  updateProgressiveOnboarding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(BankAccounts));
